@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&amp;display=swap");

:root {
  --color-primary: #ff014f;
  --color-secondary: #f4f5f6;
  --color-tertiary: #0d1013;
  --color-gray: #f6f6f6;
  --color-subtitle: #f9004d;
  --background-color-1: linear-gradient(145deg, #1e2024, #23272b);
  --background-color-2: #212428;
  --shadow-1: 10px 10px 19px #1c1e22, -10px -10px 19px #262a2e;
  --shadow-2: inset 21px 21px 19px #181a1d, inset -21px -21px 19px #202225;
  --inner-shadow: 1px 4px 2px -3px rgba(0, 0, 0, 0.7) inset,
    -1px -3px 3px -2px rgba(255, 255, 255, 0.2) inset;
  --shadow-white-3: 5px 5px 15px #d1d9e6, -5px -5px 15px #ffffff;
  --gradient-box-w: linear-gradient(145deg, #e2e8ec, #ffffff);
  --gradient-red-hover: linear-gradient(145deg, #ff014f, #d11414);
  --color-heading: #ffffff;
  --color-heading-wv: #1e2125;
  --color-body: #878e99;
  --color-body-white: #3c3e41;
  --color-lightn: #c4cfde;
  --color-midgray: #878787;
  --color-light: #e4e6ea;
  --color-lighter: #ced0d4;
  --color-lightest: #f0f2f5;
  --color-border: #e6e6e6;
  --color-white: #ffffff;
  --color-white-75: rgba(255, 255, 255, 0.75);
  --color-success: #3eb75e;
  --color-danger: #ff0003;
  --color-warning: #ff8f3c;
  --color-info: #1ba2db;
  --color-facebook: #3b5997;
  --color-twitter: #1ba1f2;
  --color-youtube: #ed4141;
  --color-linkedin: #0077b5;
  --color-pinterest: #e60022;
  --color-instagram: #c231a1;
  --color-vimeo: #00adef;
  --color-twitch: #6441a3;
  --color-discord: #7289da;
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;
  --s-light: 300;
  --s-regular: 400;
  --s-medium: 500;
  --s-semi-bold: 600;
  --s-bold: 700;
  --s-extra-bold: 800;
  --s-black: 900;
  --transition: 0.4s;
  --font-primary: "Poppins", sans-serif;
  --font-secondary: "Montserrat", sans-serif;
  --font-size-b1: 18px;
  --font-size-b2: 22px;
  --font-size-b3: 14px;
  --line-height-b1: 1.5;
  --line-height-b2: 1.6;
  --line-height-b3: 1.7;
  --h1: 70px;
  --h2: 60px;
  --h3: 50px;
  --h4: 40px;
  --h5: 30px;
  --h6: 20px;
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  font-size: 10px;
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
}

body {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-primary);
  color: var(--color-body);
  font-weight: var(--p-regular);
}

body::-webkit-scrollbar {
  width: 0.9em;
}
body::-webkit-scrollbar-thumb {
  background: linear-gradient(145deg, #ff014f, #d11414);
  border-radius: 5px;
  outline: 1px solid #708090;
}

body::-webkit-scrollbar-track {
  background: linear-gradient(to right bottom, #d1d9e6, #e2e8ec);
  -webkit-box-shadow: inset 0 0 5px rgb(0 0 0 / 30%);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  body {
    overflow: hidden;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  body {
    overflow: hidden;
  }
}

@media only screen and (max-width: 767px) {
  body {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
  margin: 0;
  margin-bottom: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  word-break: break-word;
  font-family: var(--font-secondary);
  line-height: 1.4074;
  color: var(--color-heading);
}

h1,
.h1 {
  font-size: var(--h1);
  line-height: 1.19;
  font-weight: 700;
}

h2,
.h2 {
  font-size: var(--h2);
  line-height: 1.23;
}

h3,
.h3 {
  font-size: var(--h3);
  line-height: 1.14;
}

h4,
.h4 {
  font-size: var(--h4);
  line-height: 1.25;
}

h5,
.h5 {
  font-size: var(--h5);
  line-height: 1.24;
}

h6,
.h6 {
  font-size: var(--h6);
  line-height: 1.25;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a,
.h1 a,
.h2 a,
.h3 a,
.h4 a,
.h5 a,
.h6 a {
  color: inherit;
}

.bg-color-tertiary h1,
.bg-color-tertiary h2,
.bg-color-tertiary h3,
.bg-color-tertiary h4,
.bg-color-tertiary h5,
.bg-color-tertiary h6,
.bg-color-tertiary .h1,
.bg-color-tertiary .h2,
.bg-color-tertiary .h3,
.bg-color-tertiary .h4,
.bg-color-tertiary .h5,
.bg-color-tertiary .h6 {
  color: #fff;
}

.bg-color-tertiary p {
  color: #6c7279;
}

.bg-color-tertiary a {
  color: #6c7279;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 38px;
  }

  h2,
  .h2 {
    font-size: 32px;
  }

  h3,
  .h3 {
    font-size: 28px;
  }

  h4,
  .h4 {
    font-size: 24px;
  }

  h5,
  .h5 {
    font-size: 18px;
  }
}

@media only screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 34px;
  }

  h2,
  .h2 {
    font-size: 28px;
  }

  h3,
  .h3 {
    font-size: 24px;
  }

  h4,
  .h4 {
    font-size: 20px;
  }

  h5,
  .h5 {
    font-size: 16px;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: var(--s-bold);
}

h4,
.h4,
h5,
.h5 {
  font-weight: var(--s-bold);
}

h6,
.h6 {
  font-weight: var(--s-bold);
}

p {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  font-weight: var(--p-regular);
  color: var(--color-body);
  margin: 0 0 40px;
}

p.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

p.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3);
}

@media only screen and (max-width: 767px) {
  p {
    margin: 0 0 20px;
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
  }
}

p.has-large-font-size {
  line-height: 1.5;
  font-size: 36px;
}

p.has-medium-font-size {
  font-size: 24px;
  line-height: 36px;
}

p.has-small-font-size {
  font-size: 13px;
}

p.has-very-light-gray-color {
  color: var(--color-white);
}

p.has-background {
  padding: 20px 30px;
}

p.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

p.b2 {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
}

p.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3);
}

p:last-child {
  margin-bottom: 0;
}

.b1 {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
}

.b2 {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
}

.b3 {
  font-size: var(--font-size-b3);
  line-height: var(--line-height-b3);
}

.b4 {
  font-size: var(--font-size-b4);
  line-height: var(--line-height-b4);
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 20px;
  width: 100%;
}

table a,
table a:link,
table a:visited {
  text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
  color: var(--color-heading);
}

var {
  font-family: "Poppins", sans-serif;
}

.circle {
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  position: absolute;
  border-radius: 15px;
}

ul,
ol {
  padding-left: 18px;
}

ul {
  list-style: square;
  margin-bottom: 30px;
  padding-left: 20px;
}

ul.liststyle.bullet li {
  font-size: 18px;
  line-height: 30px;
  color: var(--color-body);
  position: relative;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  ul.liststyle.bullet li {
    padding-left: 19px;
  }
}

ul.liststyle.bullet li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: var(--color-body);
  left: 0;
  top: 10px;
}

ul.liststyle.bullet li + li {
  margin-top: 8px;
}

ul li {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--color-body);
}

ul li a {
  text-decoration: none;
  color: var(--color-gray);
}

ul li a:hover {
  color: var(--color-primary);
}

ul ul {
  margin-bottom: 0;
}

ol {
  margin-bottom: 30px;
}

ol li {
  font-size: var(--font-size-b1);
  line-height: var(--line-height-b1);
  color: var(--color-body);
  margin-top: 10px;
  margin-bottom: 10px;
}

ol li a {
  color: var(--color-heading);
  text-decoration: none;
}

ol li a:hover {
  color: var(--color-primary);
}

ol ul {
  padding-left: 30px;
}

.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.mb_dec--25 {
  margin-bottom: -25px;
}

.mb_dec--30 {
  margin-bottom: -30px;
}

.m--0 {
  margin: 0;
}

.rn-section-gap {
  padding: 100px 0;
}

@media only screen and (max-width: 479px) {
  .rn-section-gap {
    padding: 30px 0 !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-section-gap {
    padding: 80px 0;
  }
}

@media only screen and (max-width: 767px) {
  .rn-section-gap {
    padding: 80px 0;
  }
}

.rn-section-gapBottom {
  padding-bottom: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-section-gapBottom {
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-section-gapBottom {
    padding-bottom: 80px;
  }
}

.rn-section-gapTop {
  padding-top: 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-section-gapTop {
    padding-top: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-section-gapTop {
    padding-top: 80px;
  }
}

.pl--0 {
  padding-left: 0 !important;
}

.pr--0 {
  padding-right: 0 !important;
}

.pt--0 {
  padding-top: 0 !important;
}

.pb--0 {
  padding-bottom: 0 !important;
}

.mr--0 {
  margin-right: 0 !important;
}

.ml--0 {
  margin-left: 0 !important;
}

.mt--0 {
  margin-top: 0 !important;
}

.mb--0 {
  margin-bottom: 0 !important;
}

.ptb--5 {
  padding: 5px 0 !important;
}

.plr--5 {
  padding: 0 5px !important;
}

.pt--5 {
  padding-top: 5px !important;
}

.pb--5 {
  padding-bottom: 5px !important;
}

.pl--5 {
  padding-left: 5px !important;
}

.pr--5 {
  padding-right: 5px !important;
}

.mt--5 {
  margin-top: 5px !important;
}

.mb--5 {
  margin-bottom: 5px !important;
}

.mr--5 {
  margin-right: 5px !important;
}

.ml--5 {
  margin-left: 5px !important;
}

.ptb--10 {
  padding: 10px 0 !important;
}

.plr--10 {
  padding: 0 10px !important;
}

.pt--10 {
  padding-top: 10px !important;
}

.pb--10 {
  padding-bottom: 10px !important;
}

.pl--10 {
  padding-left: 10px !important;
}

.pr--10 {
  padding-right: 10px !important;
}

.mt--10 {
  margin-top: 10px !important;
}

.mb--10 {
  margin-bottom: 10px !important;
}

.mr--10 {
  margin-right: 10px !important;
}

.ml--10 {
  margin-left: 10px !important;
}

.ptb--15 {
  padding: 15px 0 !important;
}

.plr--15 {
  padding: 0 15px !important;
}

.pt--15 {
  padding-top: 15px !important;
}

.pb--15 {
  padding-bottom: 15px !important;
}

.pl--15 {
  padding-left: 15px !important;
}

.pr--15 {
  padding-right: 15px !important;
}

.mt--15 {
  margin-top: 15px !important;
}

.mb--15 {
  margin-bottom: 15px !important;
}

.mr--15 {
  margin-right: 15px !important;
}

.ml--15 {
  margin-left: 15px !important;
}

.ptb--20 {
  padding: 20px 0 !important;
}

.plr--20 {
  padding: 0 20px !important;
}

.pt--20 {
  padding-top: 20px !important;
}

.pb--20 {
  padding-bottom: 20px !important;
}

.pl--20 {
  padding-left: 20px !important;
}

.pr--20 {
  padding-right: 20px !important;
}

.mt--20 {
  margin-top: 20px !important;
}

.mb--20 {
  margin-bottom: 20px !important;
}

.mr--20 {
  margin-right: 20px !important;
}

.ml--20 {
  margin-left: 20px !important;
}

.ptb--25 {
  padding: 25px 0 !important;
}

.plr--25 {
  padding: 0 25px !important;
}

.pt--25 {
  padding-top: 25px !important;
}

.pb--25 {
  padding-bottom: 25px !important;
}

.pl--25 {
  padding-left: 25px !important;
}

.pr--25 {
  padding-right: 25px !important;
}

.mt--25 {
  margin-top: 25px !important;
}

.mb--25 {
  margin-bottom: 25px !important;
}

.mr--25 {
  margin-right: 25px !important;
}

.ml--25 {
  margin-left: 25px !important;
}

.ptb--30 {
  padding: 30px 0 !important;
}

.plr--30 {
  padding: 0 30px !important;
}

.pt--30 {
  padding-top: 30px !important;
}

.pb--30 {
  padding-bottom: 30px !important;
}

.pl--30 {
  padding-left: 30px !important;
}

.pr--30 {
  padding-right: 30px !important;
}

.mt--30 {
  margin-top: 30px !important;
}

.mb--30 {
  margin-bottom: 30px !important;
}

.mr--30 {
  margin-right: 30px !important;
}

.ml--30 {
  margin-left: 30px !important;
}

.ptb--35 {
  padding: 35px 0 !important;
}

.plr--35 {
  padding: 0 35px !important;
}

.pt--35 {
  padding-top: 35px !important;
}

.pb--35 {
  padding-bottom: 35px !important;
}

.pl--35 {
  padding-left: 35px !important;
}

.pr--35 {
  padding-right: 35px !important;
}

.mt--35 {
  margin-top: 35px !important;
}

.mb--35 {
  margin-bottom: 35px !important;
}

.mr--35 {
  margin-right: 35px !important;
}

.ml--35 {
  margin-left: 35px !important;
}

.ptb--40 {
  padding: 40px 0 !important;
}

.plr--40 {
  padding: 0 40px !important;
}

.pt--40 {
  padding-top: 40px !important;
}

.pb--40 {
  padding-bottom: 40px !important;
}

.pl--40 {
  padding-left: 40px !important;
}

.pr--40 {
  padding-right: 40px !important;
}

.mt--40 {
  margin-top: 40px !important;
}

.mb--40 {
  margin-bottom: 40px !important;
}

.mr--40 {
  margin-right: 40px !important;
}

.ml--40 {
  margin-left: 40px !important;
}

.ptb--45 {
  padding: 45px 0 !important;
}

.plr--45 {
  padding: 0 45px !important;
}

.pt--45 {
  padding-top: 45px !important;
}

.pb--45 {
  padding-bottom: 45px !important;
}

.pl--45 {
  padding-left: 45px !important;
}

.pr--45 {
  padding-right: 45px !important;
}

.mt--45 {
  margin-top: 45px !important;
}

.mb--45 {
  margin-bottom: 45px !important;
}

.mr--45 {
  margin-right: 45px !important;
}

.ml--45 {
  margin-left: 45px !important;
}

.ptb--50 {
  padding: 50px 0 !important;
}

.plr--50 {
  padding: 0 50px !important;
}

.pt--50 {
  padding-top: 50px !important;
}

.pb--50 {
  padding-bottom: 50px !important;
}

.pl--50 {
  padding-left: 50px !important;
}

.pr--50 {
  padding-right: 50px !important;
}

.mt--50 {
  margin-top: 50px !important;
}

.mb--50 {
  margin-bottom: 50px !important;
}

.mr--50 {
  margin-right: 50px !important;
}

.ml--50 {
  margin-left: 50px !important;
}

.ptb--55 {
  padding: 55px 0 !important;
}

.plr--55 {
  padding: 0 55px !important;
}

.pt--55 {
  padding-top: 55px !important;
}

.pb--55 {
  padding-bottom: 55px !important;
}

.pl--55 {
  padding-left: 55px !important;
}

.pr--55 {
  padding-right: 55px !important;
}

.mt--55 {
  margin-top: 55px !important;
}

.mb--55 {
  margin-bottom: 55px !important;
}

.mr--55 {
  margin-right: 55px !important;
}

.ml--55 {
  margin-left: 55px !important;
}

.ptb--60 {
  padding: 60px 0 !important;
}

.plr--60 {
  padding: 0 60px !important;
}

.pt--60 {
  padding-top: 60px !important;
}

.pb--60 {
  padding-bottom: 60px !important;
}

.pl--60 {
  padding-left: 60px !important;
}

.pr--60 {
  padding-right: 60px !important;
}

.mt--60 {
  margin-top: 60px !important;
}

.mb--60 {
  margin-bottom: 60px !important;
}

.mr--60 {
  margin-right: 60px !important;
}

.ml--60 {
  margin-left: 60px !important;
}

.ptb--65 {
  padding: 65px 0 !important;
}

.plr--65 {
  padding: 0 65px !important;
}

.pt--65 {
  padding-top: 65px !important;
}

.pb--65 {
  padding-bottom: 65px !important;
}

.pl--65 {
  padding-left: 65px !important;
}

.pr--65 {
  padding-right: 65px !important;
}

.mt--65 {
  margin-top: 65px !important;
}

.mb--65 {
  margin-bottom: 65px !important;
}

.mr--65 {
  margin-right: 65px !important;
}

.ml--65 {
  margin-left: 65px !important;
}

.ptb--70 {
  padding: 70px 0 !important;
}

.plr--70 {
  padding: 0 70px !important;
}

.pt--70 {
  padding-top: 70px !important;
}

.pb--70 {
  padding-bottom: 70px !important;
}

.pl--70 {
  padding-left: 70px !important;
}

.pr--70 {
  padding-right: 70px !important;
}

.mt--70 {
  margin-top: 70px !important;
}

.mb--70 {
  margin-bottom: 70px !important;
}

.mr--70 {
  margin-right: 70px !important;
}

.ml--70 {
  margin-left: 70px !important;
}

.ptb--75 {
  padding: 75px 0 !important;
}

.plr--75 {
  padding: 0 75px !important;
}

.pt--75 {
  padding-top: 75px !important;
}

.pb--75 {
  padding-bottom: 75px !important;
}

.pl--75 {
  padding-left: 75px !important;
}

.pr--75 {
  padding-right: 75px !important;
}

.mt--75 {
  margin-top: 75px !important;
}

.mb--75 {
  margin-bottom: 75px !important;
}

.mr--75 {
  margin-right: 75px !important;
}

.ml--75 {
  margin-left: 75px !important;
}

.ptb--80 {
  padding: 80px 0 !important;
}

.plr--80 {
  padding: 0 80px !important;
}

.pt--80 {
  padding-top: 80px !important;
}

.pb--80 {
  padding-bottom: 80px !important;
}

.pl--80 {
  padding-left: 80px !important;
}

.pr--80 {
  padding-right: 80px !important;
}

.mt--80 {
  margin-top: 80px !important;
}

.mb--80 {
  margin-bottom: 80px !important;
}

.mr--80 {
  margin-right: 80px !important;
}

.ml--80 {
  margin-left: 80px !important;
}

.ptb--85 {
  padding: 85px 0 !important;
}

.plr--85 {
  padding: 0 85px !important;
}

.pt--85 {
  padding-top: 85px !important;
}

.pb--85 {
  padding-bottom: 85px !important;
}

.pl--85 {
  padding-left: 85px !important;
}

.pr--85 {
  padding-right: 85px !important;
}

.mt--85 {
  margin-top: 85px !important;
}

.mb--85 {
  margin-bottom: 85px !important;
}

.mr--85 {
  margin-right: 85px !important;
}

.ml--85 {
  margin-left: 85px !important;
}

.ptb--90 {
  padding: 90px 0 !important;
}

.plr--90 {
  padding: 0 90px !important;
}

.pt--90 {
  padding-top: 90px !important;
}

.pb--90 {
  padding-bottom: 90px !important;
}

.pl--90 {
  padding-left: 90px !important;
}

.pr--90 {
  padding-right: 90px !important;
}

.mt--90 {
  margin-top: 90px !important;
}

.mb--90 {
  margin-bottom: 90px !important;
}

.mr--90 {
  margin-right: 90px !important;
}

.ml--90 {
  margin-left: 90px !important;
}

.ptb--95 {
  padding: 95px 0 !important;
}

.plr--95 {
  padding: 0 95px !important;
}

.pt--95 {
  padding-top: 95px !important;
}

.pb--95 {
  padding-bottom: 95px !important;
}

.pl--95 {
  padding-left: 95px !important;
}

.pr--95 {
  padding-right: 95px !important;
}

.mt--95 {
  margin-top: 95px !important;
}

.mb--95 {
  margin-bottom: 95px !important;
}

.mr--95 {
  margin-right: 95px !important;
}

.ml--95 {
  margin-left: 95px !important;
}

.ptb--100 {
  padding: 100px 0 !important;
}

.plr--100 {
  padding: 0 100px !important;
}

.pt--100 {
  padding-top: 100px !important;
}

.pb--100 {
  padding-bottom: 100px !important;
}

.pl--100 {
  padding-left: 100px !important;
}

.pr--100 {
  padding-right: 100px !important;
}

.mt--100 {
  margin-top: 100px !important;
}

.mb--100 {
  margin-bottom: 100px !important;
}

.mr--100 {
  margin-right: 100px !important;
}

.ml--100 {
  margin-left: 100px !important;
}

.ptb--105 {
  padding: 105px 0 !important;
}

.plr--105 {
  padding: 0 105px !important;
}

.pt--105 {
  padding-top: 105px !important;
}

.pb--105 {
  padding-bottom: 105px !important;
}

.pl--105 {
  padding-left: 105px !important;
}

.pr--105 {
  padding-right: 105px !important;
}

.mt--105 {
  margin-top: 105px !important;
}

.mb--105 {
  margin-bottom: 105px !important;
}

.mr--105 {
  margin-right: 105px !important;
}

.ml--105 {
  margin-left: 105px !important;
}

.ptb--110 {
  padding: 110px 0 !important;
}

.plr--110 {
  padding: 0 110px !important;
}

.pt--110 {
  padding-top: 110px !important;
}

.pb--110 {
  padding-bottom: 110px !important;
}

.pl--110 {
  padding-left: 110px !important;
}

.pr--110 {
  padding-right: 110px !important;
}

.mt--110 {
  margin-top: 110px !important;
}

.mb--110 {
  margin-bottom: 110px !important;
}

.mr--110 {
  margin-right: 110px !important;
}

.ml--110 {
  margin-left: 110px !important;
}

.ptb--115 {
  padding: 115px 0 !important;
}

.plr--115 {
  padding: 0 115px !important;
}

.pt--115 {
  padding-top: 115px !important;
}

.pb--115 {
  padding-bottom: 115px !important;
}

.pl--115 {
  padding-left: 115px !important;
}

.pr--115 {
  padding-right: 115px !important;
}

.mt--115 {
  margin-top: 115px !important;
}

.mb--115 {
  margin-bottom: 115px !important;
}

.mr--115 {
  margin-right: 115px !important;
}

.ml--115 {
  margin-left: 115px !important;
}

.ptb--120 {
  padding: 120px 0 !important;
}

.plr--120 {
  padding: 0 120px !important;
}

.pt--120 {
  padding-top: 120px !important;
}

.pb--120 {
  padding-bottom: 120px !important;
}

.pl--120 {
  padding-left: 120px !important;
}

.pr--120 {
  padding-right: 120px !important;
}

.mt--120 {
  margin-top: 120px !important;
}

.mb--120 {
  margin-bottom: 120px !important;
}

.mr--120 {
  margin-right: 120px !important;
}

.ml--120 {
  margin-left: 120px !important;
}

.ptb--125 {
  padding: 125px 0 !important;
}

.plr--125 {
  padding: 0 125px !important;
}

.pt--125 {
  padding-top: 125px !important;
}

.pb--125 {
  padding-bottom: 125px !important;
}

.pl--125 {
  padding-left: 125px !important;
}

.pr--125 {
  padding-right: 125px !important;
}

.mt--125 {
  margin-top: 125px !important;
}

.mb--125 {
  margin-bottom: 125px !important;
}

.mr--125 {
  margin-right: 125px !important;
}

.ml--125 {
  margin-left: 125px !important;
}

.ptb--130 {
  padding: 130px 0 !important;
}

.plr--130 {
  padding: 0 130px !important;
}

.pt--130 {
  padding-top: 130px !important;
}

.pb--130 {
  padding-bottom: 130px !important;
}

.pl--130 {
  padding-left: 130px !important;
}

.pr--130 {
  padding-right: 130px !important;
}

.mt--130 {
  margin-top: 130px !important;
}

.mb--130 {
  margin-bottom: 130px !important;
}

.mr--130 {
  margin-right: 130px !important;
}

.ml--130 {
  margin-left: 130px !important;
}

.ptb--135 {
  padding: 135px 0 !important;
}

.plr--135 {
  padding: 0 135px !important;
}

.pt--135 {
  padding-top: 135px !important;
}

.pb--135 {
  padding-bottom: 135px !important;
}

.pl--135 {
  padding-left: 135px !important;
}

.pr--135 {
  padding-right: 135px !important;
}

.mt--135 {
  margin-top: 135px !important;
}

.mb--135 {
  margin-bottom: 135px !important;
}

.mr--135 {
  margin-right: 135px !important;
}

.ml--135 {
  margin-left: 135px !important;
}

.ptb--140 {
  padding: 140px 0 !important;
}

.plr--140 {
  padding: 0 140px !important;
}

.pt--140 {
  padding-top: 140px !important;
}

.pb--140 {
  padding-bottom: 140px !important;
}

.pl--140 {
  padding-left: 140px !important;
}

.pr--140 {
  padding-right: 140px !important;
}

.mt--140 {
  margin-top: 140px !important;
}

.mb--140 {
  margin-bottom: 140px !important;
}

.mr--140 {
  margin-right: 140px !important;
}

.ml--140 {
  margin-left: 140px !important;
}

.ptb--145 {
  padding: 145px 0 !important;
}

.plr--145 {
  padding: 0 145px !important;
}

.pt--145 {
  padding-top: 145px !important;
}

.pb--145 {
  padding-bottom: 145px !important;
}

.pl--145 {
  padding-left: 145px !important;
}

.pr--145 {
  padding-right: 145px !important;
}

.mt--145 {
  margin-top: 145px !important;
}

.mb--145 {
  margin-bottom: 145px !important;
}

.mr--145 {
  margin-right: 145px !important;
}

.ml--145 {
  margin-left: 145px !important;
}

.ptb--150 {
  padding: 150px 0 !important;
}

.plr--150 {
  padding: 0 150px !important;
}

.pt--150 {
  padding-top: 150px !important;
}

.pb--150 {
  padding-bottom: 150px !important;
}

.pl--150 {
  padding-left: 150px !important;
}

.pr--150 {
  padding-right: 150px !important;
}

.mt--150 {
  margin-top: 150px !important;
}

.mb--150 {
  margin-bottom: 150px !important;
}

.mr--150 {
  margin-right: 150px !important;
}

.ml--150 {
  margin-left: 150px !important;
}

.ptb--155 {
  padding: 155px 0 !important;
}

.plr--155 {
  padding: 0 155px !important;
}

.pt--155 {
  padding-top: 155px !important;
}

.pb--155 {
  padding-bottom: 155px !important;
}

.pl--155 {
  padding-left: 155px !important;
}

.pr--155 {
  padding-right: 155px !important;
}

.mt--155 {
  margin-top: 155px !important;
}

.mb--155 {
  margin-bottom: 155px !important;
}

.mr--155 {
  margin-right: 155px !important;
}

.ml--155 {
  margin-left: 155px !important;
}

.ptb--160 {
  padding: 160px 0 !important;
}

.plr--160 {
  padding: 0 160px !important;
}

.pt--160 {
  padding-top: 160px !important;
}

.pb--160 {
  padding-bottom: 160px !important;
}

.pl--160 {
  padding-left: 160px !important;
}

.pr--160 {
  padding-right: 160px !important;
}

.mt--160 {
  margin-top: 160px !important;
}

.mb--160 {
  margin-bottom: 160px !important;
}

.mr--160 {
  margin-right: 160px !important;
}

.ml--160 {
  margin-left: 160px !important;
}

.ptb--165 {
  padding: 165px 0 !important;
}

.plr--165 {
  padding: 0 165px !important;
}

.pt--165 {
  padding-top: 165px !important;
}

.pb--165 {
  padding-bottom: 165px !important;
}

.pl--165 {
  padding-left: 165px !important;
}

.pr--165 {
  padding-right: 165px !important;
}

.mt--165 {
  margin-top: 165px !important;
}

.mb--165 {
  margin-bottom: 165px !important;
}

.mr--165 {
  margin-right: 165px !important;
}

.ml--165 {
  margin-left: 165px !important;
}

.ptb--170 {
  padding: 170px 0 !important;
}

.plr--170 {
  padding: 0 170px !important;
}

.pt--170 {
  padding-top: 170px !important;
}

.pb--170 {
  padding-bottom: 170px !important;
}

.pl--170 {
  padding-left: 170px !important;
}

.pr--170 {
  padding-right: 170px !important;
}

.mt--170 {
  margin-top: 170px !important;
}

.mb--170 {
  margin-bottom: 170px !important;
}

.mr--170 {
  margin-right: 170px !important;
}

.ml--170 {
  margin-left: 170px !important;
}

.ptb--175 {
  padding: 175px 0 !important;
}

.plr--175 {
  padding: 0 175px !important;
}

.pt--175 {
  padding-top: 175px !important;
}

.pb--175 {
  padding-bottom: 175px !important;
}

.pl--175 {
  padding-left: 175px !important;
}

.pr--175 {
  padding-right: 175px !important;
}

.mt--175 {
  margin-top: 175px !important;
}

.mb--175 {
  margin-bottom: 175px !important;
}

.mr--175 {
  margin-right: 175px !important;
}

.ml--175 {
  margin-left: 175px !important;
}

.ptb--180 {
  padding: 180px 0 !important;
}

.plr--180 {
  padding: 0 180px !important;
}

.pt--180 {
  padding-top: 180px !important;
}

.pb--180 {
  padding-bottom: 180px !important;
}

.pl--180 {
  padding-left: 180px !important;
}

.pr--180 {
  padding-right: 180px !important;
}

.mt--180 {
  margin-top: 180px !important;
}

.mb--180 {
  margin-bottom: 180px !important;
}

.mr--180 {
  margin-right: 180px !important;
}

.ml--180 {
  margin-left: 180px !important;
}

.ptb--185 {
  padding: 185px 0 !important;
}

.plr--185 {
  padding: 0 185px !important;
}

.pt--185 {
  padding-top: 185px !important;
}

.pb--185 {
  padding-bottom: 185px !important;
}

.pl--185 {
  padding-left: 185px !important;
}

.pr--185 {
  padding-right: 185px !important;
}

.mt--185 {
  margin-top: 185px !important;
}

.mb--185 {
  margin-bottom: 185px !important;
}

.mr--185 {
  margin-right: 185px !important;
}

.ml--185 {
  margin-left: 185px !important;
}

.ptb--190 {
  padding: 190px 0 !important;
}

.plr--190 {
  padding: 0 190px !important;
}

.pt--190 {
  padding-top: 190px !important;
}

.pb--190 {
  padding-bottom: 190px !important;
}

.pl--190 {
  padding-left: 190px !important;
}

.pr--190 {
  padding-right: 190px !important;
}

.mt--190 {
  margin-top: 190px !important;
}

.mb--190 {
  margin-bottom: 190px !important;
}

.mr--190 {
  margin-right: 190px !important;
}

.ml--190 {
  margin-left: 190px !important;
}

.ptb--195 {
  padding: 195px 0 !important;
}

.plr--195 {
  padding: 0 195px !important;
}

.pt--195 {
  padding-top: 195px !important;
}

.pb--195 {
  padding-bottom: 195px !important;
}

.pl--195 {
  padding-left: 195px !important;
}

.pr--195 {
  padding-right: 195px !important;
}

.mt--195 {
  margin-top: 195px !important;
}

.mb--195 {
  margin-bottom: 195px !important;
}

.mr--195 {
  margin-right: 195px !important;
}

.ml--195 {
  margin-left: 195px !important;
}

.ptb--200 {
  padding: 200px 0 !important;
}

.plr--200 {
  padding: 0 200px !important;
}

.pt--200 {
  padding-top: 200px !important;
}

.pb--200 {
  padding-bottom: 200px !important;
}

.pl--200 {
  padding-left: 200px !important;
}

.pr--200 {
  padding-right: 200px !important;
}

.mt--200 {
  margin-top: 200px !important;
}

.mb--200 {
  margin-bottom: 200px !important;
}

.mr--200 {
  margin-right: 200px !important;
}

.ml--200 {
  margin-left: 200px !important;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .ptb_lp--5 {
    padding: 5px 0;
  }

  .plr_lp--5 {
    padding: 0 5px;
  }

  .pt_lp--5 {
    padding-top: 5px;
  }

  .pb_lp--5 {
    padding-bottom: 5px;
  }

  .pl_lp--5 {
    padding-left: 5px;
  }

  .pr_lp--5 {
    padding-right: 5px;
  }

  .mt_lp--5 {
    margin-top: 5px;
  }

  .mb_lp--5 {
    margin-bottom: 5px;
  }

  .ptb_lp--10 {
    padding: 10px 0;
  }

  .plr_lp--10 {
    padding: 0 10px;
  }

  .pt_lp--10 {
    padding-top: 10px;
  }

  .pb_lp--10 {
    padding-bottom: 10px;
  }

  .pl_lp--10 {
    padding-left: 10px;
  }

  .pr_lp--10 {
    padding-right: 10px;
  }

  .mt_lp--10 {
    margin-top: 10px;
  }

  .mb_lp--10 {
    margin-bottom: 10px;
  }

  .ptb_lp--15 {
    padding: 15px 0;
  }

  .plr_lp--15 {
    padding: 0 15px;
  }

  .pt_lp--15 {
    padding-top: 15px;
  }

  .pb_lp--15 {
    padding-bottom: 15px;
  }

  .pl_lp--15 {
    padding-left: 15px;
  }

  .pr_lp--15 {
    padding-right: 15px;
  }

  .mt_lp--15 {
    margin-top: 15px;
  }

  .mb_lp--15 {
    margin-bottom: 15px;
  }

  .ptb_lp--20 {
    padding: 20px 0;
  }

  .plr_lp--20 {
    padding: 0 20px;
  }

  .pt_lp--20 {
    padding-top: 20px;
  }

  .pb_lp--20 {
    padding-bottom: 20px;
  }

  .pl_lp--20 {
    padding-left: 20px;
  }

  .pr_lp--20 {
    padding-right: 20px;
  }

  .mt_lp--20 {
    margin-top: 20px;
  }

  .mb_lp--20 {
    margin-bottom: 20px;
  }

  .ptb_lp--25 {
    padding: 25px 0;
  }

  .plr_lp--25 {
    padding: 0 25px;
  }

  .pt_lp--25 {
    padding-top: 25px;
  }

  .pb_lp--25 {
    padding-bottom: 25px;
  }

  .pl_lp--25 {
    padding-left: 25px;
  }

  .pr_lp--25 {
    padding-right: 25px;
  }

  .mt_lp--25 {
    margin-top: 25px;
  }

  .mb_lp--25 {
    margin-bottom: 25px;
  }

  .ptb_lp--30 {
    padding: 30px 0;
  }

  .plr_lp--30 {
    padding: 0 30px;
  }

  .pt_lp--30 {
    padding-top: 30px;
  }

  .pb_lp--30 {
    padding-bottom: 30px;
  }

  .pl_lp--30 {
    padding-left: 30px;
  }

  .pr_lp--30 {
    padding-right: 30px;
  }

  .mt_lp--30 {
    margin-top: 30px;
  }

  .mb_lp--30 {
    margin-bottom: 30px;
  }

  .ptb_lp--35 {
    padding: 35px 0;
  }

  .plr_lp--35 {
    padding: 0 35px;
  }

  .pt_lp--35 {
    padding-top: 35px;
  }

  .pb_lp--35 {
    padding-bottom: 35px;
  }

  .pl_lp--35 {
    padding-left: 35px;
  }

  .pr_lp--35 {
    padding-right: 35px;
  }

  .mt_lp--35 {
    margin-top: 35px;
  }

  .mb_lp--35 {
    margin-bottom: 35px;
  }

  .ptb_lp--40 {
    padding: 40px 0;
  }

  .plr_lp--40 {
    padding: 0 40px;
  }

  .pt_lp--40 {
    padding-top: 40px;
  }

  .pb_lp--40 {
    padding-bottom: 40px;
  }

  .pl_lp--40 {
    padding-left: 40px;
  }

  .pr_lp--40 {
    padding-right: 40px;
  }

  .mt_lp--40 {
    margin-top: 40px;
  }

  .mb_lp--40 {
    margin-bottom: 40px;
  }

  .ptb_lp--45 {
    padding: 45px 0;
  }

  .plr_lp--45 {
    padding: 0 45px;
  }

  .pt_lp--45 {
    padding-top: 45px;
  }

  .pb_lp--45 {
    padding-bottom: 45px;
  }

  .pl_lp--45 {
    padding-left: 45px;
  }

  .pr_lp--45 {
    padding-right: 45px;
  }

  .mt_lp--45 {
    margin-top: 45px;
  }

  .mb_lp--45 {
    margin-bottom: 45px;
  }

  .ptb_lp--50 {
    padding: 50px 0;
  }

  .plr_lp--50 {
    padding: 0 50px;
  }

  .pt_lp--50 {
    padding-top: 50px;
  }

  .pb_lp--50 {
    padding-bottom: 50px;
  }

  .pl_lp--50 {
    padding-left: 50px;
  }

  .pr_lp--50 {
    padding-right: 50px;
  }

  .mt_lp--50 {
    margin-top: 50px;
  }

  .mb_lp--50 {
    margin-bottom: 50px;
  }

  .ptb_lp--55 {
    padding: 55px 0;
  }

  .plr_lp--55 {
    padding: 0 55px;
  }

  .pt_lp--55 {
    padding-top: 55px;
  }

  .pb_lp--55 {
    padding-bottom: 55px;
  }

  .pl_lp--55 {
    padding-left: 55px;
  }

  .pr_lp--55 {
    padding-right: 55px;
  }

  .mt_lp--55 {
    margin-top: 55px;
  }

  .mb_lp--55 {
    margin-bottom: 55px;
  }

  .ptb_lp--60 {
    padding: 60px 0;
  }

  .plr_lp--60 {
    padding: 0 60px;
  }

  .pt_lp--60 {
    padding-top: 60px;
  }

  .pb_lp--60 {
    padding-bottom: 60px;
  }

  .pl_lp--60 {
    padding-left: 60px;
  }

  .pr_lp--60 {
    padding-right: 60px;
  }

  .mt_lp--60 {
    margin-top: 60px;
  }

  .mb_lp--60 {
    margin-bottom: 60px;
  }

  .ptb_lp--65 {
    padding: 65px 0;
  }

  .plr_lp--65 {
    padding: 0 65px;
  }

  .pt_lp--65 {
    padding-top: 65px;
  }

  .pb_lp--65 {
    padding-bottom: 65px;
  }

  .pl_lp--65 {
    padding-left: 65px;
  }

  .pr_lp--65 {
    padding-right: 65px;
  }

  .mt_lp--65 {
    margin-top: 65px;
  }

  .mb_lp--65 {
    margin-bottom: 65px;
  }

  .ptb_lp--70 {
    padding: 70px 0;
  }

  .plr_lp--70 {
    padding: 0 70px;
  }

  .pt_lp--70 {
    padding-top: 70px;
  }

  .pb_lp--70 {
    padding-bottom: 70px;
  }

  .pl_lp--70 {
    padding-left: 70px;
  }

  .pr_lp--70 {
    padding-right: 70px;
  }

  .mt_lp--70 {
    margin-top: 70px;
  }

  .mb_lp--70 {
    margin-bottom: 70px;
  }

  .ptb_lp--75 {
    padding: 75px 0;
  }

  .plr_lp--75 {
    padding: 0 75px;
  }

  .pt_lp--75 {
    padding-top: 75px;
  }

  .pb_lp--75 {
    padding-bottom: 75px;
  }

  .pl_lp--75 {
    padding-left: 75px;
  }

  .pr_lp--75 {
    padding-right: 75px;
  }

  .mt_lp--75 {
    margin-top: 75px;
  }

  .mb_lp--75 {
    margin-bottom: 75px;
  }

  .ptb_lp--80 {
    padding: 80px 0;
  }

  .plr_lp--80 {
    padding: 0 80px;
  }

  .pt_lp--80 {
    padding-top: 80px;
  }

  .pb_lp--80 {
    padding-bottom: 80px;
  }

  .pl_lp--80 {
    padding-left: 80px;
  }

  .pr_lp--80 {
    padding-right: 80px;
  }

  .mt_lp--80 {
    margin-top: 80px;
  }

  .mb_lp--80 {
    margin-bottom: 80px;
  }

  .ptb_lp--85 {
    padding: 85px 0;
  }

  .plr_lp--85 {
    padding: 0 85px;
  }

  .pt_lp--85 {
    padding-top: 85px;
  }

  .pb_lp--85 {
    padding-bottom: 85px;
  }

  .pl_lp--85 {
    padding-left: 85px;
  }

  .pr_lp--85 {
    padding-right: 85px;
  }

  .mt_lp--85 {
    margin-top: 85px;
  }

  .mb_lp--85 {
    margin-bottom: 85px;
  }

  .ptb_lp--90 {
    padding: 90px 0;
  }

  .plr_lp--90 {
    padding: 0 90px;
  }

  .pt_lp--90 {
    padding-top: 90px;
  }

  .pb_lp--90 {
    padding-bottom: 90px;
  }

  .pl_lp--90 {
    padding-left: 90px;
  }

  .pr_lp--90 {
    padding-right: 90px;
  }

  .mt_lp--90 {
    margin-top: 90px;
  }

  .mb_lp--90 {
    margin-bottom: 90px;
  }

  .ptb_lp--95 {
    padding: 95px 0;
  }

  .plr_lp--95 {
    padding: 0 95px;
  }

  .pt_lp--95 {
    padding-top: 95px;
  }

  .pb_lp--95 {
    padding-bottom: 95px;
  }

  .pl_lp--95 {
    padding-left: 95px;
  }

  .pr_lp--95 {
    padding-right: 95px;
  }

  .mt_lp--95 {
    margin-top: 95px;
  }

  .mb_lp--95 {
    margin-bottom: 95px;
  }

  .ptb_lp--100 {
    padding: 100px 0;
  }

  .plr_lp--100 {
    padding: 0 100px;
  }

  .pt_lp--100 {
    padding-top: 100px;
  }

  .pb_lp--100 {
    padding-bottom: 100px;
  }

  .pl_lp--100 {
    padding-left: 100px;
  }

  .pr_lp--100 {
    padding-right: 100px;
  }

  .mt_lp--100 {
    margin-top: 100px;
  }

  .mb_lp--100 {
    margin-bottom: 100px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .ptb_lg--5 {
    padding: 5px 0 !important;
  }

  .plr_lg--5 {
    padding: 0 5px !important;
  }

  .pt_lg--5 {
    padding-top: 5px !important;
  }

  .pb_lg--5 {
    padding-bottom: 5px !important;
  }

  .pl_lg--5 {
    padding-left: 5px !important;
  }

  .pr_lg--5 {
    padding-right: 5px !important;
  }

  .mt_lg--5 {
    margin-top: 5px !important;
  }

  .mb_lg--5 {
    margin-bottom: 5px !important;
  }

  .ml_lg--5 {
    margin-left: 5px !important;
  }

  .ptb_lg--10 {
    padding: 10px 0 !important;
  }

  .plr_lg--10 {
    padding: 0 10px !important;
  }

  .pt_lg--10 {
    padding-top: 10px !important;
  }

  .pb_lg--10 {
    padding-bottom: 10px !important;
  }

  .pl_lg--10 {
    padding-left: 10px !important;
  }

  .pr_lg--10 {
    padding-right: 10px !important;
  }

  .mt_lg--10 {
    margin-top: 10px !important;
  }

  .mb_lg--10 {
    margin-bottom: 10px !important;
  }

  .ml_lg--10 {
    margin-left: 10px !important;
  }

  .ptb_lg--15 {
    padding: 15px 0 !important;
  }

  .plr_lg--15 {
    padding: 0 15px !important;
  }

  .pt_lg--15 {
    padding-top: 15px !important;
  }

  .pb_lg--15 {
    padding-bottom: 15px !important;
  }

  .pl_lg--15 {
    padding-left: 15px !important;
  }

  .pr_lg--15 {
    padding-right: 15px !important;
  }

  .mt_lg--15 {
    margin-top: 15px !important;
  }

  .mb_lg--15 {
    margin-bottom: 15px !important;
  }

  .ml_lg--15 {
    margin-left: 15px !important;
  }

  .ptb_lg--20 {
    padding: 20px 0 !important;
  }

  .plr_lg--20 {
    padding: 0 20px !important;
  }

  .pt_lg--20 {
    padding-top: 20px !important;
  }

  .pb_lg--20 {
    padding-bottom: 20px !important;
  }

  .pl_lg--20 {
    padding-left: 20px !important;
  }

  .pr_lg--20 {
    padding-right: 20px !important;
  }

  .mt_lg--20 {
    margin-top: 20px !important;
  }

  .mb_lg--20 {
    margin-bottom: 20px !important;
  }

  .ml_lg--20 {
    margin-left: 20px !important;
  }

  .ptb_lg--25 {
    padding: 25px 0 !important;
  }

  .plr_lg--25 {
    padding: 0 25px !important;
  }

  .pt_lg--25 {
    padding-top: 25px !important;
  }

  .pb_lg--25 {
    padding-bottom: 25px !important;
  }

  .pl_lg--25 {
    padding-left: 25px !important;
  }

  .pr_lg--25 {
    padding-right: 25px !important;
  }

  .mt_lg--25 {
    margin-top: 25px !important;
  }

  .mb_lg--25 {
    margin-bottom: 25px !important;
  }

  .ml_lg--25 {
    margin-left: 25px !important;
  }

  .ptb_lg--30 {
    padding: 30px 0 !important;
  }

  .plr_lg--30 {
    padding: 0 30px !important;
  }

  .pt_lg--30 {
    padding-top: 30px !important;
  }

  .pb_lg--30 {
    padding-bottom: 30px !important;
  }

  .pl_lg--30 {
    padding-left: 30px !important;
  }

  .pr_lg--30 {
    padding-right: 30px !important;
  }

  .mt_lg--30 {
    margin-top: 30px !important;
  }

  .mb_lg--30 {
    margin-bottom: 30px !important;
  }

  .ml_lg--30 {
    margin-left: 30px !important;
  }

  .ptb_lg--35 {
    padding: 35px 0 !important;
  }

  .plr_lg--35 {
    padding: 0 35px !important;
  }

  .pt_lg--35 {
    padding-top: 35px !important;
  }

  .pb_lg--35 {
    padding-bottom: 35px !important;
  }

  .pl_lg--35 {
    padding-left: 35px !important;
  }

  .pr_lg--35 {
    padding-right: 35px !important;
  }

  .mt_lg--35 {
    margin-top: 35px !important;
  }

  .mb_lg--35 {
    margin-bottom: 35px !important;
  }

  .ml_lg--35 {
    margin-left: 35px !important;
  }

  .ptb_lg--40 {
    padding: 40px 0 !important;
  }

  .plr_lg--40 {
    padding: 0 40px !important;
  }

  .pt_lg--40 {
    padding-top: 40px !important;
  }

  .pb_lg--40 {
    padding-bottom: 40px !important;
  }

  .pl_lg--40 {
    padding-left: 40px !important;
  }

  .pr_lg--40 {
    padding-right: 40px !important;
  }

  .mt_lg--40 {
    margin-top: 40px !important;
  }

  .mb_lg--40 {
    margin-bottom: 40px !important;
  }

  .ml_lg--40 {
    margin-left: 40px !important;
  }

  .ptb_lg--45 {
    padding: 45px 0 !important;
  }

  .plr_lg--45 {
    padding: 0 45px !important;
  }

  .pt_lg--45 {
    padding-top: 45px !important;
  }

  .pb_lg--45 {
    padding-bottom: 45px !important;
  }

  .pl_lg--45 {
    padding-left: 45px !important;
  }

  .pr_lg--45 {
    padding-right: 45px !important;
  }

  .mt_lg--45 {
    margin-top: 45px !important;
  }

  .mb_lg--45 {
    margin-bottom: 45px !important;
  }

  .ml_lg--45 {
    margin-left: 45px !important;
  }

  .ptb_lg--50 {
    padding: 50px 0 !important;
  }

  .plr_lg--50 {
    padding: 0 50px !important;
  }

  .pt_lg--50 {
    padding-top: 50px !important;
  }

  .pb_lg--50 {
    padding-bottom: 50px !important;
  }

  .pl_lg--50 {
    padding-left: 50px !important;
  }

  .pr_lg--50 {
    padding-right: 50px !important;
  }

  .mt_lg--50 {
    margin-top: 50px !important;
  }

  .mb_lg--50 {
    margin-bottom: 50px !important;
  }

  .ml_lg--50 {
    margin-left: 50px !important;
  }

  .ptb_lg--55 {
    padding: 55px 0 !important;
  }

  .plr_lg--55 {
    padding: 0 55px !important;
  }

  .pt_lg--55 {
    padding-top: 55px !important;
  }

  .pb_lg--55 {
    padding-bottom: 55px !important;
  }

  .pl_lg--55 {
    padding-left: 55px !important;
  }

  .pr_lg--55 {
    padding-right: 55px !important;
  }

  .mt_lg--55 {
    margin-top: 55px !important;
  }

  .mb_lg--55 {
    margin-bottom: 55px !important;
  }

  .ml_lg--55 {
    margin-left: 55px !important;
  }

  .ptb_lg--60 {
    padding: 60px 0 !important;
  }

  .plr_lg--60 {
    padding: 0 60px !important;
  }

  .pt_lg--60 {
    padding-top: 60px !important;
  }

  .pb_lg--60 {
    padding-bottom: 60px !important;
  }

  .pl_lg--60 {
    padding-left: 60px !important;
  }

  .pr_lg--60 {
    padding-right: 60px !important;
  }

  .mt_lg--60 {
    margin-top: 60px !important;
  }

  .mb_lg--60 {
    margin-bottom: 60px !important;
  }

  .ml_lg--60 {
    margin-left: 60px !important;
  }

  .ptb_lg--65 {
    padding: 65px 0 !important;
  }

  .plr_lg--65 {
    padding: 0 65px !important;
  }

  .pt_lg--65 {
    padding-top: 65px !important;
  }

  .pb_lg--65 {
    padding-bottom: 65px !important;
  }

  .pl_lg--65 {
    padding-left: 65px !important;
  }

  .pr_lg--65 {
    padding-right: 65px !important;
  }

  .mt_lg--65 {
    margin-top: 65px !important;
  }

  .mb_lg--65 {
    margin-bottom: 65px !important;
  }

  .ml_lg--65 {
    margin-left: 65px !important;
  }

  .ptb_lg--70 {
    padding: 70px 0 !important;
  }

  .plr_lg--70 {
    padding: 0 70px !important;
  }

  .pt_lg--70 {
    padding-top: 70px !important;
  }

  .pb_lg--70 {
    padding-bottom: 70px !important;
  }

  .pl_lg--70 {
    padding-left: 70px !important;
  }

  .pr_lg--70 {
    padding-right: 70px !important;
  }

  .mt_lg--70 {
    margin-top: 70px !important;
  }

  .mb_lg--70 {
    margin-bottom: 70px !important;
  }

  .ml_lg--70 {
    margin-left: 70px !important;
  }

  .ptb_lg--75 {
    padding: 75px 0 !important;
  }

  .plr_lg--75 {
    padding: 0 75px !important;
  }

  .pt_lg--75 {
    padding-top: 75px !important;
  }

  .pb_lg--75 {
    padding-bottom: 75px !important;
  }

  .pl_lg--75 {
    padding-left: 75px !important;
  }

  .pr_lg--75 {
    padding-right: 75px !important;
  }

  .mt_lg--75 {
    margin-top: 75px !important;
  }

  .mb_lg--75 {
    margin-bottom: 75px !important;
  }

  .ml_lg--75 {
    margin-left: 75px !important;
  }

  .ptb_lg--80 {
    padding: 80px 0 !important;
  }

  .plr_lg--80 {
    padding: 0 80px !important;
  }

  .pt_lg--80 {
    padding-top: 80px !important;
  }

  .pb_lg--80 {
    padding-bottom: 80px !important;
  }

  .pl_lg--80 {
    padding-left: 80px !important;
  }

  .pr_lg--80 {
    padding-right: 80px !important;
  }

  .mt_lg--80 {
    margin-top: 80px !important;
  }

  .mb_lg--80 {
    margin-bottom: 80px !important;
  }

  .ml_lg--80 {
    margin-left: 80px !important;
  }

  .ptb_lg--85 {
    padding: 85px 0 !important;
  }

  .plr_lg--85 {
    padding: 0 85px !important;
  }

  .pt_lg--85 {
    padding-top: 85px !important;
  }

  .pb_lg--85 {
    padding-bottom: 85px !important;
  }

  .pl_lg--85 {
    padding-left: 85px !important;
  }

  .pr_lg--85 {
    padding-right: 85px !important;
  }

  .mt_lg--85 {
    margin-top: 85px !important;
  }

  .mb_lg--85 {
    margin-bottom: 85px !important;
  }

  .ml_lg--85 {
    margin-left: 85px !important;
  }

  .ptb_lg--90 {
    padding: 90px 0 !important;
  }

  .plr_lg--90 {
    padding: 0 90px !important;
  }

  .pt_lg--90 {
    padding-top: 90px !important;
  }

  .pb_lg--90 {
    padding-bottom: 90px !important;
  }

  .pl_lg--90 {
    padding-left: 90px !important;
  }

  .pr_lg--90 {
    padding-right: 90px !important;
  }

  .mt_lg--90 {
    margin-top: 90px !important;
  }

  .mb_lg--90 {
    margin-bottom: 90px !important;
  }

  .ml_lg--90 {
    margin-left: 90px !important;
  }

  .ptb_lg--95 {
    padding: 95px 0 !important;
  }

  .plr_lg--95 {
    padding: 0 95px !important;
  }

  .pt_lg--95 {
    padding-top: 95px !important;
  }

  .pb_lg--95 {
    padding-bottom: 95px !important;
  }

  .pl_lg--95 {
    padding-left: 95px !important;
  }

  .pr_lg--95 {
    padding-right: 95px !important;
  }

  .mt_lg--95 {
    margin-top: 95px !important;
  }

  .mb_lg--95 {
    margin-bottom: 95px !important;
  }

  .ml_lg--95 {
    margin-left: 95px !important;
  }

  .ptb_lg--100 {
    padding: 100px 0 !important;
  }

  .plr_lg--100 {
    padding: 0 100px !important;
  }

  .pt_lg--100 {
    padding-top: 100px !important;
  }

  .pb_lg--100 {
    padding-bottom: 100px !important;
  }

  .pl_lg--100 {
    padding-left: 100px !important;
  }

  .pr_lg--100 {
    padding-right: 100px !important;
  }

  .mt_lg--100 {
    margin-top: 100px !important;
  }

  .mb_lg--100 {
    margin-bottom: 100px !important;
  }

  .ml_lg--100 {
    margin-left: 100px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .ptb_md--0 {
    padding: 0 !important;
  }

  .pl_md--0 {
    padding-left: 0 !important;
  }

  .pr_md--0 {
    padding-right: 0 !important;
  }

  .pt_md--0 {
    padding-top: 0 !important;
  }

  .pb_md--0 {
    padding-bottom: 0 !important;
  }

  .mr_md--0 {
    margin-right: 0 !important;
  }

  .ml_md--0 {
    margin-left: 0 !important;
  }

  .mt_md--0 {
    margin-top: 0 !important;
  }

  .mb_md--0 {
    margin-bottom: 0 !important;
  }

  .ptb_md--250 {
    padding: 250px 0 !important;
  }

  .ptb_md--5 {
    padding: 5px 0 !important;
  }

  .plr_md--5 {
    padding: 0 5px !important;
  }

  .pt_md--5 {
    padding-top: 5px !important;
  }

  .pb_md--5 {
    padding-bottom: 5px !important;
  }

  .pl_md--5 {
    padding-left: 5px !important;
  }

  .pr_md--5 {
    padding-right: 5px !important;
  }

  .mt_md--5 {
    margin-top: 5px !important;
  }

  .mb_md--5 {
    margin-bottom: 5px !important;
  }

  .ptb_md--10 {
    padding: 10px 0 !important;
  }

  .plr_md--10 {
    padding: 0 10px !important;
  }

  .pt_md--10 {
    padding-top: 10px !important;
  }

  .pb_md--10 {
    padding-bottom: 10px !important;
  }

  .pl_md--10 {
    padding-left: 10px !important;
  }

  .pr_md--10 {
    padding-right: 10px !important;
  }

  .mt_md--10 {
    margin-top: 10px !important;
  }

  .mb_md--10 {
    margin-bottom: 10px !important;
  }

  .ptb_md--15 {
    padding: 15px 0 !important;
  }

  .plr_md--15 {
    padding: 0 15px !important;
  }

  .pt_md--15 {
    padding-top: 15px !important;
  }

  .pb_md--15 {
    padding-bottom: 15px !important;
  }

  .pl_md--15 {
    padding-left: 15px !important;
  }

  .pr_md--15 {
    padding-right: 15px !important;
  }

  .mt_md--15 {
    margin-top: 15px !important;
  }

  .mb_md--15 {
    margin-bottom: 15px !important;
  }

  .ptb_md--20 {
    padding: 20px 0 !important;
  }

  .plr_md--20 {
    padding: 0 20px !important;
  }

  .pt_md--20 {
    padding-top: 20px !important;
  }

  .pb_md--20 {
    padding-bottom: 20px !important;
  }

  .pl_md--20 {
    padding-left: 20px !important;
  }

  .pr_md--20 {
    padding-right: 20px !important;
  }

  .mt_md--20 {
    margin-top: 20px !important;
  }

  .mb_md--20 {
    margin-bottom: 20px !important;
  }

  .ptb_md--25 {
    padding: 25px 0 !important;
  }

  .plr_md--25 {
    padding: 0 25px !important;
  }

  .pt_md--25 {
    padding-top: 25px !important;
  }

  .pb_md--25 {
    padding-bottom: 25px !important;
  }

  .pl_md--25 {
    padding-left: 25px !important;
  }

  .pr_md--25 {
    padding-right: 25px !important;
  }

  .mt_md--25 {
    margin-top: 25px !important;
  }

  .mb_md--25 {
    margin-bottom: 25px !important;
  }

  .ptb_md--30 {
    padding: 30px 0 !important;
  }

  .plr_md--30 {
    padding: 0 30px !important;
  }

  .pt_md--30 {
    padding-top: 30px !important;
  }

  .pb_md--30 {
    padding-bottom: 30px !important;
  }

  .pl_md--30 {
    padding-left: 30px !important;
  }

  .pr_md--30 {
    padding-right: 30px !important;
  }

  .mt_md--30 {
    margin-top: 30px !important;
  }

  .mb_md--30 {
    margin-bottom: 30px !important;
  }

  .ptb_md--35 {
    padding: 35px 0 !important;
  }

  .plr_md--35 {
    padding: 0 35px !important;
  }

  .pt_md--35 {
    padding-top: 35px !important;
  }

  .pb_md--35 {
    padding-bottom: 35px !important;
  }

  .pl_md--35 {
    padding-left: 35px !important;
  }

  .pr_md--35 {
    padding-right: 35px !important;
  }

  .mt_md--35 {
    margin-top: 35px !important;
  }

  .mb_md--35 {
    margin-bottom: 35px !important;
  }

  .ptb_md--40 {
    padding: 40px 0 !important;
  }

  .plr_md--40 {
    padding: 0 40px !important;
  }

  .pt_md--40 {
    padding-top: 40px !important;
  }

  .pb_md--40 {
    padding-bottom: 40px !important;
  }

  .pl_md--40 {
    padding-left: 40px !important;
  }

  .pr_md--40 {
    padding-right: 40px !important;
  }

  .mt_md--40 {
    margin-top: 40px !important;
  }

  .mb_md--40 {
    margin-bottom: 40px !important;
  }

  .ptb_md--45 {
    padding: 45px 0 !important;
  }

  .plr_md--45 {
    padding: 0 45px !important;
  }

  .pt_md--45 {
    padding-top: 45px !important;
  }

  .pb_md--45 {
    padding-bottom: 45px !important;
  }

  .pl_md--45 {
    padding-left: 45px !important;
  }

  .pr_md--45 {
    padding-right: 45px !important;
  }

  .mt_md--45 {
    margin-top: 45px !important;
  }

  .mb_md--45 {
    margin-bottom: 45px !important;
  }

  .ptb_md--50 {
    padding: 50px 0 !important;
  }

  .plr_md--50 {
    padding: 0 50px !important;
  }

  .pt_md--50 {
    padding-top: 50px !important;
  }

  .pb_md--50 {
    padding-bottom: 50px !important;
  }

  .pl_md--50 {
    padding-left: 50px !important;
  }

  .pr_md--50 {
    padding-right: 50px !important;
  }

  .mt_md--50 {
    margin-top: 50px !important;
  }

  .mb_md--50 {
    margin-bottom: 50px !important;
  }

  .ptb_md--55 {
    padding: 55px 0 !important;
  }

  .plr_md--55 {
    padding: 0 55px !important;
  }

  .pt_md--55 {
    padding-top: 55px !important;
  }

  .pb_md--55 {
    padding-bottom: 55px !important;
  }

  .pl_md--55 {
    padding-left: 55px !important;
  }

  .pr_md--55 {
    padding-right: 55px !important;
  }

  .mt_md--55 {
    margin-top: 55px !important;
  }

  .mb_md--55 {
    margin-bottom: 55px !important;
  }

  .ptb_md--60 {
    padding: 60px 0 !important;
  }

  .plr_md--60 {
    padding: 0 60px !important;
  }

  .pt_md--60 {
    padding-top: 60px !important;
  }

  .pb_md--60 {
    padding-bottom: 60px !important;
  }

  .pl_md--60 {
    padding-left: 60px !important;
  }

  .pr_md--60 {
    padding-right: 60px !important;
  }

  .mt_md--60 {
    margin-top: 60px !important;
  }

  .mb_md--60 {
    margin-bottom: 60px !important;
  }

  .ptb_md--65 {
    padding: 65px 0 !important;
  }

  .plr_md--65 {
    padding: 0 65px !important;
  }

  .pt_md--65 {
    padding-top: 65px !important;
  }

  .pb_md--65 {
    padding-bottom: 65px !important;
  }

  .pl_md--65 {
    padding-left: 65px !important;
  }

  .pr_md--65 {
    padding-right: 65px !important;
  }

  .mt_md--65 {
    margin-top: 65px !important;
  }

  .mb_md--65 {
    margin-bottom: 65px !important;
  }

  .ptb_md--70 {
    padding: 70px 0 !important;
  }

  .plr_md--70 {
    padding: 0 70px !important;
  }

  .pt_md--70 {
    padding-top: 70px !important;
  }

  .pb_md--70 {
    padding-bottom: 70px !important;
  }

  .pl_md--70 {
    padding-left: 70px !important;
  }

  .pr_md--70 {
    padding-right: 70px !important;
  }

  .mt_md--70 {
    margin-top: 70px !important;
  }

  .mb_md--70 {
    margin-bottom: 70px !important;
  }

  .ptb_md--75 {
    padding: 75px 0 !important;
  }

  .plr_md--75 {
    padding: 0 75px !important;
  }

  .pt_md--75 {
    padding-top: 75px !important;
  }

  .pb_md--75 {
    padding-bottom: 75px !important;
  }

  .pl_md--75 {
    padding-left: 75px !important;
  }

  .pr_md--75 {
    padding-right: 75px !important;
  }

  .mt_md--75 {
    margin-top: 75px !important;
  }

  .mb_md--75 {
    margin-bottom: 75px !important;
  }

  .ptb_md--80 {
    padding: 80px 0 !important;
  }

  .plr_md--80 {
    padding: 0 80px !important;
  }

  .pt_md--80 {
    padding-top: 80px !important;
  }

  .pb_md--80 {
    padding-bottom: 80px !important;
  }

  .pl_md--80 {
    padding-left: 80px !important;
  }

  .pr_md--80 {
    padding-right: 80px !important;
  }

  .mt_md--80 {
    margin-top: 80px !important;
  }

  .mb_md--80 {
    margin-bottom: 80px !important;
  }

  .ptb_md--85 {
    padding: 85px 0 !important;
  }

  .plr_md--85 {
    padding: 0 85px !important;
  }

  .pt_md--85 {
    padding-top: 85px !important;
  }

  .pb_md--85 {
    padding-bottom: 85px !important;
  }

  .pl_md--85 {
    padding-left: 85px !important;
  }

  .pr_md--85 {
    padding-right: 85px !important;
  }

  .mt_md--85 {
    margin-top: 85px !important;
  }

  .mb_md--85 {
    margin-bottom: 85px !important;
  }

  .ptb_md--90 {
    padding: 90px 0 !important;
  }

  .plr_md--90 {
    padding: 0 90px !important;
  }

  .pt_md--90 {
    padding-top: 90px !important;
  }

  .pb_md--90 {
    padding-bottom: 90px !important;
  }

  .pl_md--90 {
    padding-left: 90px !important;
  }

  .pr_md--90 {
    padding-right: 90px !important;
  }

  .mt_md--90 {
    margin-top: 90px !important;
  }

  .mb_md--90 {
    margin-bottom: 90px !important;
  }

  .ptb_md--95 {
    padding: 95px 0 !important;
  }

  .plr_md--95 {
    padding: 0 95px !important;
  }

  .pt_md--95 {
    padding-top: 95px !important;
  }

  .pb_md--95 {
    padding-bottom: 95px !important;
  }

  .pl_md--95 {
    padding-left: 95px !important;
  }

  .pr_md--95 {
    padding-right: 95px !important;
  }

  .mt_md--95 {
    margin-top: 95px !important;
  }

  .mb_md--95 {
    margin-bottom: 95px !important;
  }

  .ptb_md--100 {
    padding: 100px 0 !important;
  }

  .plr_md--100 {
    padding: 0 100px !important;
  }

  .pt_md--100 {
    padding-top: 100px !important;
  }

  .pb_md--100 {
    padding-bottom: 100px !important;
  }

  .pl_md--100 {
    padding-left: 100px !important;
  }

  .pr_md--100 {
    padding-right: 100px !important;
  }

  .mt_md--100 {
    margin-top: 100px !important;
  }

  .mb_md--100 {
    margin-bottom: 100px !important;
  }
}

@media only screen and (max-width: 767px) {
  .ptb_sm--250 {
    padding: 250px 0 !important;
  }

  .ptb_sm--0 {
    padding: 0 !important;
  }

  .pl_sm--0 {
    padding-left: 0 !important;
  }

  .pr_sm--0 {
    padding-right: 0 !important;
  }

  .pt_sm--0 {
    padding-top: 0 !important;
  }

  .pb_sm--0 {
    padding-bottom: 0 !important;
  }

  .mr_sm--0 {
    margin-right: 0 !important;
  }

  .ml_sm--0 {
    margin-left: 0 !important;
  }

  .mt_sm--0 {
    margin-top: 0 !important;
  }

  .mb_sm--0 {
    margin-bottom: 0 !important;
  }

  .pt_sm--150 {
    padding-top: 150px !important;
  }

  .pb_sm--110 {
    padding-bottom: 110px !important;
  }

  .ptb_sm--5 {
    padding: 5px 0 !important;
  }

  .plr_sm--5 {
    padding: 0 5px !important;
  }

  .pt_sm--5 {
    padding-top: 5px !important;
  }

  .pb_sm--5 {
    padding-bottom: 5px !important;
  }

  .pl_sm--5 {
    padding-left: 5px !important;
  }

  .pr_sm--5 {
    padding-right: 5px !important;
  }

  .mt_sm--5 {
    margin-top: 5px !important;
  }

  .ml_sm--5 {
    margin-left: 5px !important;
  }

  .mr_sm--5 {
    margin-right: 5px !important;
  }

  .mb_sm--5 {
    margin-bottom: 5px !important;
  }

  .ptb_sm--10 {
    padding: 10px 0 !important;
  }

  .plr_sm--10 {
    padding: 0 10px !important;
  }

  .pt_sm--10 {
    padding-top: 10px !important;
  }

  .pb_sm--10 {
    padding-bottom: 10px !important;
  }

  .pl_sm--10 {
    padding-left: 10px !important;
  }

  .pr_sm--10 {
    padding-right: 10px !important;
  }

  .mt_sm--10 {
    margin-top: 10px !important;
  }

  .ml_sm--10 {
    margin-left: 10px !important;
  }

  .mr_sm--10 {
    margin-right: 10px !important;
  }

  .mb_sm--10 {
    margin-bottom: 10px !important;
  }

  .ptb_sm--15 {
    padding: 15px 0 !important;
  }

  .plr_sm--15 {
    padding: 0 15px !important;
  }

  .pt_sm--15 {
    padding-top: 15px !important;
  }

  .pb_sm--15 {
    padding-bottom: 15px !important;
  }

  .pl_sm--15 {
    padding-left: 15px !important;
  }

  .pr_sm--15 {
    padding-right: 15px !important;
  }

  .mt_sm--15 {
    margin-top: 15px !important;
  }

  .ml_sm--15 {
    margin-left: 15px !important;
  }

  .mr_sm--15 {
    margin-right: 15px !important;
  }

  .mb_sm--15 {
    margin-bottom: 15px !important;
  }

  .ptb_sm--20 {
    padding: 20px 0 !important;
  }

  .plr_sm--20 {
    padding: 0 20px !important;
  }

  .pt_sm--20 {
    padding-top: 20px !important;
  }

  .pb_sm--20 {
    padding-bottom: 20px !important;
  }

  .pl_sm--20 {
    padding-left: 20px !important;
  }

  .pr_sm--20 {
    padding-right: 20px !important;
  }

  .mt_sm--20 {
    margin-top: 20px !important;
  }

  .ml_sm--20 {
    margin-left: 20px !important;
  }

  .mr_sm--20 {
    margin-right: 20px !important;
  }

  .mb_sm--20 {
    margin-bottom: 20px !important;
  }

  .ptb_sm--25 {
    padding: 25px 0 !important;
  }

  .plr_sm--25 {
    padding: 0 25px !important;
  }

  .pt_sm--25 {
    padding-top: 25px !important;
  }

  .pb_sm--25 {
    padding-bottom: 25px !important;
  }

  .pl_sm--25 {
    padding-left: 25px !important;
  }

  .pr_sm--25 {
    padding-right: 25px !important;
  }

  .mt_sm--25 {
    margin-top: 25px !important;
  }

  .ml_sm--25 {
    margin-left: 25px !important;
  }

  .mr_sm--25 {
    margin-right: 25px !important;
  }

  .mb_sm--25 {
    margin-bottom: 25px !important;
  }

  .ptb_sm--30 {
    padding: 30px 0 !important;
  }

  .plr_sm--30 {
    padding: 0 30px !important;
  }

  .pt_sm--30 {
    padding-top: 30px !important;
  }

  .pb_sm--30 {
    padding-bottom: 30px !important;
  }

  .pl_sm--30 {
    padding-left: 30px !important;
  }

  .pr_sm--30 {
    padding-right: 30px !important;
  }

  .mt_sm--30 {
    margin-top: 30px !important;
  }

  .ml_sm--30 {
    margin-left: 30px !important;
  }

  .mr_sm--30 {
    margin-right: 30px !important;
  }

  .mb_sm--30 {
    margin-bottom: 30px !important;
  }

  .ptb_sm--35 {
    padding: 35px 0 !important;
  }

  .plr_sm--35 {
    padding: 0 35px !important;
  }

  .pt_sm--35 {
    padding-top: 35px !important;
  }

  .pb_sm--35 {
    padding-bottom: 35px !important;
  }

  .pl_sm--35 {
    padding-left: 35px !important;
  }

  .pr_sm--35 {
    padding-right: 35px !important;
  }

  .mt_sm--35 {
    margin-top: 35px !important;
  }

  .ml_sm--35 {
    margin-left: 35px !important;
  }

  .mr_sm--35 {
    margin-right: 35px !important;
  }

  .mb_sm--35 {
    margin-bottom: 35px !important;
  }

  .ptb_sm--40 {
    padding: 40px 0 !important;
  }

  .plr_sm--40 {
    padding: 0 40px !important;
  }

  .pt_sm--40 {
    padding-top: 40px !important;
  }

  .pb_sm--40 {
    padding-bottom: 40px !important;
  }

  .pl_sm--40 {
    padding-left: 40px !important;
  }

  .pr_sm--40 {
    padding-right: 40px !important;
  }

  .mt_sm--40 {
    margin-top: 40px !important;
  }

  .ml_sm--40 {
    margin-left: 40px !important;
  }

  .mr_sm--40 {
    margin-right: 40px !important;
  }

  .mb_sm--40 {
    margin-bottom: 40px !important;
  }

  .ptb_sm--45 {
    padding: 45px 0 !important;
  }

  .plr_sm--45 {
    padding: 0 45px !important;
  }

  .pt_sm--45 {
    padding-top: 45px !important;
  }

  .pb_sm--45 {
    padding-bottom: 45px !important;
  }

  .pl_sm--45 {
    padding-left: 45px !important;
  }

  .pr_sm--45 {
    padding-right: 45px !important;
  }

  .mt_sm--45 {
    margin-top: 45px !important;
  }

  .ml_sm--45 {
    margin-left: 45px !important;
  }

  .mr_sm--45 {
    margin-right: 45px !important;
  }

  .mb_sm--45 {
    margin-bottom: 45px !important;
  }

  .ptb_sm--50 {
    padding: 50px 0 !important;
  }

  .plr_sm--50 {
    padding: 0 50px !important;
  }

  .pt_sm--50 {
    padding-top: 50px !important;
  }

  .pb_sm--50 {
    padding-bottom: 50px !important;
  }

  .pl_sm--50 {
    padding-left: 50px !important;
  }

  .pr_sm--50 {
    padding-right: 50px !important;
  }

  .mt_sm--50 {
    margin-top: 50px !important;
  }

  .ml_sm--50 {
    margin-left: 50px !important;
  }

  .mr_sm--50 {
    margin-right: 50px !important;
  }

  .mb_sm--50 {
    margin-bottom: 50px !important;
  }

  .ptb_sm--55 {
    padding: 55px 0 !important;
  }

  .plr_sm--55 {
    padding: 0 55px !important;
  }

  .pt_sm--55 {
    padding-top: 55px !important;
  }

  .pb_sm--55 {
    padding-bottom: 55px !important;
  }

  .pl_sm--55 {
    padding-left: 55px !important;
  }

  .pr_sm--55 {
    padding-right: 55px !important;
  }

  .mt_sm--55 {
    margin-top: 55px !important;
  }

  .ml_sm--55 {
    margin-left: 55px !important;
  }

  .mr_sm--55 {
    margin-right: 55px !important;
  }

  .mb_sm--55 {
    margin-bottom: 55px !important;
  }

  .ptb_sm--60 {
    padding: 60px 0 !important;
  }

  .plr_sm--60 {
    padding: 0 60px !important;
  }

  .pt_sm--60 {
    padding-top: 60px !important;
  }

  .pb_sm--60 {
    padding-bottom: 60px !important;
  }

  .pl_sm--60 {
    padding-left: 60px !important;
  }

  .pr_sm--60 {
    padding-right: 60px !important;
  }

  .mt_sm--60 {
    margin-top: 60px !important;
  }

  .ml_sm--60 {
    margin-left: 60px !important;
  }

  .mr_sm--60 {
    margin-right: 60px !important;
  }

  .mb_sm--60 {
    margin-bottom: 60px !important;
  }

  .ptb_sm--65 {
    padding: 65px 0 !important;
  }

  .plr_sm--65 {
    padding: 0 65px !important;
  }

  .pt_sm--65 {
    padding-top: 65px !important;
  }

  .pb_sm--65 {
    padding-bottom: 65px !important;
  }

  .pl_sm--65 {
    padding-left: 65px !important;
  }

  .pr_sm--65 {
    padding-right: 65px !important;
  }

  .mt_sm--65 {
    margin-top: 65px !important;
  }

  .ml_sm--65 {
    margin-left: 65px !important;
  }

  .mr_sm--65 {
    margin-right: 65px !important;
  }

  .mb_sm--65 {
    margin-bottom: 65px !important;
  }

  .ptb_sm--70 {
    padding: 70px 0 !important;
  }

  .plr_sm--70 {
    padding: 0 70px !important;
  }

  .pt_sm--70 {
    padding-top: 70px !important;
  }

  .pb_sm--70 {
    padding-bottom: 70px !important;
  }

  .pl_sm--70 {
    padding-left: 70px !important;
  }

  .pr_sm--70 {
    padding-right: 70px !important;
  }

  .mt_sm--70 {
    margin-top: 70px !important;
  }

  .ml_sm--70 {
    margin-left: 70px !important;
  }

  .mr_sm--70 {
    margin-right: 70px !important;
  }

  .mb_sm--70 {
    margin-bottom: 70px !important;
  }

  .ptb_sm--75 {
    padding: 75px 0 !important;
  }

  .plr_sm--75 {
    padding: 0 75px !important;
  }

  .pt_sm--75 {
    padding-top: 75px !important;
  }

  .pb_sm--75 {
    padding-bottom: 75px !important;
  }

  .pl_sm--75 {
    padding-left: 75px !important;
  }

  .pr_sm--75 {
    padding-right: 75px !important;
  }

  .mt_sm--75 {
    margin-top: 75px !important;
  }

  .ml_sm--75 {
    margin-left: 75px !important;
  }

  .mr_sm--75 {
    margin-right: 75px !important;
  }

  .mb_sm--75 {
    margin-bottom: 75px !important;
  }

  .ptb_sm--80 {
    padding: 80px 0 !important;
  }

  .plr_sm--80 {
    padding: 0 80px !important;
  }

  .pt_sm--80 {
    padding-top: 80px !important;
  }

  .pb_sm--80 {
    padding-bottom: 80px !important;
  }

  .pl_sm--80 {
    padding-left: 80px !important;
  }

  .pr_sm--80 {
    padding-right: 80px !important;
  }

  .mt_sm--80 {
    margin-top: 80px !important;
  }

  .ml_sm--80 {
    margin-left: 80px !important;
  }

  .mr_sm--80 {
    margin-right: 80px !important;
  }

  .mb_sm--80 {
    margin-bottom: 80px !important;
  }

  .ptb_sm--85 {
    padding: 85px 0 !important;
  }

  .plr_sm--85 {
    padding: 0 85px !important;
  }

  .pt_sm--85 {
    padding-top: 85px !important;
  }

  .pb_sm--85 {
    padding-bottom: 85px !important;
  }

  .pl_sm--85 {
    padding-left: 85px !important;
  }

  .pr_sm--85 {
    padding-right: 85px !important;
  }

  .mt_sm--85 {
    margin-top: 85px !important;
  }

  .ml_sm--85 {
    margin-left: 85px !important;
  }

  .mr_sm--85 {
    margin-right: 85px !important;
  }

  .mb_sm--85 {
    margin-bottom: 85px !important;
  }

  .ptb_sm--90 {
    padding: 90px 0 !important;
  }

  .plr_sm--90 {
    padding: 0 90px !important;
  }

  .pt_sm--90 {
    padding-top: 90px !important;
  }

  .pb_sm--90 {
    padding-bottom: 90px !important;
  }

  .pl_sm--90 {
    padding-left: 90px !important;
  }

  .pr_sm--90 {
    padding-right: 90px !important;
  }

  .mt_sm--90 {
    margin-top: 90px !important;
  }

  .ml_sm--90 {
    margin-left: 90px !important;
  }

  .mr_sm--90 {
    margin-right: 90px !important;
  }

  .mb_sm--90 {
    margin-bottom: 90px !important;
  }

  .ptb_sm--95 {
    padding: 95px 0 !important;
  }

  .plr_sm--95 {
    padding: 0 95px !important;
  }

  .pt_sm--95 {
    padding-top: 95px !important;
  }

  .pb_sm--95 {
    padding-bottom: 95px !important;
  }

  .pl_sm--95 {
    padding-left: 95px !important;
  }

  .pr_sm--95 {
    padding-right: 95px !important;
  }

  .mt_sm--95 {
    margin-top: 95px !important;
  }

  .ml_sm--95 {
    margin-left: 95px !important;
  }

  .mr_sm--95 {
    margin-right: 95px !important;
  }

  .mb_sm--95 {
    margin-bottom: 95px !important;
  }

  .ptb_sm--100 {
    padding: 100px 0 !important;
  }

  .plr_sm--100 {
    padding: 0 100px !important;
  }

  .pt_sm--100 {
    padding-top: 100px !important;
  }

  .pb_sm--100 {
    padding-bottom: 100px !important;
  }

  .pl_sm--100 {
    padding-left: 100px !important;
  }

  .pr_sm--100 {
    padding-right: 100px !important;
  }

  .mt_sm--100 {
    margin-top: 100px !important;
  }

  .ml_sm--100 {
    margin-left: 100px !important;
  }

  .mr_sm--100 {
    margin-right: 100px !important;
  }

  .mb_sm--100 {
    margin-bottom: 100px !important;
  }

  .pl_sm--0 {
    padding-left: 0;
  }

  .pr_sm--0 {
    padding-right: 0;
  }

  .pt_sm--0 {
    padding-top: 0;
  }

  .pb_sm--0 {
    padding-bottom: 0;
  }

  .mr_sm--0 {
    margin-right: 0;
  }

  .ml_sm--0 {
    margin-left: 0;
  }

  .mt_sm--0 {
    margin-top: 0;
  }

  .mb_sm--0 {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  .ptb_mobile--5 {
    padding: 5px 0 !important;
  }

  .plr_mobile--5 {
    padding: 0 5px !important;
  }

  .pt_mobile--5 {
    padding-top: 5px !important;
  }

  .pb_mobile--5 {
    padding-bottom: 5px !important;
  }

  .pl_mobile--5 {
    padding-left: 5px !important;
  }

  .pr_mobile--5 {
    padding-right: 5px !important;
  }

  .mt_mobile--5 {
    margin-top: 5px !important;
  }

  .mb_mobile--5 {
    margin-bottom: 5px !important;
  }

  .ptb_mobile--10 {
    padding: 10px 0 !important;
  }

  .plr_mobile--10 {
    padding: 0 10px !important;
  }

  .pt_mobile--10 {
    padding-top: 10px !important;
  }

  .pb_mobile--10 {
    padding-bottom: 10px !important;
  }

  .pl_mobile--10 {
    padding-left: 10px !important;
  }

  .pr_mobile--10 {
    padding-right: 10px !important;
  }

  .mt_mobile--10 {
    margin-top: 10px !important;
  }

  .mb_mobile--10 {
    margin-bottom: 10px !important;
  }

  .ptb_mobile--15 {
    padding: 15px 0 !important;
  }

  .plr_mobile--15 {
    padding: 0 15px !important;
  }

  .pt_mobile--15 {
    padding-top: 15px !important;
  }

  .pb_mobile--15 {
    padding-bottom: 15px !important;
  }

  .pl_mobile--15 {
    padding-left: 15px !important;
  }

  .pr_mobile--15 {
    padding-right: 15px !important;
  }

  .mt_mobile--15 {
    margin-top: 15px !important;
  }

  .mb_mobile--15 {
    margin-bottom: 15px !important;
  }

  .ptb_mobile--20 {
    padding: 20px 0 !important;
  }

  .plr_mobile--20 {
    padding: 0 20px !important;
  }

  .pt_mobile--20 {
    padding-top: 20px !important;
  }

  .pb_mobile--20 {
    padding-bottom: 20px !important;
  }

  .pl_mobile--20 {
    padding-left: 20px !important;
  }

  .pr_mobile--20 {
    padding-right: 20px !important;
  }

  .mt_mobile--20 {
    margin-top: 20px !important;
  }

  .mb_mobile--20 {
    margin-bottom: 20px !important;
  }

  .ptb_mobile--25 {
    padding: 25px 0 !important;
  }

  .plr_mobile--25 {
    padding: 0 25px !important;
  }

  .pt_mobile--25 {
    padding-top: 25px !important;
  }

  .pb_mobile--25 {
    padding-bottom: 25px !important;
  }

  .pl_mobile--25 {
    padding-left: 25px !important;
  }

  .pr_mobile--25 {
    padding-right: 25px !important;
  }

  .mt_mobile--25 {
    margin-top: 25px !important;
  }

  .mb_mobile--25 {
    margin-bottom: 25px !important;
  }

  .ptb_mobile--30 {
    padding: 30px 0 !important;
  }

  .plr_mobile--30 {
    padding: 0 30px !important;
  }

  .pt_mobile--30 {
    padding-top: 30px !important;
  }

  .pb_mobile--30 {
    padding-bottom: 30px !important;
  }

  .pl_mobile--30 {
    padding-left: 30px !important;
  }

  .pr_mobile--30 {
    padding-right: 30px !important;
  }

  .mt_mobile--30 {
    margin-top: 30px !important;
  }

  .mb_mobile--30 {
    margin-bottom: 30px !important;
  }

  .ptb_mobile--35 {
    padding: 35px 0 !important;
  }

  .plr_mobile--35 {
    padding: 0 35px !important;
  }

  .pt_mobile--35 {
    padding-top: 35px !important;
  }

  .pb_mobile--35 {
    padding-bottom: 35px !important;
  }

  .pl_mobile--35 {
    padding-left: 35px !important;
  }

  .pr_mobile--35 {
    padding-right: 35px !important;
  }

  .mt_mobile--35 {
    margin-top: 35px !important;
  }

  .mb_mobile--35 {
    margin-bottom: 35px !important;
  }

  .ptb_mobile--40 {
    padding: 40px 0 !important;
  }

  .plr_mobile--40 {
    padding: 0 40px !important;
  }

  .pt_mobile--40 {
    padding-top: 40px !important;
  }

  .pb_mobile--40 {
    padding-bottom: 40px !important;
  }

  .pl_mobile--40 {
    padding-left: 40px !important;
  }

  .pr_mobile--40 {
    padding-right: 40px !important;
  }

  .mt_mobile--40 {
    margin-top: 40px !important;
  }

  .mb_mobile--40 {
    margin-bottom: 40px !important;
  }

  .ptb_mobile--45 {
    padding: 45px 0 !important;
  }

  .plr_mobile--45 {
    padding: 0 45px !important;
  }

  .pt_mobile--45 {
    padding-top: 45px !important;
  }

  .pb_mobile--45 {
    padding-bottom: 45px !important;
  }

  .pl_mobile--45 {
    padding-left: 45px !important;
  }

  .pr_mobile--45 {
    padding-right: 45px !important;
  }

  .mt_mobile--45 {
    margin-top: 45px !important;
  }

  .mb_mobile--45 {
    margin-bottom: 45px !important;
  }

  .ptb_mobile--50 {
    padding: 50px 0 !important;
  }

  .plr_mobile--50 {
    padding: 0 50px !important;
  }

  .pt_mobile--50 {
    padding-top: 50px !important;
  }

  .pb_mobile--50 {
    padding-bottom: 50px !important;
  }

  .pl_mobile--50 {
    padding-left: 50px !important;
  }

  .pr_mobile--50 {
    padding-right: 50px !important;
  }

  .mt_mobile--50 {
    margin-top: 50px !important;
  }

  .mb_mobile--50 {
    margin-bottom: 50px !important;
  }

  .ptb_mobile--55 {
    padding: 55px 0 !important;
  }

  .plr_mobile--55 {
    padding: 0 55px !important;
  }

  .pt_mobile--55 {
    padding-top: 55px !important;
  }

  .pb_mobile--55 {
    padding-bottom: 55px !important;
  }

  .pl_mobile--55 {
    padding-left: 55px !important;
  }

  .pr_mobile--55 {
    padding-right: 55px !important;
  }

  .mt_mobile--55 {
    margin-top: 55px !important;
  }

  .mb_mobile--55 {
    margin-bottom: 55px !important;
  }

  .ptb_mobile--60 {
    padding: 60px 0 !important;
  }

  .plr_mobile--60 {
    padding: 0 60px !important;
  }

  .pt_mobile--60 {
    padding-top: 60px !important;
  }

  .pb_mobile--60 {
    padding-bottom: 60px !important;
  }

  .pl_mobile--60 {
    padding-left: 60px !important;
  }

  .pr_mobile--60 {
    padding-right: 60px !important;
  }

  .mt_mobile--60 {
    margin-top: 60px !important;
  }

  .mb_mobile--60 {
    margin-bottom: 60px !important;
  }

  .ptb_mobile--65 {
    padding: 65px 0 !important;
  }

  .plr_mobile--65 {
    padding: 0 65px !important;
  }

  .pt_mobile--65 {
    padding-top: 65px !important;
  }

  .pb_mobile--65 {
    padding-bottom: 65px !important;
  }

  .pl_mobile--65 {
    padding-left: 65px !important;
  }

  .pr_mobile--65 {
    padding-right: 65px !important;
  }

  .mt_mobile--65 {
    margin-top: 65px !important;
  }

  .mb_mobile--65 {
    margin-bottom: 65px !important;
  }

  .ptb_mobile--70 {
    padding: 70px 0 !important;
  }

  .plr_mobile--70 {
    padding: 0 70px !important;
  }

  .pt_mobile--70 {
    padding-top: 70px !important;
  }

  .pb_mobile--70 {
    padding-bottom: 70px !important;
  }

  .pl_mobile--70 {
    padding-left: 70px !important;
  }

  .pr_mobile--70 {
    padding-right: 70px !important;
  }

  .mt_mobile--70 {
    margin-top: 70px !important;
  }

  .mb_mobile--70 {
    margin-bottom: 70px !important;
  }

  .ptb_mobile--75 {
    padding: 75px 0 !important;
  }

  .plr_mobile--75 {
    padding: 0 75px !important;
  }

  .pt_mobile--75 {
    padding-top: 75px !important;
  }

  .pb_mobile--75 {
    padding-bottom: 75px !important;
  }

  .pl_mobile--75 {
    padding-left: 75px !important;
  }

  .pr_mobile--75 {
    padding-right: 75px !important;
  }

  .mt_mobile--75 {
    margin-top: 75px !important;
  }

  .mb_mobile--75 {
    margin-bottom: 75px !important;
  }

  .ptb_mobile--80 {
    padding: 80px 0 !important;
  }

  .plr_mobile--80 {
    padding: 0 80px !important;
  }

  .pt_mobile--80 {
    padding-top: 80px !important;
  }

  .pb_mobile--80 {
    padding-bottom: 80px !important;
  }

  .pl_mobile--80 {
    padding-left: 80px !important;
  }

  .pr_mobile--80 {
    padding-right: 80px !important;
  }

  .mt_mobile--80 {
    margin-top: 80px !important;
  }

  .mb_mobile--80 {
    margin-bottom: 80px !important;
  }

  .ptb_mobile--85 {
    padding: 85px 0 !important;
  }

  .plr_mobile--85 {
    padding: 0 85px !important;
  }

  .pt_mobile--85 {
    padding-top: 85px !important;
  }

  .pb_mobile--85 {
    padding-bottom: 85px !important;
  }

  .pl_mobile--85 {
    padding-left: 85px !important;
  }

  .pr_mobile--85 {
    padding-right: 85px !important;
  }

  .mt_mobile--85 {
    margin-top: 85px !important;
  }

  .mb_mobile--85 {
    margin-bottom: 85px !important;
  }

  .ptb_mobile--90 {
    padding: 90px 0 !important;
  }

  .plr_mobile--90 {
    padding: 0 90px !important;
  }

  .pt_mobile--90 {
    padding-top: 90px !important;
  }

  .pb_mobile--90 {
    padding-bottom: 90px !important;
  }

  .pl_mobile--90 {
    padding-left: 90px !important;
  }

  .pr_mobile--90 {
    padding-right: 90px !important;
  }

  .mt_mobile--90 {
    margin-top: 90px !important;
  }

  .mb_mobile--90 {
    margin-bottom: 90px !important;
  }

  .ptb_mobile--95 {
    padding: 95px 0 !important;
  }

  .plr_mobile--95 {
    padding: 0 95px !important;
  }

  .pt_mobile--95 {
    padding-top: 95px !important;
  }

  .pb_mobile--95 {
    padding-bottom: 95px !important;
  }

  .pl_mobile--95 {
    padding-left: 95px !important;
  }

  .pr_mobile--95 {
    padding-right: 95px !important;
  }

  .mt_mobile--95 {
    margin-top: 95px !important;
  }

  .mb_mobile--95 {
    margin-bottom: 95px !important;
  }

  .ptb_mobile--100 {
    padding: 100px 0 !important;
  }

  .plr_mobile--100 {
    padding: 0 100px !important;
  }

  .pt_mobile--100 {
    padding-top: 100px !important;
  }

  .pb_mobile--100 {
    padding-bottom: 100px !important;
  }

  .pl_mobile--100 {
    padding-left: 100px !important;
  }

  .pr_mobile--100 {
    padding-right: 100px !important;
  }

  .mt_mobile--100 {
    margin-top: 100px !important;
  }

  .mb_mobile--100 {
    margin-bottom: 100px !important;
  }
}

.slick-gutter-5 {
  margin-left: -5px;
  margin-right: -5px;
}

.slick-gutter-5 .slick-slide {
  padding-left: 5px;
  padding-right: 5px;
}

.slick-gutter-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.slick-gutter-10 .slick-slide {
  padding-left: 10px;
  padding-right: 10px;
}

.slick-gutter-15 {
  margin-left: -15px;
  margin-right: -15px;
}

.slick-gutter-15 .slick-slide {
  padding-left: 15px;
  padding-right: 15px;
}

.slick-gutter-20 {
  margin-left: -20px;
  margin-right: -20px;
}

.slick-gutter-20 .slick-slide {
  padding-left: 20px;
  padding-right: 20px;
}

.slick-gutter-25 {
  margin-left: -25px;
  margin-right: -25px;
}

.slick-gutter-25 .slick-slide {
  padding-left: 25px;
  padding-right: 25px;
}

.slick-gutter-30 {
  margin-left: -30px;
  margin-right: -30px;
}

.slick-gutter-30 .slick-slide {
  padding-left: 30px;
  padding-right: 30px;
}

.slick-gutter-35 {
  margin-left: -35px;
  margin-right: -35px;
}

.slick-gutter-35 .slick-slide {
  padding-left: 35px;
  padding-right: 35px;
}

.slick-gutter-40 {
  margin-left: -40px;
  margin-right: -40px;
}

.slick-gutter-40 .slick-slide {
  padding-left: 40px;
  padding-right: 40px;
}

.slick-gutter-45 {
  margin-left: -45px;
  margin-right: -45px;
}

.slick-gutter-45 .slick-slide {
  padding-left: 45px;
  padding-right: 45px;
}

.slick-gutter-50 {
  margin-left: -50px;
  margin-right: -50px;
}

.slick-gutter-50 .slick-slide {
  padding-left: 50px;
  padding-right: 50px;
}

.slick-gutter-55 {
  margin-left: -55px;
  margin-right: -55px;
}

.slick-gutter-55 .slick-slide {
  padding-left: 55px;
  padding-right: 55px;
}

.slick-gutter-60 {
  margin-left: -60px;
  margin-right: -60px;
}

.slick-gutter-60 .slick-slide {
  padding-left: 60px;
  padding-right: 60px;
}

.slick-gutter-65 {
  margin-left: -65px;
  margin-right: -65px;
}

.slick-gutter-65 .slick-slide {
  padding-left: 65px;
  padding-right: 65px;
}

.slick-gutter-70 {
  margin-left: -70px;
  margin-right: -70px;
}

.slick-gutter-70 .slick-slide {
  padding-left: 70px;
  padding-right: 70px;
}

.slick-gutter-75 {
  margin-left: -75px;
  margin-right: -75px;
}

.slick-gutter-75 .slick-slide {
  padding-left: 75px;
  padding-right: 75px;
}

.slick-gutter-80 {
  margin-left: -80px;
  margin-right: -80px;
}

.slick-gutter-80 .slick-slide {
  padding-left: 80px;
  padding-right: 80px;
}

.slick-gutter-85 {
  margin-left: -85px;
  margin-right: -85px;
}

.slick-gutter-85 .slick-slide {
  padding-left: 85px;
  padding-right: 85px;
}

.slick-gutter-90 {
  margin-left: -90px;
  margin-right: -90px;
}

.slick-gutter-90 .slick-slide {
  padding-left: 90px;
  padding-right: 90px;
}

.slick-gutter-95 {
  margin-left: -95px;
  margin-right: -95px;
}

.slick-gutter-95 .slick-slide {
  padding-left: 95px;
  padding-right: 95px;
}

.slick-gutter-100 {
  margin-left: -100px;
  margin-right: -100px;
}

.slick-gutter-100 .slick-slide {
  padding-left: 100px;
  padding-right: 100px;
}

.mt-dec-30 {
  margin-top: -30px !important;
}

.mt_dec--30 {
  margin-top: -30px !important;
}

.mt-dec-100 {
  margin-top: -100px !important;
}

@media only screen and (max-width: 479px) {
  .small-margin-pricing {
    margin-bottom: 25px !important;
  }
}

@media only screen and (max-width: 479px) {
  .contact-input {
    margin-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mb_dec--35 {
    margin-bottom: -50px;
  }
}

@media only screen and (max-width: 767px) {
  .mb_dec--35 {
    margin-bottom: -75px;
  }
}

@media only screen and (max-width: 575px) {
  .mb_dec--35 {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 575px) {
  .mt-contact-sm {
    margin-top: 30px !important;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-pb {
    padding-bottom: 35px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-input {
    padding-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .contact-input {
    padding-bottom: 30px;
  }
}

.pb_xl--130 {
  padding-bottom: 130px;
}

@media only screen and (max-width: 1199px) {
  .pb_xl--130 {
    padding-bottom: 110px;
  }
}

@media only screen and (max-width: 1199px) {
  .mt_experience {
    margin-top: -10px;
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

a {
  color: var(--color-heading);
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: var(--color-primary);
}

a:focus {
  outline: none;
}

address {
  margin: 0 0 24px;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

mark {
  background: var(--color-primary);
  color: #ffffff;
}

code,
kbd,
pre,
samp {
  font-size: var(--font-size-b3);
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  color: var(--color-primary);
}

kbd,
ins {
  color: #ffffff;
}

pre {
  font-family: "Raleway", sans-serif;
  font-size: var(--font-size-b3);
  margin: 10px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
  color: var(--color-body);
  background: var(--color-lighter);
}

small {
  font-size: smaller;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

dl {
  margin-top: 0;
  margin-bottom: 10px;
}

dd {
  margin: 0 15px 15px;
}

dt {
  font-weight: bold;
  color: var(--color-heading);
}

menu,
ol,
ul {
  margin: 16px 0;
  padding: 0 0 0 40px;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

li > ul,
li > ol {
  margin: 0;
}

ol ul {
  margin-bottom: 0;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid var(--color-border);
  margin: 0 2px;
  min-width: inherit;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  appearance: textfield;
  padding-right: 2px;
  width: 270px;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

caption,
th,
td {
  font-weight: normal;
}

th {
  font-weight: 500;
  text-transform: uppercase;
}

td,
.wp-block-calendar tfoot td {
  border: 1px solid var(--color-border);
  padding: 7px 10px;
}

del {
  color: #333;
}

ins {
  background: rgba(255, 47, 47, 0.4);
  text-decoration: none;
}

hr {
  background-size: 4px 4px;
  border: 0;
  height: 1px;
  margin: 0 0 24px;
}

table a,
table a:link,
table a:visited {
  text-decoration: underline;
}

dt {
  font-weight: bold;
  margin-bottom: 10px;
}

dd {
  margin: 0 15px 15px;
}

caption {
  caption-side: top;
}

kbd {
  background: var(--heading-color);
}

dfn,
cite,
em {
  font-style: italic;
}

blockquote,
q {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

blockquote {
  font-size: var(--font-size-b1);
  font-style: italic;
  font-weight: var(--p-light);
  margin: 24px 40px;
}

blockquote blockquote {
  margin-right: 0;
}

blockquote cite,
blockquote small {
  font-size: var(--font-size-b3);
  font-weight: normal;
}

blockquote strong,
blockquote b {
  font-weight: 700;
}

input,
button,
select,
textarea {
  background: transparent;
  border: 1px solid var(--color-border);
  -webkit-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  color: var(--color-body);
  width: 100%;
}

input:focus,
input:active,
button:focus,
button:active,
select:focus,
select:active,
textarea:focus,
textarea:active {
  outline: none;
  border-color: var(--color-primary);
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

input {
  height: 40px;
  padding: 0 15px;
}

select {
  -webkit-transition: 0.3s;
  transition: 0.3s;
  height: 40px;
  padding: 0 20px;
  outline: none;
  color: var(--color-body);
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid var(--color-border);
  border-radius: 4px;
  padding-right: 32px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
textarea {
  font-size: var(--font-size-b2);
  font-weight: 400;
  height: auto;
  line-height: 28px;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 15px;
  outline: none;
  border: var(--border-width) solid var(--color-border);
  border-radius: var(--radius);
}

input[type="text"]::-webkit-input-placeholder,
input[type="password"]::-webkit-input-placeholder,
input[type="email"]::-webkit-input-placeholder,
input[type="number"]::-webkit-input-placeholder,
input[type="tel"]::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: var(--body-color);
  opacity: 1;
}

input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: var(--body-color);
  opacity: 1;
}

input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="email"]::placeholder,
input[type="number"]::placeholder,
input[type="tel"]::placeholder,
textarea::placeholder {
  color: var(--body-color);
  opacity: 1;
}

input[type="text"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="number"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: var(--body-color);
}

input[type="text"]::-ms-input-placeholder,
input[type="password"]::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder,
input[type="number"]::-ms-input-placeholder,
input[type="tel"]::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: var(--body-color);
}

input[type="text"].p-holder__active,
.input-active input[type="text"],
input[type="text"].input-active,
input[type="password"].p-holder__active,
.input-active input[type="password"],
input[type="password"].input-active,
input[type="email"].p-holder__active,
.input-active input[type="email"],
input[type="email"].input-active,
input[type="number"].p-holder__active,
.input-active input[type="number"],
input[type="number"].input-active,
input[type="tel"].p-holder__active,
.input-active input[type="tel"],
input[type="tel"].input-active,
textarea.p-holder__active,
textarea.input-active {
  border-color: var(--color-primary);
}

input[type="text"].p-holder__active::-webkit-input-placeholder,
.input-active input[type="text"]::-webkit-input-placeholder,
input[type="text"].input-active::-webkit-input-placeholder,
input[type="password"].p-holder__active::-webkit-input-placeholder,
.input-active input[type="password"]::-webkit-input-placeholder,
input[type="password"].input-active::-webkit-input-placeholder,
input[type="email"].p-holder__active::-webkit-input-placeholder,
.input-active input[type="email"]::-webkit-input-placeholder,
input[type="email"].input-active::-webkit-input-placeholder,
input[type="number"].p-holder__active::-webkit-input-placeholder,
.input-active input[type="number"]::-webkit-input-placeholder,
input[type="number"].input-active::-webkit-input-placeholder,
input[type="tel"].p-holder__active::-webkit-input-placeholder,
.input-active input[type="tel"]::-webkit-input-placeholder,
input[type="tel"].input-active::-webkit-input-placeholder,
textarea.p-holder__active::-webkit-input-placeholder,
textarea.input-active::-webkit-input-placeholder {
  color: var(--color-primary);
  opacity: 1;
}

input[type="text"].p-holder__active:-ms-input-placeholder,
.input-active input[type="text"]:-ms-input-placeholder,
input[type="text"].input-active:-ms-input-placeholder,
input[type="password"].p-holder__active:-ms-input-placeholder,
.input-active input[type="password"]:-ms-input-placeholder,
input[type="password"].input-active:-ms-input-placeholder,
input[type="email"].p-holder__active:-ms-input-placeholder,
.input-active input[type="email"]:-ms-input-placeholder,
input[type="email"].input-active:-ms-input-placeholder,
input[type="number"].p-holder__active:-ms-input-placeholder,
.input-active input[type="number"]:-ms-input-placeholder,
input[type="number"].input-active:-ms-input-placeholder,
input[type="tel"].p-holder__active:-ms-input-placeholder,
.input-active input[type="tel"]:-ms-input-placeholder,
input[type="tel"].input-active:-ms-input-placeholder,
textarea.p-holder__active:-ms-input-placeholder,
textarea.input-active:-ms-input-placeholder {
  color: var(--color-primary);
  opacity: 1;
}

input[type="text"].p-holder__active::placeholder,
.input-active input[type="text"]::placeholder,
input[type="text"].input-active::placeholder,
input[type="password"].p-holder__active::placeholder,
.input-active input[type="password"]::placeholder,
input[type="password"].input-active::placeholder,
input[type="email"].p-holder__active::placeholder,
.input-active input[type="email"]::placeholder,
input[type="email"].input-active::placeholder,
input[type="number"].p-holder__active::placeholder,
.input-active input[type="number"]::placeholder,
input[type="number"].input-active::placeholder,
input[type="tel"].p-holder__active::placeholder,
.input-active input[type="tel"]::placeholder,
input[type="tel"].input-active::placeholder,
textarea.p-holder__active::placeholder,
textarea.input-active::placeholder {
  color: var(--color-primary);
  opacity: 1;
}

input[type="text"].p-holder__active:-ms-input-placeholder,
.input-active input[type="text"]:-ms-input-placeholder,
input[type="text"].input-active:-ms-input-placeholder,
input[type="password"].p-holder__active:-ms-input-placeholder,
.input-active input[type="password"]:-ms-input-placeholder,
input[type="password"].input-active:-ms-input-placeholder,
input[type="email"].p-holder__active:-ms-input-placeholder,
.input-active input[type="email"]:-ms-input-placeholder,
input[type="email"].input-active:-ms-input-placeholder,
input[type="number"].p-holder__active:-ms-input-placeholder,
.input-active input[type="number"]:-ms-input-placeholder,
input[type="number"].input-active:-ms-input-placeholder,
input[type="tel"].p-holder__active:-ms-input-placeholder,
.input-active input[type="tel"]:-ms-input-placeholder,
input[type="tel"].input-active:-ms-input-placeholder,
textarea.p-holder__active:-ms-input-placeholder,
textarea.input-active:-ms-input-placeholder {
  color: var(--color-primary);
}

input[type="text"].p-holder__active::-ms-input-placeholder,
.input-active input[type="text"]::-ms-input-placeholder,
input[type="text"].input-active::-ms-input-placeholder,
input[type="password"].p-holder__active::-ms-input-placeholder,
.input-active input[type="password"]::-ms-input-placeholder,
input[type="password"].input-active::-ms-input-placeholder,
input[type="email"].p-holder__active::-ms-input-placeholder,
.input-active input[type="email"]::-ms-input-placeholder,
input[type="email"].input-active::-ms-input-placeholder,
input[type="number"].p-holder__active::-ms-input-placeholder,
.input-active input[type="number"]::-ms-input-placeholder,
input[type="number"].input-active::-ms-input-placeholder,
input[type="tel"].p-holder__active::-ms-input-placeholder,
.input-active input[type="tel"]::-ms-input-placeholder,
input[type="tel"].input-active::-ms-input-placeholder,
textarea.p-holder__active::-ms-input-placeholder,
textarea.input-active::-ms-input-placeholder {
  color: var(--color-primary);
}

input[type="text"].p-holder__error,
.input-error input[type="text"],
input[type="text"].input-error,
input[type="password"].p-holder__error,
.input-error input[type="password"],
input[type="password"].input-error,
input[type="email"].p-holder__error,
.input-error input[type="email"],
input[type="email"].input-error,
input[type="number"].p-holder__error,
.input-error input[type="number"],
input[type="number"].input-error,
input[type="tel"].p-holder__error,
.input-error input[type="tel"],
input[type="tel"].input-error,
textarea.p-holder__error,
textarea.input-error {
  border-color: #f4282d;
}

input[type="text"].p-holder__error::-webkit-input-placeholder,
.input-error input[type="text"]::-webkit-input-placeholder,
input[type="text"].input-error::-webkit-input-placeholder,
input[type="password"].p-holder__error::-webkit-input-placeholder,
.input-error input[type="password"]::-webkit-input-placeholder,
input[type="password"].input-error::-webkit-input-placeholder,
input[type="email"].p-holder__error::-webkit-input-placeholder,
.input-error input[type="email"]::-webkit-input-placeholder,
input[type="email"].input-error::-webkit-input-placeholder,
input[type="number"].p-holder__error::-webkit-input-placeholder,
.input-error input[type="number"]::-webkit-input-placeholder,
input[type="number"].input-error::-webkit-input-placeholder,
input[type="tel"].p-holder__error::-webkit-input-placeholder,
.input-error input[type="tel"]::-webkit-input-placeholder,
input[type="tel"].input-error::-webkit-input-placeholder,
textarea.p-holder__error::-webkit-input-placeholder,
textarea.input-error::-webkit-input-placeholder {
  color: #f4282d;
  opacity: 1;
}

input[type="text"].p-holder__error:-ms-input-placeholder,
.input-error input[type="text"]:-ms-input-placeholder,
input[type="text"].input-error:-ms-input-placeholder,
input[type="password"].p-holder__error:-ms-input-placeholder,
.input-error input[type="password"]:-ms-input-placeholder,
input[type="password"].input-error:-ms-input-placeholder,
input[type="email"].p-holder__error:-ms-input-placeholder,
.input-error input[type="email"]:-ms-input-placeholder,
input[type="email"].input-error:-ms-input-placeholder,
input[type="number"].p-holder__error:-ms-input-placeholder,
.input-error input[type="number"]:-ms-input-placeholder,
input[type="number"].input-error:-ms-input-placeholder,
input[type="tel"].p-holder__error:-ms-input-placeholder,
.input-error input[type="tel"]:-ms-input-placeholder,
input[type="tel"].input-error:-ms-input-placeholder,
textarea.p-holder__error:-ms-input-placeholder,
textarea.input-error:-ms-input-placeholder {
  color: #f4282d;
  opacity: 1;
}

input[type="text"].p-holder__error::placeholder,
.input-error input[type="text"]::placeholder,
input[type="text"].input-error::placeholder,
input[type="password"].p-holder__error::placeholder,
.input-error input[type="password"]::placeholder,
input[type="password"].input-error::placeholder,
input[type="email"].p-holder__error::placeholder,
.input-error input[type="email"]::placeholder,
input[type="email"].input-error::placeholder,
input[type="number"].p-holder__error::placeholder,
.input-error input[type="number"]::placeholder,
input[type="number"].input-error::placeholder,
input[type="tel"].p-holder__error::placeholder,
.input-error input[type="tel"]::placeholder,
input[type="tel"].input-error::placeholder,
textarea.p-holder__error::placeholder,
textarea.input-error::placeholder {
  color: #f4282d;
  opacity: 1;
}

input[type="text"].p-holder__error:-ms-input-placeholder,
.input-error input[type="text"]:-ms-input-placeholder,
input[type="text"].input-error:-ms-input-placeholder,
input[type="password"].p-holder__error:-ms-input-placeholder,
.input-error input[type="password"]:-ms-input-placeholder,
input[type="password"].input-error:-ms-input-placeholder,
input[type="email"].p-holder__error:-ms-input-placeholder,
.input-error input[type="email"]:-ms-input-placeholder,
input[type="email"].input-error:-ms-input-placeholder,
input[type="number"].p-holder__error:-ms-input-placeholder,
.input-error input[type="number"]:-ms-input-placeholder,
input[type="number"].input-error:-ms-input-placeholder,
input[type="tel"].p-holder__error:-ms-input-placeholder,
.input-error input[type="tel"]:-ms-input-placeholder,
input[type="tel"].input-error:-ms-input-placeholder,
textarea.p-holder__error:-ms-input-placeholder,
textarea.input-error:-ms-input-placeholder {
  color: #f4282d;
}

input[type="text"].p-holder__error::-ms-input-placeholder,
.input-error input[type="text"]::-ms-input-placeholder,
input[type="text"].input-error::-ms-input-placeholder,
input[type="password"].p-holder__error::-ms-input-placeholder,
.input-error input[type="password"]::-ms-input-placeholder,
input[type="password"].input-error::-ms-input-placeholder,
input[type="email"].p-holder__error::-ms-input-placeholder,
.input-error input[type="email"]::-ms-input-placeholder,
input[type="email"].input-error::-ms-input-placeholder,
input[type="number"].p-holder__error::-ms-input-placeholder,
.input-error input[type="number"]::-ms-input-placeholder,
input[type="number"].input-error::-ms-input-placeholder,
input[type="tel"].p-holder__error::-ms-input-placeholder,
.input-error input[type="tel"]::-ms-input-placeholder,
input[type="tel"].input-error::-ms-input-placeholder,
textarea.p-holder__error::-ms-input-placeholder,
textarea.input-error::-ms-input-placeholder {
  color: #f4282d;
}

input[type="text"].p-holder__error:focus,
.input-error input[type="text"]:focus,
input[type="text"].input-error:focus,
input[type="password"].p-holder__error:focus,
.input-error input[type="password"]:focus,
input[type="password"].input-error:focus,
input[type="email"].p-holder__error:focus,
.input-error input[type="email"]:focus,
input[type="email"].input-error:focus,
input[type="number"].p-holder__error:focus,
.input-error input[type="number"]:focus,
input[type="number"].input-error:focus,
input[type="tel"].p-holder__error:focus,
.input-error input[type="tel"]:focus,
input[type="tel"].input-error:focus,
textarea.p-holder__error:focus,
textarea.input-error:focus {
  border-color: #f4282d;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
textarea:focus {
  border-color: var(--color-primary);
}

input[type="checkbox"],
input[type="radio"] {
  opacity: 0;
  position: absolute;
}

input[type="checkbox"] ~ label,
input[type="radio"] ~ label {
  position: relative;
  font-size: 12px;
  line-height: 17px;
  color: var(--color-gray);
  font-weight: 400;
  padding-left: 20px;
  cursor: pointer;
}

input[type="checkbox"] ~ label::before,
input[type="radio"] ~ label::before {
  content: " ";
  position: absolute;
  top: 2px;
  left: 0;
  width: 14px;
  height: 14px;
  background-color: #fff;
  border: var(--border-width) solid var(--color-lightest);
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"] ~ label::after,
input[type="radio"] ~ label::after {
  content: " ";
  position: absolute;
  top: 5px;
  left: 2px;
  width: 10px;
  height: 5px;
  background-color: transparent;
  border-bottom: var(--border-width) solid #fff;
  border-left: var(--border-width) solid #fff;
  border-radius: 2px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  opacity: 0;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"]:checked ~ label::before,
input[type="radio"]:checked ~ label::before {
  background-color: var(--color-primary);
  border: var(--border-width) solid var(--color-primary);
}

input[type="checkbox"]:checked ~ label::after,
input[type="radio"]:checked ~ label::after {
  opacity: 1;
}

input[type="radio"] ~ label::before {
  border-radius: 50%;
}

input[type="radio"] ~ label::after {
  width: 8px;
  height: 8px;
  left: 3px;
  background: #fff;
  border-radius: 50%;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}

.form-group input {
  border: 0 none;
  border-radius: 4px;
  height: 50px;
  font-size: var(--font-size-b2);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  padding: 0 20px;
  background-color: var(--color-lightest);
  border: 1px solid transparent;
  transition: var(--transition);
}

.form-group input:focus {
  border-color: var(--color-primary);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-group textarea {
  min-height: 160px;
  border: 0 none;
  border-radius: 4px;
  resize: none;
  padding: 15px;
  font-size: var(--font-size-b2);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--color-lightest);
  border: 1px solid transparent;
}

.form-group textarea:focus {
  border-color: var(--color-primary);
}

input[type="submit"] {
  width: auto;
  padding: 0 30px;
  border-radius: 500px;
  display: inline-block;
  font-weight: 500;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  height: 60px;
  background: var(--color-primary);
  color: var(--color-white);
  font-weight: var(--p-medium);
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b3);
  height: 50px;
  border: 2px solid var(--color-primary);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

input[type="submit"]:hover {
  background: transparent;
  color: var(--color-primary);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.fix {
  overflow: hidden;
}

.slick-initialized .slick-slide {
  margin-bottom: -10px;
}

.bg-color-primary {
  background: var(--color-primary);
}

.bg-color-secondary {
  background: var(--color-secondary);
}

.bg-color-tertiary {
  background: var(--color-tertiary);
}

.bg-color-gray {
  background: var(--color-gray);
}

.bg-color-white {
  background: #ffffff;
}

.bg-color-black {
  background: #1a1a1a;
}

.bg-color-extra03 {
  background: var(--color-extra03);
}

.bg_image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.fullscreen {
  min-height: 100vh;
  width: 100%;
}

.row--0 {
  margin-left: 0px;
  margin-right: 0px;
}

.row--0 > [class*="col"] {
  padding-left: 0px;
  padding-right: 0px;
}

.row--5 {
  margin-left: -5px;
  margin-right: -5px;
}

.row--5 > [class*="col"] {
  padding-left: 5px;
  padding-right: 5px;
}

.row--10 {
  margin-left: -10px;
  margin-right: -10px;
}

.row--10 > [class*="col"] {
  padding-left: 10px;
  padding-right: 10px;
}

.row--20 {
  margin-left: -20px;
  margin-right: -20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--20 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--20 > [class*="col"],
.row--20 > [class*="col-"] {
  padding-left: 20px;
  padding-right: 20px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--20 > [class*="col"],
  .row--20 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--25 {
  margin-left: -25px;
  margin-right: -25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--25 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--25 > [class*="col"],
.row--25 > [class*="col-"] {
  padding-left: 25px;
  padding-right: 25px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--25 > [class*="col"],
  .row--25 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--30 > [class*="col"],
.row--30 > [class*="col-"] {
  padding-left: 30px;
  padding-right: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--30 > [class*="col"],
  .row--30 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--45 {
  margin-left: -45px;
  margin-right: -45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--45 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--45 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--45 > [class*="col"],
.row--45 > [class*="col-"] {
  padding-left: 45px;
  padding-right: 45px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--45 > [class*="col"],
  .row--45 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--45 > [class*="col"],
  .row--45 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--45 > [class*="col"],
  .row--45 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--45 > [class*="col"],
  .row--45 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--40 {
  margin-left: -40px;
  margin-right: -40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--40 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--40 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--40 > [class*="col"],
.row--40 > [class*="col-"] {
  padding-left: 40px;
  padding-right: 40px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--40 > [class*="col"],
  .row--40 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--40 > [class*="col"],
  .row--40 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--40 > [class*="col"],
  .row--40 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--40 > [class*="col"],
  .row--40 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--60 {
  margin-left: -60px;
  margin-right: -60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--60 {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media only screen and (max-width: 767px) {
  .row--60 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}

.row--60 > [class*="col"],
.row--60 > [class*="col-"] {
  padding-left: 60px;
  padding-right: 60px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .row--60 > [class*="col"],
  .row--60 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--60 > [class*="col"],
  .row--60 > [class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--60 > [class*="col"],
  .row--60 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

@media only screen and (max-width: 767px) {
  .row--60 > [class*="col"],
  .row--60 > [class*="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}

[data-overlay],
[data-black-overlay],
[data-white-overlay] {
  position: relative;
  z-index: 2;
}

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > *,
[data-white-overlay] > div,
[data-white-overlay] > * {
  position: relative;
  z-index: 2;
}

[data-overlay]:before,
[data-black-overlay]:before,
[data-white-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}

[data-overlay]:before {
  background: var(--color-primary);
}

[data-black-overlay]:before {
  background-color: #130d0d;
}

[data-white-overlay]:before {
  background-color: #ffffff;
}

[data-overlay="1"]:before,
[data-black-overlay="1"]:before,
[data-white-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="2"]:before,
[data-black-overlay="2"]:before,
[data-white-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="3"]:before,
[data-black-overlay="3"]:before,
[data-white-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="4"]:before,
[data-black-overlay="4"]:before,
[data-white-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="5"]:before,
[data-black-overlay="5"]:before,
[data-white-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="6"]:before,
[data-black-overlay="6"]:before,
[data-white-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="7"]:before,
[data-black-overlay="7"]:before,
[data-white-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="8"]:before,
[data-black-overlay="8"]:before,
[data-white-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="9"]:before,
[data-black-overlay="9"]:before,
[data-white-overlay="9"]:before {
  opacity: 0.9;
}

[data-overlay="10"]:before,
[data-black-overlay="10"]:before,
[data-white-overlay="10"]:before {
  opacity: 1;
}

#scrollUp {
  width: 70px;
  height: 80px;
  right: 100px;
  bottom: 60px;
  text-align: center;
  z-index: 9811 !important;
  text-decoration: none;
  background: #fff;
  line-height: 80px;
  color: #757589;
  font-size: 15px;
  font-weight: 400;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  background: #ffffff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  #scrollUp {
    right: 20px;
    bottom: 40px;
  }
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    right: 20px;
    bottom: 40px;
  }
}

#scrollUp::before {
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  background: rgba(0, 2, 72, 0.1);
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-10deg);
  transform: rotateY(-10deg);
  -webkit-filter: blur(50px);
  filter: blur(50px);
}

#scrollUp::after {
  background: #ffffff;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transform: rotateY(-10deg);
  transform: rotateY(-10deg);
}

@media only screen and (max-width: 767px) {
  #scrollUp {
    right: 20px;
    bottom: 30px;
    width: 50px;
    height: 60px;
    line-height: 60px;
  }
}

#scrollUp span.text {
  position: relative;
  display: inline-block;
  margin-top: 7px;
}

@media only screen and (max-width: 767px) {
  #scrollUp span.text {
    margin-top: 3px;
  }
}

#scrollUp span.text::after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 7px 5px;
  border-color: transparent transparent var(--color-primary) transparent;
  position: absolute;
  content: "";
  left: 50%;
  top: 21%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#scrollUp:hover span.text {
  color: var(--color-primary);
}

.form-message {
  margin-bottom: 0;
  text-align: center;
}

.form-message.error {
  margin-top: 20px;
  color: #f80707;
}

.form-message.success {
  margin-top: 20px;
  color: #0d8d2d;
}

.template-color-2 {
  background: #1d1e24;
}

.border-none {
  border: none !important;
}

.h-100 {
  height: 100%;
}

.liststyle {
  padding: 0;
  list-style: none;
}

.color-white {
  color: var(--color-white) !important;
}

.hover-effect-gradient {
  position: relative;
  z-index: 1;
}

.hover-effect-gradient::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.hover-effect-gradient:hover::before {
  opacity: 1;
}

.video-play-button {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  width: 32px;
  height: 44px;
  border-radius: 50%;
  padding: 18px 20px 18px 28px;
}

.video-play-button:before {
  content: "";
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 120px;
  height: 120px;
  background: #212428;
  border-radius: 50%;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

.video-play-button:after {
  content: "";
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  display: block;
  width: 80px;
  height: 80px;
  background: #212428;
  border-radius: 50%;
  -webkit-transition: all 200ms;
  transition: all 200ms;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

.video-play-button:hover:after {
  background-color: #212428;
}

.video-play-button img {
  position: relative;
  z-index: 3;
  max-width: 100%;
  width: auto;
  height: auto;
}

.video-play-button span {
  display: block;
  position: relative;
  z-index: 3;
  width: 0;
  height: 0;
  border-left: 27px solid #fff;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
}

@-webkit-keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 1;
  }
}

@keyframes pulse-border {
  0% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0)
      scale(1.5);
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.5);
    opacity: 1;
  }
}

.video-overlay {
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  opacity: 0;
  -webkit-transition: all ease 500ms;
  transition: all ease 500ms;
}

.video-overlay.open {
  position: fixed;
  z-index: 1000;
  opacity: 1;
}

.video-overlay-close {
  position: absolute;
  z-index: 1000;
  top: 15px;
  right: 20px;
  font-size: 36px;
  line-height: 1;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 200ms;
  transition: all 200ms;
}

.video-overlay-close:hover {
  color: #fa183d;
}

.video-overlay iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.75);
}

.box-wrapper .backto-top > div {
  bottom: 45px;
  right: 45px;
}

.social-share-style-1.single-page-icone {
  border-top: none;
  margin-top: 0 !important;
  padding-top: 0;
}

.social-share-style-1 .title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 500;
  color: var(--color-lightn);
  display: inline-block;
  margin-bottom: 20px;
}

.social-share-style-1 .social-share {
  margin: 0;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.social-share-style-1 .social-share li {
  margin: 0 25px 25px 0;
}

.social-share-style-1 .social-share li a {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--background-color-1);
  position: relative;
  z-index: 1;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

.social-share-style-1 .social-share li a.only-border {
  background: transparent;
  border: 1px solid #ff014f87;
}

.social-share-style-1 .social-share li a.only-border::before {
  display: none;
}

.social-share-style-1 .social-share li a.border-white {
  background: transparent;
  border: 1px solid #433888 !important;
}

.social-share-style-1 .social-share li a.border-white::before {
  display: none;
}

.social-share-style-1 .social-share li a.border-none {
  border-radius: 0;
}

.social-share-style-1 .social-share li a.border-none::before {
  border-radius: 0;
}

.social-share-style-1 .social-share li a.circle {
  border-radius: 50%;
}

.social-share-style-1 .social-share li a.circle::before {
  border-radius: 50%;
}

.social-share-style-1 .social-share li a::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.social-share-style-1 .social-share li a:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.social-share-style-1 .social-share li a:hover::before {
  opacity: 1;
}

.social-share-style-1 .social-share li a svg {
  color: var(--color-lightn);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  stroke-width: 2;
  position: relative;
  z-index: 2;
  width: 20px;
}

ul.slick-dots {
  width: auto;
  top: auto;
  z-index: 3;
  position: absolute;
  left: 50%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -40px;
}

ul.slick-dots li {
  width: auto;
  height: auto;
  padding: 10px;
  margin: 0;
}

ul.slick-dots li.slick-active button {
  background: var(--color-primary);
}

ul.slick-dots li button {
  font-size: 0;
  line-height: 0;
  width: 11px;
  height: 11px;
  padding: 0;
  cursor: pointer;
  border: 0;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--inner-shadow);
  box-shadow: var(--inner-shadow);
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.background-primary {
  background: #212428;
}

.mt-dec-50 {
  margin-top: -50px !important;
}

.rn-video-popup-area .thumbnail {
  padding: 30px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 15px;
}

.rn-video-popup-area .thumbnail img {
  border-radius: 8px;
  -o-object-fit: cover;
  object-fit: cover;
}

.color-body-white {
  color: var(--color-body-white) !important;
}

.w-100 {
  width: 100%;
}

.color-lightn {
  color: var(--color-lightn) !important;
}

.border-style {
  border: 1px solid #00000040 !important;
}

.padding-none {
  padding: 0 !important;
}

.flex-basis-style-1 {
  -ms-flex-preferred-size: 25% !important;
  flex-basis: 25% !important;
}

.tab-content-wrapper .tab-navigation-button {
  background: #212428;
  margin: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 20px 15px;
}

@media only screen and (max-width: 1199px) {
  .tab-content-wrapper .tab-navigation-button.tab-smlg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    margin-bottom: 30px;
    padding: 10px 15px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 575px) {
  .tab-content-wrapper .tab-navigation-button.tab-smlg {
    border-bottom: 0;
    border-radius: 0;
    padding: 0.5rem 0.9375rem;
    position: fixed;
    bottom: -30px;
    left: 0;
    width: 100%;
    z-index: 10;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.tab-content-wrapper .tab-content {
  padding: 45px 25px;
  background: #212428;
  border-radius: 10px;
}

.bg-image-area-fitness {
  height: 650px;
}

.sticky-top.rbt-sticky-top-adjust-three {
  padding: 0 !important;
  margin: 0 !important;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.animated.hinge {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}

.animated.bounceIn,
.animated.bounceOut {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

.animated.flipOutX,
.animated.flipOutY {
  -webkit-animation-duration: 0.75s;
  animation-duration: 0.75s;
}

@-webkit-keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

@keyframes bounce {
  from,
  20%,
  53%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  40%,
  43% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -30px, 0);
    transform: translate3d(0, -30px, 0);
  }

  70% {
    -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    -webkit-transform: translate3d(0, -15px, 0);
    transform: translate3d(0, -15px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -4px, 0);
    transform: translate3d(0, -4px, 0);
  }
}

.bounce {
  -webkit-animation-name: bounce;
  animation-name: bounce;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
}

@-webkit-keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

@keyframes flash {
  from,
  50%,
  to {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

.flash {
  -webkit-animation-name: flash;
  animation-name: flash;
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.pulse {
  -webkit-animation-name: pulse;
  animation-name: pulse;
}

@-webkit-keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes rubberBand {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }

  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }

  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.rubberBand {
  -webkit-animation-name: rubberBand;
  animation-name: rubberBand;
}

@-webkit-keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

@keyframes shake {
  from,
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  -webkit-animation-name: shake;
  animation-name: shake;
}

@-webkit-keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

@keyframes swing {
  20% {
    -webkit-transform: rotate3d(0, 0, 1, 15deg);
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    -webkit-transform: rotate3d(0, 0, 1, -10deg);
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, -5deg);
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.swing {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-animation-name: swing;
  animation-name: swing;
}

@-webkit-keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes tada {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.tada {
  -webkit-animation-name: tada;
  animation-name: tada;
}

@-webkit-keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes wobble {
  from {
    -webkit-transform: none;
    transform: none;
  }

  15% {
    -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.wobble {
  -webkit-animation-name: wobble;
  animation-name: wobble;
}

@-webkit-keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: none;
    transform: none;
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

@keyframes jello {
  from,
  11.1%,
  to {
    -webkit-transform: none;
    transform: none;
  }

  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg);
  }

  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg);
  }

  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg);
  }

  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg);
  }

  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg);
  }

  77.7% {
    -webkit-transform: skewX(0.390625deg) skewY(0.390625deg);
    transform: skewX(0.390625deg) skewY(0.390625deg);
  }

  88.8% {
    -webkit-transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);
  }
}

.jello {
  -webkit-animation-name: jello;
  animation-name: jello;
  -webkit-transform-origin: center;
  transform-origin: center;
}

@-webkit-keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(1.03, 1.03, 1.03);
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    -webkit-transform: scale3d(0.97, 0.97, 0.97);
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

.bounceIn {
  -webkit-animation-name: bounceIn;
  animation-name: bounceIn;
}

@-webkit-keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}

@-webkit-keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInLeft {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    -webkit-transform: translate3d(-3000px, 0, 0);
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(25px, 0, 0);
    transform: translate3d(25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(5px, 0, 0);
    transform: translate3d(5px, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}

@-webkit-keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInRight {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(3000px, 0, 0);
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(-25px, 0, 0);
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }

  90% {
    -webkit-transform: translate3d(-5px, 0, 0);
    transform: translate3d(-5px, 0, 0);
  }

  to {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInRight {
  -webkit-animation-name: bounceInRight;
  animation-name: bounceInRight;
}

@-webkit-keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 3000px, 0);
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  75% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  90% {
    -webkit-transform: translate3d(0, -5px, 0);
    transform: translate3d(0, -5px, 0);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.bounceInUp {
  -webkit-animation-name: bounceInUp;
  animation-name: bounceInUp;
}

@-webkit-keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

@keyframes bounceOut {
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }

  50%,
  55% {
    opacity: 1;
    -webkit-transform: scale3d(1.1, 1.1, 1.1);
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.bounceOut {
  -webkit-animation-name: bounceOut;
  animation-name: bounceOut;
}

@-webkit-keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes bounceOutDown {
  20% {
    -webkit-transform: translate3d(0, 10px, 0);
    transform: translate3d(0, 10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.bounceOutDown {
  -webkit-animation-name: bounceOutDown;
  animation-name: bounceOutDown;
}

@-webkit-keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(20px, 0, 0);
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.bounceOutLeft {
  -webkit-animation-name: bounceOutLeft;
  animation-name: bounceOutLeft;
}

@-webkit-keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.bounceOutRight {
  -webkit-animation-name: bounceOutRight;
  animation-name: bounceOutRight;
}

@-webkit-keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes bounceOutUp {
  20% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }

  40%,
  45% {
    opacity: 1;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.bounceOutUp {
  -webkit-animation-name: bounceOutUp;
  animation-name: bounceOutUp;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

@-webkit-keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}

@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

@-webkit-keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }
}

.fadeOutDownBig {
  -webkit-animation-name: fadeOutDownBig;
  animation-name: fadeOutDownBig;
}

@-webkit-keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.fadeOutLeft {
  -webkit-animation-name: fadeOutLeft;
  animation-name: fadeOutLeft;
}

@-webkit-keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
    transform: translate3d(-2000px, 0, 0);
  }
}

.fadeOutLeftBig {
  -webkit-animation-name: fadeOutLeftBig;
  animation-name: fadeOutLeftBig;
}

@-webkit-keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.fadeOutRight {
  -webkit-animation-name: fadeOutRight;
  animation-name: fadeOutRight;
}

@-webkit-keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
    transform: translate3d(2000px, 0, 0);
  }
}

.fadeOutRightBig {
  -webkit-animation-name: fadeOutRightBig;
  animation-name: fadeOutRightBig;
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}

@-webkit-keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -2000px, 0);
    transform: translate3d(0, -2000px, 0);
  }
}

.fadeOutUpBig {
  -webkit-animation-name: fadeOutUpBig;
  animation-name: fadeOutUpBig;
}

@-webkit-keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

@keyframes flip {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  40% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -190deg);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }

  50% {
    -webkit-transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    transform: perspective(400px) translate3d(0, 0, 150px)
      rotate3d(0, 1, 0, -170deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  80% {
    -webkit-transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
}

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  -webkit-animation-name: flip;
  animation-name: flip;
}

@-webkit-keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInX {
  from {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInX;
  animation-name: flipInX;
}

@-webkit-keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@keyframes flipInY {
  from {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }

  40% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }

  60% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg);
  }

  to {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipInY;
  animation-name: flipInY;
}

@-webkit-keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutX {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }
}

.flipOutX {
  -webkit-animation-name: flipOutX;
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@-webkit-keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

@keyframes flipOutY {
  from {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }

  30% {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1;
  }

  to {
    -webkit-transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0;
  }
}

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  -webkit-animation-name: flipOutY;
  animation-name: flipOutY;
}

@-webkit-keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }

  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes lightSpeedIn {
  from {
    -webkit-transform: translate3d(100%, 0, 0) skewX(-30deg);
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0;
  }

  60% {
    -webkit-transform: skewX(20deg);
    transform: skewX(20deg);
    opacity: 1;
  }

  80% {
    -webkit-transform: skewX(-5deg);
    transform: skewX(-5deg);
    opacity: 1;
  }

  to {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  from {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

@keyframes lightSpeedOut {
  from {
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(100%, 0, 0) skewX(30deg);
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0;
  }
}

.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

@-webkit-keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateIn {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, -200deg);
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.rotateIn {
  -webkit-animation-name: rotateIn;
  animation-name: rotateIn;
}

@-webkit-keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.rotateInDownLeft {
  -webkit-animation-name: rotateInDownLeft;
  animation-name: rotateInDownLeft;
}

@-webkit-keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.rotateInDownRight {
  -webkit-animation-name: rotateInDownRight;
  animation-name: rotateInDownRight;
}

@-webkit-keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpLeft {
  -webkit-animation-name: rotateInUpLeft;
  animation-name: rotateInUpLeft;
}

@-webkit-keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@-webkit-keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

@keyframes rotateOut {
  from {
    -webkit-transform-origin: center;
    transform-origin: center;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: center;
    transform-origin: center;
    -webkit-transform: rotate3d(0, 0, 1, 200deg);
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0;
  }
}

.rotateOut {
  -webkit-animation-name: rotateOut;
  animation-name: rotateOut;
}

@-webkit-keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, 45deg);
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0;
  }
}

.rotateOutDownLeft {
  -webkit-animation-name: rotateOutDownLeft;
  animation-name: rotateOutDownLeft;
}

@-webkit-keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

@keyframes rotateOutDownRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutDownRight {
  -webkit-animation-name: rotateOutDownRight;
  animation-name: rotateOutDownRight;
}

@-webkit-keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpLeft {
  from {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom;
    -webkit-transform: rotate3d(0, 0, 1, -45deg);
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0;
  }
}

.rotateOutUpLeft {
  -webkit-animation-name: rotateOutUpLeft;
  animation-name: rotateOutUpLeft;
}

@-webkit-keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}

@keyframes rotateOutUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    opacity: 1;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0;
  }
}

.rotateOutUpRight {
  -webkit-animation-name: rotateOutUpRight;
  animation-name: rotateOutUpRight;
}

@-webkit-keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

@keyframes hinge {
  0% {
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  20%,
  60% {
    -webkit-transform: rotate3d(0, 0, 1, 80deg);
    transform: rotate3d(0, 0, 1, 80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
  }

  40%,
  80% {
    -webkit-transform: rotate3d(0, 0, 1, 60deg);
    transform: rotate3d(0, 0, 1, 60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    opacity: 1;
  }

  to {
    -webkit-transform: translate3d(0, 700px, 0);
    transform: translate3d(0, 700px, 0);
    opacity: 0;
  }
}

.hinge {
  -webkit-animation-name: hinge;
  animation-name: hinge;
}

@-webkit-keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes rollIn {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.rollIn {
  -webkit-animation-name: rollIn;
  animation-name: rollIn;
}

@-webkit-keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg);
  }
}

.rollOut {
  -webkit-animation-name: rollOut;
  animation-name: rollOut;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}

@-webkit-keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInDown {
  -webkit-animation-name: zoomInDown;
  animation-name: zoomInDown;
}

@-webkit-keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInLeft {
  -webkit-animation-name: zoomInLeft;
  animation-name: zoomInLeft;
}

@-webkit-keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInRight {
  -webkit-animation-name: zoomInRight;
  animation-name: zoomInRight;
}

@-webkit-keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomInUp {
  -webkit-animation-name: zoomInUp;
  animation-name: zoomInUp;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
  animation-name: zoomOut;
}

@-webkit-keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutDown {
  -webkit-animation-name: zoomOutDown;
  animation-name: zoomOutDown;
}

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

.zoomOutLeft {
  -webkit-animation-name: zoomOutLeft;
  animation-name: zoomOutLeft;
}

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
  }
}

.zoomOutRight {
  -webkit-animation-name: zoomOutRight;
  animation-name: zoomOutRight;
}

@-webkit-keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  to {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom;
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
}

.zoomOutUp {
  -webkit-animation-name: zoomOutUp;
  animation-name: zoomOutUp;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@-webkit-keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@-webkit-keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

@-webkit-keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@-webkit-keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@-webkit-keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
}

.cd-intro {
  margin: 4em auto;
}

@media only screen and (min-width: 768px) {
  .cd-intro {
    margin: 5em auto;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-intro {
    margin: 6em auto;
  }
}

.cd-headline {
  font-size: 3rem;
  line-height: 1.2;
}

@media only screen and (min-width: 768px) {
  .cd-headline {
    font-size: 4.4rem;
    font-weight: 300;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-headline {
    font-size: 48px;
  }
}

@media only screen and (max-width: 768px) {
  .cd-headline {
    font-size: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .cd-headline {
    font-size: 26px;
  }
}

.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
}

.cd-words-wrapper b.is-visible {
  position: relative;
}

.no-js .cd-words-wrapper b {
  opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

.cd-headline.clip span {
  display: inline-block;
  padding: 0;
}

.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: middle;
}

.cd-headline.clip .cd-words-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 2px;
  height: 80%;
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.cd-headline.clip b {
  opacity: 0;
}

.cd-headline.clip b.is-visible {
  opacity: 1;
}

body.template-color-1 {
  background-color: #212428;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: transparent;
  position: relative;
}

.nav-pills .nav-link {
  margin: 0.5rem 1rem;
  padding: 0;
}

.header-wrapper {
  height: 122px;
  padding: 0 64px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .header-wrapper {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-wrapper {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-wrapper {
    padding: 0 30px;
  }
}

@media only screen and (max-width: 767px) {
  .header-wrapper {
    padding: 0 20px;
    height: 80px;
  }
}

@media only screen and (max-width: 575px) {
  .header-wrapper {
    padding: 0 3px;
  }
}

@media only screen and (max-width: 479px) {
  .header-wrapper {
    padding: 0;
  }
}

.header-wrapper .header-left {
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-wrapper .header-center {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-wrapper .header-right {
  margin-left: 30px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: -18px;
}

@media only screen and (max-width: 575px) {
  .header-wrapper .header-right a.rn-btn {
    font-size: 14px;
    padding: 17px 19px;
    line-height: 21px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .header-wrapper .header-right a.rn-btn {
    font-size: 13px;
    padding: 17px 19px;
    line-height: 21px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-wrapper .header-right a.rn-btn {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-wrapper .header-right a.rn-btn {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-wrapper .header-right a.rn-btn {
    display: none;
  }
}

.header-wrapper .header-right i {
  z-index: 800;
  color: var(--color-primary);
  font-size: 36px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  top: 2px;
}

@media only screen and (max-width: 1199px) {
  #sideNav {
    width: 250px;
    height: 100vh;
    position: fixed;
    right: -250px;
    top: 0;
    z-index: 999;
    -webkit-transition: 0.7s;
    transition: 0.7s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) and (max-width: 1199px) {
  #sideNav {
    display: inline-block;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 768px) and (max-width: 991px) {
  #sideNav {
    display: inline-block;
  }
}

@media only screen and (max-width: 1199px) and (max-width: 767px) {
  #sideNav {
    display: inline-block;
  }
}

@media only screen and (max-width: 1199px) and (max-width: 575px) {
  #sideNav {
    display: inline-block;
  }
}

@media only screen and (max-width: 1199px) and (max-width: 479px) {
  #sideNav {
    display: inline-block;
  }
}

@media only screen and (max-width: 1199px) {
  #sideNav ul li {
    list-style: none;
    margin: 50px 20px;
    display: block;
  }

  #sideNav ul li a {
    text-decoration: none;
    color: var(--color-white);
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
  }
}

.home-sticky .rn-header.header--fixed.sticky {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  position: fixed;
  -webkit-backdrop-filter: none;
  backdrop-filter: none;
  top: 0;
}

.home-sticky .sticky .header-wrapper {
  height: 90px;
  border-bottom: none;
}

.home-sticky .header-wrapper .header-right a.rn-btn {
  display: none;
}

.home-sticky .header-wrapper .header-right i.feather-menu {
  display: block;
  margin-top: -10px;
  cursor: pointer;
}

.home-sticky .rn-header .header-wrapper.menu-open .close-menu {
  opacity: 1;
  z-index: 99999;
  visibility: visible;
  color: var(--color-primary);
  font-size: 36px;
  margin-bottom: 15px;
  position: inherit;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  cursor: pointer;
}

.home-sticky .rn-header .header-wrapper.menu-open #sideNav {
  right: 0 !important;
}

.home-sticky #sideNav {
  width: 250px;
  height: 100vh;
  position: fixed;
  background: #191b1e;
  -webkit-box-shadow: none;
  box-shadow: none;
  right: -250px;
  top: 0;
  z-index: 999;
  -webkit-transition: 0.7s;
  transition: 0.7s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .home-sticky #sideNav {
    display: inline-block;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .home-sticky #sideNav {
    display: inline-block;
  }
}

@media only screen and (max-width: 767px) {
  .home-sticky #sideNav {
    display: inline-block;
  }
}

@media only screen and (max-width: 575px) {
  .home-sticky #sideNav {
    display: inline-block;
  }
}

@media only screen and (max-width: 479px) {
  .home-sticky #sideNav {
    display: inline-block;
  }
}

.home-sticky #sideNav ul {
  display: block;
  margin-top: 54px;
  margin-left: 30px;
}

.home-sticky #sideNav ul li {
  list-style: none;
  margin: 50px 20px;
  display: block;
}

.home-sticky #sideNav ul li a {
  text-decoration: none;
  color: var(--color-white);
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
}

.rn-header.header--sticky {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.rn-header.header--fixed {
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.rn-header.header--fixed.rn-d-none {
  display: none;
}

.rn-header.header--fixed.sticky {
  display: block;
}

.rn-header.header--fixed.sticky {
  background-color: #212428de;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  position: fixed;
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  top: 0;
}

.sticky .header-wrapper {
  height: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .sticky .header-wrapper {
    height: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sticky .header-wrapper {
    height: 70px;
  }
}

@media only screen and (max-width: 767px) {
  .sticky .header-wrapper {
    height: 70px;
    padding: 0;
  }
}

.sticky .header-wrapper .logo img {
  max-width: calc(100% - 10%);
}

.logo a {
  display: inline-block;
}

.logo a img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media only screen and (max-width: 767px) {
  .logo a img {
    max-height: 54px;
  }
}

@media only screen and (max-width: 1199px) {
  .rn-header .header-wrapper.menu-open #sideNav {
    right: 0 !important;
  }

  .rn-header .header-wrapper.menu-open .close-menu {
    opacity: 1;
    z-index: 99999;
    visibility: visible;
    color: var(--color-primary);
    font-size: 36px;
    margin-bottom: 15px;
    position: inherit;
    -webkit-transition: 0.7s;
    transition: 0.7s;
  }
}

.close-menu {
  opacity: 0;
  visibility: hidden;
}

.close-menu .closeTrigger i {
  position: relative;
}

.left-header-style {
  color: white;
  position: fixed;
  left: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  min-height: 100vh;
  top: 0px;
  width: 290px;
  z-index: 1;
  -webkit-transform: translateX(0px);
  transform: translateX(0px);
  border-right: 1px solid #121415;
  background: #212428;
  padding: 10px 40px 0px;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.left-header-style .logo-area a img {
  border-radius: 50%;
  border: 5px solid #32363b;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

.left-header-style #sideNavs .primary-menu {
  display: block;
  margin-top: 50px;
  width: 100%;
  border-radius: 10px;
  margin: 0;
  padding: 20px 0 0 10px;
}

.left-header-style #sideNavs .primary-menu li {
  margin: 0;
}

.left-header-style #sideNavs .primary-menu li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
  font-size: 18px;
  color: var(--color-light);
  border-radius: 10px;
  width: 100%;
  margin-left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin: 19px 0;
  font-size: 16px;
  transition: var(--transition);
}

.left-header-style #sideNavs .primary-menu li a:hover {
  color: var(--color-primary);
}

.left-header-style #sideNavs .primary-menu li a.active {
  color: var(--color-primary);
}

.left-header-style #sideNavs .primary-menu li a svg {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  color: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  top: -3px;
  position: relative;
}

.left-header-style .footer .social-share-style-1 {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 10px !important;
}

.with-particles {
  position: relative;
}

#particles-js {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}

.header-style-2 {
  position: absolute;
  top: 0;
  z-index: 99;
  width: auto;
  right: 0;
  padding: 15px 30px;
  left: 0;
}

@media only screen and (max-width: 479px) {
  .header-style-2 {
    -webkit-box-shadow: var(--shadow-white-3);
    box-shadow: var(--shadow-white-3);
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-style-2 {
    -webkit-box-shadow: var(--shadow-white-3);
    box-shadow: var(--shadow-white-3);
  }
}

.header-style-2 .header-right .hamberger-menu i {
  font-size: 32px;
  color: var(--color-primary);
}

.mainmenu-nav .primary-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -24px;
  padding: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .mainmenu-nav .primary-menu {
    display: block;
    margin-top: 95px;
    margin-left: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .mainmenu-nav .primary-menu {
    display: block;
    margin-top: 95px;
    margin-left: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .mainmenu-nav .primary-menu {
    display: block;
    margin-top: 95px;
    margin-left: 30px;
  }
}

.mainmenu-nav .primary-menu li {
  margin: 10px 14px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .mainmenu-nav .primary-menu li {
    margin: 10px 5px;
  }
}

.mainmenu-nav .primary-menu li:hover a {
  opacity: 1 !important;
  color: var(--color-white);
}

.mainmenu-nav .primary-menu li a {
  color: var(--color-lightn);
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 13px;
  font-weight: var(--s-regular);
  text-transform: uppercase;
  font-family: var(--font-secondary);
}

.mainmenu-nav .primary-menu li a.active {
  opacity: 1 !important;
  color: var(--color-white);
}

.mainmenu-nav .primary-menu:hover li a {
  opacity: 0.5;
}

.header--sticky.sticky .mainmenu-nav .primary-menu li a {
  opacity: 0.5;
}

.popup-mobile-menu {
  z-index: 9999;
  position: fixed;
  content: "";
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
}

.popup-mobile-menu .inner {
  padding: 25px 40px;
  width: 375px;
  z-index: 999;
  position: absolute;
  background: #191b1e;
  height: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);
  opacity: 0;
  left: -150px;
  -webkit-transition: all 0.5s ease-out;
  transition: all 0.5s ease-out;
}

.sr-hd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@media only screen and (max-width: 479px) {
  .popup-mobile-menu .inner {
    width: 310px;
    padding: 10px;
  }
}

.popup-mobile-menu .inner .menu-top {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 20px;
}

.popup-mobile-menu .inner .menu-top .menu-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.popup-mobile-menu .inner .menu-top .menu-header .close-button .close {
  opacity: 1;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}

@media only screen and (max-width: 479px) {
  .popup-mobile-menu .inner .menu-top .menu-header .close-button .close {
    right: 9px;
  }
}

.popup-mobile-menu .inner .menu-top .menu-header .close-button .close::after {
  position: absolute;
  content: "";
  padding: 22px;
  background: var(--background-color-1);
  left: 50%;
  top: 50%;
  border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
}

@media only screen and (max-width: 479px) {
  .popup-mobile-menu .inner .menu-top .menu-header .close-button .close::after {
    padding: 20px;
  }
}

.popup-mobile-menu .inner .menu-top .menu-header .close-button .close svg {
  color: var(--color-primary);
  width: 23px;
  height: auto;
}

@media only screen and (max-width: 479px) {
  .popup-mobile-menu .inner .menu-top .menu-header .close-button .close svg {
    width: 20px;
  }
}

.popup-mobile-menu .inner .menu-top p.discription {
  font-size: 16px;
  line-height: 32px;
  margin-top: 20px;
  color: var(--color-lighten);
  padding-right: 7%;
}

.popup-mobile-menu.menu-open {
  visibility: visible;
  opacity: 1;
}

.popup-mobile-menu.menu-open .inner {
  opacity: 1;
  left: 0;
  overflow-y: auto;
}

.popup-mobile-menu.menu-open .inner .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 0 30px;
}

.popup-mobile-menu.menu-open .inner .content .primary-menu {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.popup-mobile-menu.menu-open .inner .content .primary-menu .nav-item {
  display: block;
  margin: 8px 0;
}

.popup-mobile-menu.menu-open .inner .content .primary-menu .nav-item a {
  color: var(--color-lightn);
  position: relative;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 14px;
  font-weight: var(--s-regular);
  text-transform: uppercase;
  font-family: var(--font-secondary);
  margin: 0;
  display: inline;
  font-weight: 500;
}

.social-share-style-1 {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 10px !important;
}

.social-share-style-1 .social-share li {
  margin: 0 15px 15px 0;
}

.social-share-style-1 .social-share {
  margin-right: -15px;
}

.social-share-style-1 .social-share li a {
  width: 53px;
  height: 53px;
  line-height: 53px;
}

.popup-mobile-menu .social-share-style-1 .social-share li a {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rn-right-demo {
  width: 30px;
  position: fixed;
  z-index: 99;
  right: 0px;
  top: calc(50% - 55px);
}

.rn-right-demo .demo-button {
  border-radius: 6px 0 0 6px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  background: var(--gradient-red-hover);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  z-index: 2;
  font-size: 14px;
  border: 0 none;
  width: 100%;
  min-width: 100%;
  padding: 10px 0px 8px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
}

.rn-right-demo .demo-button::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.rn-right-demo .demo-button .text {
  -webkit-writing-mode: vertical-rl;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: upright;
  padding-left: 5px;
  padding-right: 5px;
}

.demo-modal-area {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border: 0px;
  background-color: #212428;
  position: fixed;
  z-index: 999;
  overflow: auto;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateX(100px);
  transform: translateX(100px);
}

.demo-modal-area .demo-close-btn {
  border: 0 none;
  position: fixed;
  right: 50px;
  top: 50px;
  width: 40px;
  display: inline-block;
  height: 40px;
  font-size: 22px;
  border-radius: 6px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: 2;
}

.demo-modal-area .demo-close-btn::after {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.demo-modal-area .demo-close-btn:hover {
  color: var(--color-primary);
}

.demo-modal-area .wrapper .rn-modal-inner {
  margin: 70px auto;
  border-radius: 6px;
  padding: 50px;
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  position: relative;
  max-width: 1170px;
  width: 90%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .demo-modal-area .wrapper .rn-modal-inner {
    padding: 40px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .demo-modal-area .wrapper .rn-modal-inner {
    padding: 40px 30px;
  }
}

.demo-modal-area .wrapper .rn-modal-inner::after {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.demo-modal-area .wrapper .rn-modal-inner .demo-top .title {
  margin-bottom: 12px;
  color: #000;
}

.demo-modal-area .wrapper .rn-modal-inner .demo-top .subtitle {
  font-size: 16px;
  margin-bottom: 40px;
}

.sr-social {
  width: 100%;
}

.sr-social a:after,
a:before {
  content: "";
  position: absolute;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sr-social ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  padding: 0;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.sr-social ul li {
  list-style: none;
  margin: 40px 40px;
}

.sr-social ul li:nth-child(1) a {
  background-color: #007bb5;
}

.sr-social ul li:nth-child(1) a:before {
  background-color: #006a9c;
}

.sr-social ul li:nth-child(1) a:after {
  background-color: #0089c9;
}

.sr-social ul li:nth-child(2) a {
  background-color: #1877f2;
}

.sr-social ul li:nth-child(2) a:before {
  background-color: #0d6ae4;
}

.sr-social ul li:nth-child(2) a:after {
  background-color: #2b82f3;
}

.sr-social ul li:nth-child(3) a {
  background-color: #c32aa3;
}

.sr-social ul li:nth-child(3) a:before {
  background-color: #ae2591;
}

.sr-social ul li:nth-child(3) a:after {
  background-color: #d22fb0;
}

.sr-social ul li:nth-child(4) a {
  background-color: #1da1f2;
}

.sr-social ul li:nth-child(4) a:before {
  background-color: #0d95e8;
}

.sr-social ul li:nth-child(4) a:after {
  background-color: #30a9f3;
}

.sr-social ul li:nth-child(5) a {
  background-color: #171515;
}

.sr-social ul li:nth-child(5) a:before {
  background-color: #171515;
}

.sr-social ul li:nth-child(5) a:after {
  background-color: #171515;
}

.sr-social ul li:hover .fa {
  color: #fff;
}

.sr-social ul li a {
  position: relative;
  display: block;
  width: 70px;
  height: 70px;
  text-align: center;
  -webkit-box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
  box-shadow: -20px 20px 10px rgba(0, 0, 0, 0.5);
  -webkit-transform: perspective(1000px) rotate(-30deg) skew(25deg)
    translate(0, 0);
  transform: perspective(1000px) rotate(-30deg) skew(25deg) translate(0, 0);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.sr-social ul li a:before {
  top: 10px;
  left: -20px;
  height: 100%;
  width: 20px;
  -webkit-transform: rotate(0deg) skewY(-45deg);
  transform: rotate(0deg) skewY(-45deg);
}

.sr-social ul li a:after {
  bottom: -20px;
  left: -10px;
  height: 20px;
  width: 100%;
  -webkit-transform: rotate(0deg) skewX(-45deg);
  transform: rotate(0deg) skewX(-45deg);
}

.sr-social ul li a:hover {
  -webkit-transform: perspective(1000px) rotate(-30deg) skew(25deg)
    translate(20px, -20px);
  transform: perspective(1000px) rotate(-30deg) skew(25deg)
    translate(20px, -20px);
  -webkit-box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
  box-shadow: -50px 50px 50px rgba(0, 0, 0, 0.5);
}

.sr-social ul li a svg {
  font-size: 40px;
  color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  margin: 20px;
}

.skill-main .slide {
  padding-bottom: 0;
}

.white-version .sr-content .sect-main {
  background: #fff;
  padding: 50px 20px;
  border-radius: 10px;
  box-shadow: var(--shadow-white-3);
}

.sr-content .sect-main {
  padding: 50px 20px;
  border-radius: 10px;
  box-shadow: var(--shadow-1);
}

@media only screen and (max-width: 575px) {
  .sr-content .sect-main {
    padding: 20px 0;
  }

  .table .thead-dark th {
    font-size: 12px;
  }

  .mobile-hide {
    display: none;
  }
}
.white-version a.btn.card-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}
.white-version .btn {
  color: #000;
}
.btn {
  color: #fff;
}
a.btn.card-btn {
  font-size: 20px;
  box-shadow: var(--shadow-1);
  margin-left: 10px;
  border-radius: 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

a.btn.card-btn:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
  background: var(--color-primary);
  color: #fff;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.rn-portfolio .inner .content .tags span {
  font-size: 10px;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
}

.rn-portfolio .inner .content .category-list .html {
  background: #ff5722 !important;
}

.rn-portfolio .inner .content .category-list .css {
  background: #2257ea !important;
}

.rn-portfolio .inner .content .category-list .javascript {
  background: #dab92d !important;
}

.rn-portfolio .inner .content .category-list .react {
  background: #53e1fc !important;
}

.rn-portfolio .inner .content .category-list .vue {
  background: #41b883 !important;
}

.rn-portfolio .inner .content .category-list .typescript {
  background: #2d79c7 !important;
}

.rn-portfolio .inner .content .category-list .angular {
  background: #a70a05 !important;
}

.rn-portfolio .inner .content .category-list .php {
  background: #474a8a !important;
}
.rn-portfolio .inner .content .category-list .python {
  background-image: linear-gradient(#3673a5, #ffd342) !important;
}

.rn-portfolio .inner .content .category-list .btns {
  background: #df0f6d;
}

.tags a {
  font-size: 10px;
  padding: 5px;
  border-radius: 5px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none !important;
}

.tags a.html {
  background: #ff5722;
}

.tags a.css {
  background: #2257ea;
}

.tags a.javascript {
  background: #dab92d;
}

.tags a.react {
  background: #53e1fc;
  color: #000;
}

.tags a.vue {
  background: #41b883;
}

.tags a.typescript {
  background: #2d79c7;
}

.tags a.angular {
  background: #a70a05;
}

.tooltip-inner {
  max-width: 350px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
  opacity: 99;
}

.white-version .rn-portfolio:hover {
  background: linear-gradient(228deg, #ffffff, #e2e8ec) !important;
}

.rn-portfolio .inner .thumbnail {
  cursor: pointer;
}

.demo-modal-area.open {
  opacity: 1;
  visibility: visible;
  -webkit-transform: none;
  transform: none;
}

.single-demo {
  position: relative;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  padding: 0;
  border-radius: 6px;
  margin-bottom: 50px;
  padding-bottom: 20px;
}

.single-demo.coming-soon {
  pointer-events: none;
}

.single-demo .inner.badge-1 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.single-demo .inner.badge-1::after {
  position: absolute;
  content: "Hot";
  padding: 3px 10px;
  border-radius: 5px;
  z-index: 2;
  font-size: 14px;
  top: 15px;
  right: 15px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#6a67ce),
    to(#fc636b)
  ) !important;
  background: linear-gradient(to right, #6a67ce, #fc636b) !important;
  color: var(--color-white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single-demo .inner.badge-2 {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.single-demo .inner.badge-2::after {
  position: absolute;
  content: "New";
  padding: 3px 10px;
  border-radius: 5px;
  z-index: 2;
  font-size: 14px;
  top: 15px;
  right: 15px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#6a67ce),
    to(#fc636b)
  ) !important;
  background: linear-gradient(to right, #6a67ce, #fc636b) !important;
  color: var(--color-white);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single-demo .inner .thumbnail {
  border-radius: 6px 6px 0 0;
}

.single-demo .inner .thumbnail a {
  position: relative;
  display: block;
  z-index: 2;
}

.single-demo .inner .thumbnail a::after {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 8px 8px 0 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0;
}

.single-demo .inner .thumbnail a .overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 2;
  margin-top: 10px;
  display: inline-block;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.single-demo .inner .thumbnail a .overlay-content .overlay-text {
  display: inline-block;
  padding: 8px 12px;
  background: var(--color-primary);
  font-size: 14px;
  border-radius: 6px;
  opacity: 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  color: var(--color-white);
}

.single-demo .inner .thumbnail img {
  border-radius: 6px 6px 0 0;
}

.single-demo .inner .title {
  margin-bottom: 0;
  padding-top: 16px;
  padding-bottom: 0;
  font-size: 18px;
  text-align: center;
  letter-spacing: 0.5px;
  border-top: 1px solid #1a1d23;
  margin-top: 0;
  font-weight: 600;
}

.single-demo .inner .title a {
  color: var(--color-heading);
  display: block;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single-demo .inner .title a:hover {
  color: var(--color-primary);
}

.single-demo:hover .inner .thumbnail a::after {
  opacity: 1;
}

.single-demo:hover .inner .thumbnail a .overlay-content {
  margin-top: 0;
}

.single-demo:hover .inner .thumbnail a .overlay-content .overlay-text {
  opacity: 1;
}

.single-demo:hover .badge-1:after {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  right: 7px;
  top: 7px;
}

.single-demo:hover .badge-2:after {
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  right: 0;
  top: 7px;
}

.single-demo::after {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.popuptab-area {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: -20px -10px;
  margin-bottom: 50px;
  border: 0 none;
}

.popuptab-area li {
  margin: 20px 10px;
}

.popuptab-area li a.nav-link {
  border-radius: 6px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  position: relative;
  z-index: 2;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  padding: 15px 35px;
  list-style: none;
  text-decoration: none;
  color: var(--color-white);
  outline: none !important;
}

.popuptab-area li a.nav-link.active {
  border-color: transparent !important;
  color: var(--color-primary);
  background-color: transparent;
}

.popuptab-area li a.nav-link::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.popuptab-area li a.nav-link:hover,
.popuptab-area li a.nav-link.active {
  outline: none;
  border-color: transparent !important;
}

.popuptab-area li a.nav-link:hover::before,
.popuptab-area li a.nav-link.active::before {
  opacity: 1;
}

.demo-modal-area.white-version .wrapper .rn-modal-inner {
  background: var(--gradient-box-w);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.demo-modal-area.white-version {
  background-color: #212428;
}

.demo-modal-area.white-version .single-demo {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.demo-modal-area.white-version .single-demo .inner .title a {
  color: var(--color-heading-wv);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.demo-modal-area .popuptab-area li a.nav-link {
  position: relative;
  z-index: 2;
}

.demo-modal-area.white-version .popuptab-area li a.nav-link {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  color: var(--color-heading-wv);
}

.demo-modal-area.white-version .popuptab-area li a.nav-link.active {
  color: var(--color-primary);
}

.demo-modal-area.white-version .popuptab-area li a.nav-link::before {
  display: none;
}

.demo-modal-area.white-version .popuptab-area li a.nav-link.active::before {
  opacity: 1;
}

.demo-modal-area.white-version .single-demo .inner .title {
  border-top: 1px solid transparent;
}

.demo-modal-area.white-version .wrapper .rn-modal-inner .demo-top .title {
  color: var(--color-heading-wv);
}

.demo-modal-area.white-version .wrapper .rn-modal-inner .demo-top .subtitle {
  color: var(--color-body-white);
}

a.rn-btn,
button.rn-btn {
  display: inline-block;
  padding: 15px 35px;
  list-style: none;
  text-decoration: none;
  color: var(--color-primary);
}

a.rn-btn,
button.rn-btn {
  border-radius: 6px;
  background: linear-gradient(145deg, #1e2024, #23272b);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  z-index: 2;
  font-size: 14px;
  font-weight: 500;
  border: 0 none;
}

a.rn-btn::before,
button.rn-btn::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

a.rn-btn:hover,
button.rn-btn:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

a.rn-btn:hover::before,
button.rn-btn:hover::before {
  opacity: 1;
}

a.rn-btn.no-shadow,
button.rn-btn.no-shadow {
  -webkit-box-shadow: none;
  box-shadow: none;
}

a.rn-btn.btn-theme,
button.rn-btn.btn-theme {
  background: var(--color-primary) !important;
  color: var(--color-white) !important;
}

a.rn-btn.btn-theme::before,
button.rn-btn.btn-theme::before {
  display: none;
}

a.rn-btn.btn-transparent,
button.rn-btn.btn-transparent {
  background: transparent;
}

a.rn-btn.btn-transparent::before,
button.rn-btn.btn-transparent::before {
  display: none;
}

a.rn-btn.thumbs-icon svg,
button.rn-btn.thumbs-icon svg {
  margin-top: -7px;
}

.button-style .main-page-wrapper {
  position: relative;
  top: 122px;
}

.breadcrumb-style-one {
  background: #1f2125;
  padding: 40px 0;
}

.breadcrumb-style-one .breadcrumb-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.breadcrumb-style-one .breadcrumb-inner .title {
  font-size: 30px;
  color: var(--color-lightn);
  margin: 0;
}

.breadcrumb-style-one .breadcrumb-inner .page-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
}

.breadcrumb-style-one .breadcrumb-inner .page-list li {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  list-style: none;
}

.breadcrumb-style-one .breadcrumb-inner .page-list li a {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.breadcrumb-style-one .breadcrumb-inner .page-list li.separator {
  position: relative;
  padding: 0 20px;
}

.breadcrumb-style-one .breadcrumb-inner .page-list li.separator::after {
  position: absolute;
  background-color: var(--color-primary);
  width: 5px;
  content: "";
  height: 5px;
  border-radius: 100%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: 50%;
  opacity: 0.5;
}

.button-group {
  margin: -15px;
}

.button-group a.rn-btn {
  margin: 15px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.button-group a.rn-btn.btn-small {
  padding: 13px 30px;
}

.button-group a.rn-btn.btn-large {
  padding: 18px 40px;
}

.button-group a.rn-btn.border-button {
  background: transparent;
  border: 1px solid var(--color-primary);
}

.button-group a.rn-btn.border-button::before {
  display: none;
}

.button-style-border-radius .button-group a.rn-btn {
  border-radius: 0;
}

.button-style-border-radius .button-group a.rn-btn::before {
  border-radius: 0;
}

.button-style-underline .button-group:nth-child(1) {
  margin-bottom: 30px;
}

.rn-btn-ulderline {
  position: relative;
  margin: 30px !important;
}

.rn-btn-ulderline::after {
  content: "";
  position: absolute;
  width: 40%;
  background: var(--color-primary);
  height: 3px;
  bottom: -3px;
  left: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.rn-btn-ulderline:hover::after {
  width: 100%;
}

.white-version .rn-btn-ulderline {
  color: #1e2024;
}

.accordion-style-one .section-title-accordion h3 {
  font-size: 40px;
  color: var(--color-lightn);
}

.accordion-style-one .accordion .accordion-item {
  margin-bottom: 30px;
}

.accordion-style-one .accordion .accordion-item .accordion-header {
  font-size: 32px;
  color: var(--color-lightn);
  margin-bottom: 0;
}

.accordion-style-one .accordion .accordion-item .accordion-header a {
  font-size: 22px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.accordion-style-one
  .accordion
  .accordion-item
  .accordion-header
  a.accordion-button {
  padding: 34px 30px;
  position: relative;
  width: 100%;
  z-index: 1;
  display: block;
}

.accordion-style-one
  .accordion
  .accordion-item
  .accordion-header
  a.accordion-button
  .inner
  p {
  font-size: 16px;
}

.accordion-style-one
  .accordion
  .accordion-item
  .accordion-header
  a.accordion-button::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.35;
  background: linear-gradient(145deg, #1e2024, #23272b);
  z-index: -1;
  border-radius: 8px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

.accordion-style-one
  .accordion
  .accordion-item
  .accordion-header
  a.accordion-button::before {
  position: absolute;
  content: "";
  top: 10px;
  font-family: "feather" !important;
  font-weight: 200;
  right: 35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.accordion-style-one
  .accordion
  .accordion-item
  .accordion-header
  a.accordion-button[aria-expanded="true"]::before {
  content: "";
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.accordion-style-one
  .accordion
  .accordion-item
  .accordion-header
  a.accordion-button[aria-expanded="true"]::after {
  border-radius: 8px 8px 0px 0px;
}

.accordion-style-one
  .accordion
  .accordion-item
  .accordion-header
  a.accordion-button.two::before {
  content: "";
}

.accordion-style-one
  .accordion
  .accordion-item
  .accordion-header
  a.accordion-button.two[aria-expanded="true"]::before {
  content: "";
}

.accordion-style-one
  .accordion
  .accordion-item
  .accordion-header
  a.accordion-button.two[aria-expanded="true"]::after {
  border-radius: 8px 8px 0px 0px;
}

.accordion-style-one .accordion .accordion-item .accordion-body .inner {
  padding: 20px;
  background: #1e2024;
  border-radius: 0 0px 8px 8px;
}

.accordion-style-one .accordion .accordion-item .accordion-body .inner p {
  font-size: 16px;
}

.rn-typography-area .wp-block-quote {
  padding: 40px 137px;
  position: relative;
  background: transparent;
  color: var(--color-heading);
  z-index: 1;
  border: 0;
  text-align: center;
  margin: 0;
  padding-top: 50px;
}

.rn-typography-area .wp-block-quote::before {
  position: absolute;
  width: 100px;
  height: 87px;
  left: 50%;
  top: -37px;
  content: "";
  opacity: 1;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-size: 100px;
  opacity: 0.1;
}

p.has-drop-cap:not(:focus):first-letter {
  float: left;
  font-size: 104px;
  line-height: 0.78;
  margin: 4px 15px 0 0;
  text-transform: uppercase;
  font-family: georgia, serif;
  font-style: normal;
  font-weight: 400;
  color: var(--color-heading);
  -webkit-font-smoothing: antialiased;
  color: var(--color-lightn);
}

.table-area tbody td {
  border: 1px solid var(--color-tertiary);
  padding: 7px 10px;
  color: var(--color-dark-alt);
  color: var(--color-body);
}

.table-area tbody td strong {
  color: var(--color-lightn);
  font-weight: 500;
}

.table-area .section-title h2.title {
  font-size: 40px;
}

.hr-area .section-title h2.title {
  font-size: 24px;
  margin-top: -10px;
  margin-bottom: 5px;
}

.hr-area hr {
  background-size: 4px 4px;
  height: 1px;
  margin: 0 0 24px;
  border: 0;
  border-top: 1px solid #121214;
}

.hr-dots-area .section-title .title {
  font-size: 40px;
  margin-bottom: 10px;
}

.hr-dots-area .wp-block-separator.is-style-dots::before {
  content: "···";
  padding-left: 21px;
  color: #121214;
  font-size: 21px;
  font-weight: 700;
  letter-spacing: 21px;
  font-family: georgia, serif;
}

.hr-dots-area .wp-block-separator.is-style-dots {
  background: 0 0;
  border: none;
  text-align: center;
  max-width: none;
  line-height: 1;
  height: auto;
}

.column-galary .section-title .title {
  font-size: 40px;
}

.blocks-gallery-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0 -10px;
  list-style: none;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blocks-gallery-grid .blocks-gallery-item {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  padding: 0 10px;
}

.blocks-gallery-grid .blocks-gallery-item figure {
  overflow: hidden;
  border-radius: 8px;
}

.blocks-gallery-grid .blocks-gallery-item figure img {
  border-radius: 8px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.blocks-gallery-grid .blocks-gallery-item figure img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.Three-Column-gallery .section-title .title {
  font-size: 40px;
}

.Three-Column-gallery .columns-3 .blocks-gallery-item {
  padding: 0 10px;
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
}

.large .title {
  font-size: 40px !important;
}

.alignfull {
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  max-width: 100vw;
  width: 100vw;
}

.alignwide {
  margin-left: -105px;
  margin-right: -105px;
  max-width: 1400px;
  width: auto;
}

.alignwide .radius-4 {
  border-radius: 8px;
}

figcaption {
  text-align: center;
  margin-top: 10px;
  font-size: 13px;
  color: var(--color-dark-alt);
  letter-spacing: 0.5px;
}

.section-separator {
  position: relative;
}

.section-separator::after {
  position: absolute;
  max-width: 1270px;
  height: 1px;
  background: #121415;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

@media only screen and (max-width: 479px) {
  .section-flex {
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    display: block !important;
  }
}

.section-title span.subtitle {
  color: #ff014f;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 14px;
  font-family: var(--font-secondary);
  font-weight: 500;
}

.white-version .section-title h2.title {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--color-lightn);
  margin-top: 15px;
  margin-bottom: 0;
}

.section-title h2.title {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2;

  margin-top: 15px;
  margin-bottom: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section-title h2.title {
    font-size: 38px;
    line-height: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-title h2.title {
    font-size: 40px;
    margin-top: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title h2.title {
    font-size: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .section-title h2.title {
    text-align: center;
    margin: 0;
    font-size: 34px;
  }
}

.skill-share-inner-one .skill-share {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -10px -12px;
  margin-top: -20px;
}

.skill-share-inner-one .skill-share li {
  margin: 10px 12px;
  line-height: 55px;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 6px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

.skill-share-inner-one .skill-share li img {
  border: none !important;
  max-height: 23px !important;
  top: 0 !important;
}

.slide {
  padding-bottom: 100px;
  position: relative;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide {
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide {
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 575px) {
  .slide {
    padding-bottom: 100px;
  }
}

.slide .content {
  margin-top: 150px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .content {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide .content {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .slide .content {
    margin-top: 40px;
  }
}

.slide .content .inner {
  margin-bottom: 142px;
}

@media only screen and (max-width: 1199px) {
  .slide .content .inner {
    margin-bottom: 40px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide .content .inner {
    margin-bottom: 40px;
  }
}

.slide .content .inner span.subtitle {
  font-size: 14px;
  font-family: var(--font-secondary);
  color: var(--color-lightn);
  letter-spacing: 3px;
  text-transform: uppercase;
  font-weight: 500;
  display: block;
  margin-bottom: 20px;
}

@media only screen and (max-width: 479px) {
  .slide .content .inner span.subtitle {
    letter-spacing: 1px;
  }
}

.slide .content .inner .title {
  color: #ffffff;
  font-weight: 700;
  font-size: 60px;
  line-height: 75px;
  margin-bottom: 22px;
  font-family: var(--font-secondary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .content .inner .title {
    font-size: 50px;
    line-height: 1.2;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide .content .inner .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 767px) {
  .slide .content .inner .title {
    font-size: 50px;
    line-height: 60px;
  }
}

@media only screen and (max-width: 479px) {
  .slide .content .inner .title {
    font-size: 32px;
    line-height: 40px;
  }
}

.slide .content .inner .title span {
  color: #ff014f;
}

.slide .content .inner .title .header-caption span {
  color: var(--color-white);
  font-weight: 600;
}

.slide .content .inner .title .span {
  font-size: 48px;
  color: var(--color-white);
}

@media only screen and (max-width: 479px) {
  .slide .content .inner .title .span {
    font-size: 24px;
    line-height: 33px;
  }
}

.slide .content .inner p.discs {
  font-size: 18px;
  width: 50%;
}

@media only screen and (max-width: 767px) {
  .slide .content .inner p.discs {
    width: 80%;
  }
}

@media only screen and (max-width: 575px) {
  .slide .content .inner p.discs {
    width: 80%;
  }
}

@media only screen and (max-width: 479px) {
  .slide .content .inner p.discs {
    width: 90%;
  }
}

.slide .content .inner .description {
  font-size: 16px;
  line-height: 30px;
  color: var(--color-lightn);
  padding-right: 16%;
  opacity: 0.9;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .content .inner .description {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide .content .inner .description {
    margin-bottom: 40px;
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .slide .content .inner .description {
    margin-bottom: 40px;
    padding-right: 0;
  }
}

.slide .social-share-inner-left span.title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
  color: var(--color-lightn);
  display: inline-block;
  margin-bottom: 20px;
}

.slide .social-share-inner-left .social-share {
  margin: -10px -12px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .social-share-inner-left .social-share {
    margin: -10px -10px;
  }
}

@media only screen and (max-width: 767px) {
  .slide .social-share-inner-left .social-share {
    margin: -10px -10px;
  }
}

.slide .social-share-inner-left .social-share li {
  margin: 10px 12px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide .social-share-inner-left .social-share li {
    margin: 10px 10px;
  }
}

@media only screen and (max-width: 767px) {
  .slide .social-share-inner-left .social-share li {
    margin: 10px 10px;
  }
}

.slide .social-share-inner-left .social-share li a {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  position: relative;
}

.slide .social-share-inner-left .social-share li a::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.slide .social-share-inner-left .social-share li a svg {
  color: var(--color-lightn);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  stroke-width: 2;
  position: relative;
  z-index: 2;
  width: 20px;
}

.slide .social-share-inner-left .social-share li a:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.slide .social-share-inner-left .social-share li a:hover svg {
  color: var(--color-white);
}

.slide .social-share-inner-left .social-share li a:hover::before {
  opacity: 1;
}

.slide .skill-share-inner span.title {
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 300;
  color: var(--color-lightn);
  display: inline-block;
  margin-bottom: 20px;
}

.slide .skill-share-inner .skill-share {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -10px -12px;
}

.slide .skill-share-inner .skill-share li {
  margin: 10px 12px;
  line-height: 55px;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 6px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

.slide .skill-share-inner .skill-share li img {
  max-height: 23px;
  width: auto;
}

.slide .thumbnail {
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 767px) {
  .slide .thumbnail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.slide .thumbnail::before {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: calc(100% - 130px);
  content: "";
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 6px;
}

.slide .thumbnail .inner {
  text-align: center;
}

.slide .thumbnail .inner img {
  border: 0;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
}

.slide .thumbnail.box-gradient::before {
  width: 100%;
  height: 100%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#6a67ce),
    to(#fc636b)
  ) !important;
  background: linear-gradient(to right, #6a67ce, #fc636b) !important;
}

.slide .thumbnail.box-gradient .inner {
  padding: 15px;
}

.slide .thumbnail.box-gradient .inner img {
  border-radius: 6px;
}

.slide.slider-style-1 {
  padding-top: 150px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slider-style-1 {
    padding-top: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slider-style-1 {
    padding-top: 100px;
  }
}

.slide.slider-style-1.with-square-box {
  padding-top: 200px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slider-style-1.with-square-box {
    padding-top: 150px;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slider-style-1.with-square-box {
    padding-top: 120px;
  }
}

.slide.slider-style-1 .content {
  margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slider-style-1.box-layout {
    padding-top: 157px;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slider-style-1.box-layout {
    padding-top: 157px;
  }
}

@media only screen and (max-width: 575px) {
  .slide.slider-style-1.box-layout {
    padding-top: 100px;
  }
}

.slide.slider-style-2 {
  padding-top: 180px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide.slider-style-2 {
    padding-top: 170px;
    padding-bottom: 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slider-style-2 {
    padding-top: 150px;
    padding-bottom: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slider-style-2 {
    padding-top: 120px;
    padding-bottom: 80px;
  }
}

.slide.slider-style-2 .content {
  margin-top: 0;
}

.slide.slider-style-2 .content .inner {
  margin-bottom: 120px;
}

@media only screen and (max-width: 767px) {
  .slide.slider-style-2 .content .inner .description {
    padding-right: 0;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slider-style-3 {
    padding: 100px 0 60px 0;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slider-style-3 {
    padding-bottom: 60px;
  }
}

.slide.slider-style-3 .slider-wrapper {
  padding-top: 75px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 1199px) {
  .slide.slider-style-3 .slider-wrapper {
    padding-top: 126px;
    padding-bottom: 45px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slider-style-3 .slider-wrapper {
    padding-top: 126px;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 479px) {
  .slide.slider-style-3 .slider-wrapper {
    padding-top: 100px;
    padding-bottom: 16px;
  }
}

.slider-style-3 .cd-headline .cd-words-wrapper b {
  font-weight: 300;
  font-size: 30px;
  color: var(--color-primary);
}

.slider-style-3 .cd-headline span {
  font-size: 30px;
}

.slide.slider-style-3 .slider-wrapper .slider-info {
  top: 250px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.slide.slider-style-3 .slider-wrapper .slider-info:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.slide.slider-style-3 .slider-wrapper .slider-info .user-info-top {
  padding: 35px;
  background: var(--background-color-2);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 10px 10px 0 0;
}

.sr-user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 479px) {
  .slide.slider-style-3 .slider-wrapper .slider-info .user-info-top {
    padding: 25px;
    padding-top: 41px;
  }

  .slider-style-3 .cd-headline .cd-words-wrapper b {
    font-size: 25px;
  }
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-header {
  margin-bottom: 30px;
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-header
  .user {
  position: relative;
  z-index: 1;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  -ms-flex-item-align: center;
  align-self: center;
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-header
  .user
  svg {
  width: 22px;
  height: auto;
  position: relative;
  color: #ffffff;
  top: 37%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-header
  .user::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: var(--color-primary);
  opacity: 0.7;
  z-index: -1;
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-header
  .user::after {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 60px;
  height: 60px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  background: var(--color-primary);
  opacity: 0.2;
  z-index: -2;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-header
  .title {
  color: #ffffff;
  font-weight: 700;
  font-size: 50px;
  line-height: 62px;
  margin-bottom: 11px;
  font-family: var(--font-secondary);
}

@media only screen and (max-width: 1199px) {
  .slide.slider-style-3
    .slider-wrapper
    .slider-info
    .user-info-top
    .user-info-header
    .title {
    font-size: 48px;
  }
}

@media only screen and (max-width: 479px) {
  .slide.slider-style-3
    .slider-wrapper
    .slider-info
    .user-info-top
    .user-info-header
    .title {
    font-size: 32px;
    line-height: 40px;
  }

  .rn-right-demo {
    display: none;
  }

  .white-version .backto-top > div {
    position: fixed;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
    z-index: 999;
    width: 40px;
    height: 40px;
    line-height: 35px;
  }

  a.rn-btn {
    padding: 15px 15px;
  }
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-header
  .title
  span {
  color: var(--color-primary);
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-header
  p.disc {
  font-size: 16px;
  color: var(--color-lightn);
  line-height: 24px;
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-footer
  .info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 13px;
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-footer
  .info
  span
  a {
  color: var(--color-primary);
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-footer
  .info
  svg {
  color: var(--color-primary);
  margin-right: 10px;
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-footer
  .info
  span {
  color: var(--color-lightn);
  font-size: 16px;
}

.slide.slider-style-3 .slider-wrapper .slider-info .user-info-bottom {
  border-radius: 0 0 10px 10px;
  margin-top: 30px;
  padding: 35px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

@media only screen and (max-width: 479px) {
  .slide.slider-style-3 .slider-wrapper .slider-info .user-info-bottom {
    padding: 20px;
  }
}

.slide.slider-style-3 .slider-wrapper .slider-info .user-info-bottom > span {
  color: var(--color-lightn);
  font-size: 16px;
}

.slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-bottom
  .button-wrapper {
  margin-top: 30px;
}

@media only screen and (max-width: 767px) {
  .slide.slider-style-3
    .slider-wrapper
    .slider-info
    .user-info-bottom
    .button-wrapper {
    margin-top: 3px;
  }
}

@media only screen and (max-width: 479px) {
  .slide.slider-style-3
    .slider-wrapper
    .slider-info
    .user-info-bottom
    .button-wrapper {
    display: inline-block;
  }
}

@media only screen and (max-width: 479px) {
  .slide.slider-style-3
    .slider-wrapper
    .slider-info
    .user-info-bottom
    .button-wrapper
    .rn-btn {
    margin-top: 20px;
  }
}

.slide.slider-style-3 .slider-wrapper .background-image-area {
  padding: 34px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  position: relative;
  right: 0;
  border-radius: 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  height: 100%;
}

@media only screen and (max-width: 479px) {
  .slide.slider-style-3 .slider-wrapper .background-image-area {
    padding: 10px;
  }
}

.slide.slider-style-3 .slider-wrapper .background-image-area:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.slide.slider-style-3 .slider-wrapper .background-image-area .thumbnail-image {
  height: 100%;
}

@media only screen and (max-width: 479px) {
  .havatar .image {
    background: linear-gradient(
      60deg,
      #f79533,
      #f37055,
      #ef4e7b,
      #a166ab,
      #5073b8,
      #1098ad,
      #07b39b,
      #6fba82
    );
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    -webkit-animation: morph 8s ease-in-out infinite 1s;
    animation: morph 8s ease-in-out infinite 1s;
    background-blend-mode: multiply;
    -webkit-box-shadow: inset 0 0 0 9px rgba(255, 255, 255, 0.3);
    box-shadow: inset 0 0 0 9px rgba(255, 255, 255, 0.3);
  }

  .gradient-borders {
    width: 100%;
    height: 100%;
    position: absolute !important;
    z-index: -1;
    top: 0;
    left: 0;
    -webkit-animation: morph 8s ease-in-out infinite 1s;
    animation: morph 8s ease-in-out infinite 1s;
    background: rgba(255, 153, 0, 1);
    background: -webkit-gradient(
      left top,
      right top,
      color-stop(0%, rgba(255, 153, 0, 1)),
      color-stop(50%, rgba(241, 111, 92, 1)),
      color-stop(100%, rgba(192, 52, 247, 1))
    );
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(255, 153, 0, 1)),
      color-stop(50%, rgba(241, 111, 92, 1)),
      to(rgba(192, 52, 247, 1))
    );
    background: linear-gradient(
      to right,
      rgba(255, 153, 0, 1) 0%,
      rgba(241, 111, 92, 1) 50%,
      rgba(192, 52, 247, 1) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff9900', endColorstr='#c034f7', GradientType=1);
  }

  .srpd {
    padding: 10px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
}

.srpd {
  padding: 10px;
}

@-webkit-keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40%/50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70%/60% 30% 70% 40%;
  }
}

.slide.slider-style-3
  .slider-wrapper
  .background-image-area
  .thumbnail-image
  img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  -o-object-fit: cover;
  object-fit: cover;
}

.slide.slider-style-4 {
  height: 100vh;
  padding-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide.slider-style-4 {
    height: auto;
    padding: 150px 0;
  }
}

@media only screen and (max-width: 767px) {
  .slide.slider-style-4 {
    height: auto;
    padding: 150px 0;
  }
}

.slide.slider-style-4 .content {
  margin-top: 0;
}

.slide.slider-style-4 .content .social-share-inner-left ul li a {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.underline-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.underline-area hr {
  height: 3px;
  width: 200px;
  background: var(--color-primary);
  margin: 0;
  margin-right: 30px;
  left: 8px;
}

@media only screen and (max-width: 575px) {
  .underline-area hr {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  .sticky-home-wrapper {
    margin-top: -40px;
  }
}

@media only screen and (max-width: 575px) {
  .sticky-home-wrapper {
    margin-top: -30px;
  }
}

.banner-details-wrapper-sticky .thumbnail::before {
  display: none;
}

.banner-details-wrapper-sticky .thumbnail img {
  width: 180px;
  height: 180px;
  border-radius: 50%;
  border: 5px solid #32363b;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  -o-object-fit: cover;
  object-fit: cover;
}

.banner-details-wrapper-sticky .banner-title-area .title {
  color: #ffffff;
  font-weight: 700;
  font-size: 60px;
  line-height: 62px;
  margin-bottom: 22px;
  font-family: var(--font-secondary);
}

@media only screen and (max-width: 767px) {
  .banner-details-wrapper-sticky .banner-title-area .title {
    text-align: center;
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-details-wrapper-sticky .banner-title-area .title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 479px) {
  .banner-details-wrapper-sticky .banner-title-area .title {
    font-size: 32px !important;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner-details-wrapper-sticky .banner-title-area .title {
    font-size: 48px;
  }
}

.banner-details-wrapper-sticky .banner-title-area .title .span {
  font-size: 48px;
  color: var(--color-white);
}

@media only screen and (max-width: 767px) {
  .banner-details-wrapper-sticky .banner-title-area .title .span {
    font-size: 36px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-details-wrapper-sticky .banner-title-area .title .span {
    font-size: 32px;
  }
}

@media only screen and (max-width: 479px) {
  .banner-details-wrapper-sticky .banner-title-area .title .span {
    font-size: 26px !important;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1199px) {
  .banner-details-wrapper-sticky .banner-title-area .title .span {
    font-size: 36px;
  }
}

.banner-details-wrapper-sticky .banner-title-area .title span {
  color: #ff014f;
}

@media only screen and (max-width: 575px) {
  .banner-details-wrapper-sticky .banner-title-area .title span {
    font-size: 32px;
  }
}

@media only screen and (max-width: 479px) {
  .banner-details-wrapper-sticky .banner-title-area .title span {
    font-size: 30px;
    line-height: 40px;
  }
}

.banner-details-wrapper-sticky .banner-title-area p {
  font-size: 18px;
  color: var(--color-body);
}

@media only screen and (max-width: 767px) {
  .banner-details-wrapper-sticky .banner-title-area p {
    text-align: center;
    padding: 0 14px;
  }
}

.thumbnail.masking .inner {
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

.thumbnail.masking::before {
  display: none;
}

.thumbnail.style-2::before {
  height: 100%;
}

.thumbnail.style-2 img {
  border-radius: 6px !important;
}

.thumbnail.style-2 .inner {
  padding: 15px;
}

.front-end-developer div#carouselExampleControls {
  padding: 15px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 8px;
}

.front-end-developer #carouselExampleControls .carousel-control-prev svg {
  width: 34px;
  height: auto;
  position: relative;
  left: 5px;
}

.front-end-developer #carouselExampleControls .carousel-control-next svg {
  width: 34px;
  height: auto;
  position: relative;
  right: 5px;
}

.home-vedio body {
  background: #222;
}

.home-vedio .video-play-button {
  top: 48%;
}

.home-vedio .video-play-button:before {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #21242899;
}

.home-vedio .video-play-button::after {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #212428bf;
}

.sticky-top-slider a.rn-btn,
.sticky-top-slider button.rn-btn {
  font-size: 18px;
}

.attachment {
  background-attachment: fixed;
}

.page-wrapper-two {
  margin-left: 290px;
}

@media only screen and (max-width: 1199px) {
  .page-wrapper-two {
    margin-left: 0;
  }
}

.slider-style-5 {
  margin: auto;
  max-width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
  padding-top: 105px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .slider-style-5 {
    padding-top: 100px;
  }
}

.slider-style-5.height--100 {
  height: 730px;
}

.slider-style-5.position-content {
  -webkit-transform: translateY(150px);
  transform: translateY(150px);
}

.slider-style-5 .thumbnail {
  margin-bottom: 18px;
  padding: 10px;
  display: inline-block;
}

.slider-style-5 .thumbnail img {
  border-radius: 50%;
  height: auto;
  width: 320px;
  border: 5px solid #32363b;
}

.slider-style-5 .thumbnail.gradient-border img {
  border: none;
  background-size: 400% 400%;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
}

.slider-style-5 .thumbnail.gradient-animation img {
  -webkit-animation: gradient 3s ease infinite;
  animation: gradient 3s ease infinite;
}

@-webkit-keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.slider-style-5 h1 {
  font-size: 48px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .slider-style-5 h1 {
    font-size: 38px;
    margin-bottom: 10px;
  }
}

.slider-style-5 .cd-headline span {
  font-size: 30px;
  color: var(--color-lightn);
}

.slider-style-5 .cd-headline .cd-words-wrapper b {
  font-weight: 300;
  font-size: 30px;
  color: var(--color-primary);
}

.slider-style-5 .social-share-style-1 {
  border-top: none;
  padding: 0;
  text-align: center;
  margin-top: 40px !important;
}

.slider-style-5 .social-share-style-1 ul {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.slider-style-5 .button-area a {
  margin-top: 25px;
}

.slider-style-6.height--100 {
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider-style-6.height--100 {
    height: auto;
    padding: 150px 0;
  }
}

@media only screen and (max-width: 767px) {
  .slider-style-6.height--100 {
    height: auto;
    padding: 150px 0;
  }
}

.slider-style-6 h1 {
  font-size: 48px;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .slider-style-6 h1 {
    font-size: 38px;
    margin-bottom: 10px;
  }
}

.slider-style-6 .cd-headline span {
  font-size: 30px;
  color: var(--color-lightn);
}

.slider-style-6 .cd-headline .cd-words-wrapper b {
  font-weight: 300;
  font-size: 30px;
  color: var(--color-primary);
}

.slider-style-6 .button-area {
  margin-top: 30px;
}

#box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 400px;
  height: 200px;
  color: white;
  font-family: "Raleway";
  font-size: 2.5rem;
}

.gradient-border {
  --borderWidth: 3px;
  background: transparent;
  position: relative;
  border-radius: 50%;
}

.gradient-border:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    60deg,
    #f79533,
    #f37055,
    #ef4e7b,
    #a166ab,
    #5073b8,
    #1098ad,
    #07b39b,
    #6fba82
  );
  border-radius: 50%;
  z-index: -1;
  -webkit-animation: animatedgradient 2s ease alternate infinite;
  animation: animatedgradient 2s ease alternate infinite;
  background-size: 300% 300%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.rn-header-image-area {
  height: 350px;
}

.rn-content-wrapper {
  position: relative;
  z-index: 5;
}

.rn-content-wrapper .padding-tb {
  padding: 20px;
  background: #212428;
  border-radius: 10px;
  box-shadow: var(--shadow-1);
}

.rn-content-wrapper .padding-tb.m_dec-top {
  margin-top: -100px;
  margin-left: 0;
  margin-right: 0;
}

.rn-content-wrapper .header-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .rn-content-wrapper .header-left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }
}

.rn-content-wrapper .header-left .header-thumbnail img {
  border-radius: 15px;
  max-height: 200px;
  border: 5px solid #1d1e24;
  position: relative;
  top: -80px;
}

.rn-content-wrapper .header-left .header-info-content {
  margin-left: 30px;
}

@media only screen and (max-width: 1199px) {
  .rn-content-wrapper .header-left .header-info-content {
    margin-left: 0;
    margin-top: -46px;
  }
}

.rn-content-wrapper .header-left .header-info-content .social-share-style-1 {
  margin-top: 15px !important;
}

@media only screen and (max-width: 1199px) {
  .rn-content-wrapper .header-left .header-info-content .social-share-style-1 {
    margin-left: 0;
    margin-top: -50px;
    text-align: center;
  }
}

@media only screen and (max-width: 1199px) {
  .rn-content-wrapper
    .header-left
    .header-info-content
    .social-share-style-1.border-none {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 5px !important;
  }
}

.rn-content-wrapper .header-left .header-info-content h4 {
  font-size: 32px;
  margin-bottom: 10px;
  transition: all 0.3s;
}

.rn-content-wrapper .header-left .header-info-content h4 a {
  transition: all 0.3s;
}

.rn-content-wrapper .header-left .header-info-content h4 a svg {
  font-size: 25px;
  display: none;
  transition: all 0.3s;
}

.rn-content-wrapper .header-left .header-info-content h4:hover a svg {
  display: inline-block;
  transition: all 0.3s;
}

@media only screen and (max-width: 575px) {
  .rn-content-wrapper .header-left .header-info-content h4 {
    font-size: 24px;
  }
}

.rn-content-wrapper .header-left .header-info-content .status-info {
  font-size: 18px;
  font-weight: 500;
}

.rn-content-wrapper .header-left::before {
  content: "";
  background-color: #151517;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 1px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-content-wrapper .header-left::before {
    content: "";
    background-color: #151517;
    position: absolute;
    top: 106%;
    right: 0;
    bottom: -13px;
    height: 1px;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .rn-content-wrapper .header-left::before {
    content: "";
    background-color: #151517;
    position: absolute;
    top: 106%;
    right: 0;
    bottom: -13px;
    height: 1px;
    width: 100%;
  }
}

.rn-content-wrapper .header-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-content-wrapper .header-right {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-content-wrapper .header-right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 30px;
  }
}

.rn-header-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
}

.fd-lorem {
  font-size: 16px !important;
  letter-spacing: 4px;
  color: var(--color-lighten) !important;
  margin-top: 35px !important;
}

.fs--100 {
  font-size: 100px !important;
}

.fs--100 {
  font-size: 120px !important;
}

.lh--100 {
  line-height: 120px !important;
}

.fashion-designer-inner.model h1.title {
  font-size: 65px !important;
  line-height: 90px !important;
  color: var(--color-white) !important;
  letter-spacing: 0.5px !important;
}

@media only screen and (max-width: 767px) {
  .fashion-designer-inner.model h1.title {
    font-size: 40px !important;
    line-height: 50px !important;
  }
}

.fashion-designer-inner.model p.disc {
  margin-top: 0 !important;
  color: var(--color-white) !important;
}

@media only screen and (max-width: 767px) {
  .fashion-designer-inner.model p.disc {
    letter-spacing: 2px !important;
  }
}

.fashion-designer-inner h1.title {
  font-size: 120px !important;
  line-height: 120px !important;
  color: #b79685 !important;
}

@media only screen and (max-width: 767px) {
  .fashion-designer-inner h1.title {
    font-size: 70px !important;
    line-height: 75px !important;
  }
}

@media only screen and (max-width: 575px) {
  .fashion-designer-inner h1.title {
    font-size: 50px !important;
    line-height: 65px !important;
  }
}

@media only screen and (max-width: 767px) {
  .fashion-designer-inner p.disc {
    letter-spacing: 2px !important;
  }
}

.rn-header-content {
  list-style: none;
  padding-left: 30px;
}

@media only screen and (max-width: 767px) {
  .rn-header-content {
    padding-left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-header-content.two {
    padding-left: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-header-content.two {
    padding-left: 0;
  }
}

.rn-header-content li {
  font-size: 18px;
  color: var(--color-lightn);
  margin-bottom: 20px;
}

.rn-header-content li span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-body);
}

.rn-service {
  text-align: left;
  padding: 35px 50px 35px 50px;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  position: relative;
  border-radius: 10px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  position: relative;
  z-index: 1;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-service {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-service {
    padding: 30px 30px 30px 30px;
  }
}

@media only screen and (max-width: 479px) {
  .rn-service {
    padding: 20px;
  }
}

.rn-service a.over-link {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.rn-service .inner .icon {
  margin-bottom: 29px;
}

@media only screen and (max-width: 767px) {
  .rn-service .inner .icon {
    margin-bottom: 10px;
  }
}

.rn-service .inner .icon svg {
  width: 50px;
  height: auto;
  color: var(--color-primary);
  stroke-width: 1 !important;
}

.rn-service .inner .content .title {
  font-family: var(--color-primary);
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 20px;
  color: var(--color-lightn);
}

@media only screen and (max-width: 767px) {
  .rn-service .inner .content .title {
    margin-bottom: 15px;
  }
}

.rn-service .inner .content .title a {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.rn-service .inner .content p.description {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-lightn);
  margin-bottom: 22px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.rn-service .inner .content .read-more-button {
  height: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.rn-service .inner .content .read-more-button::after {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
  pointer-events: auto;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
}

.rn-service .inner .content .read-more-button i {
  color: var(--color-primary);
  font-size: 32px;
}

.rn-service::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.rn-service:hover .inner .content .title {
  color: var(--color-white);
}

.rn-service:hover .inner .content .description {
  color: var(--color-white);
}

.rn-service:hover .inner .content .read-more-button {
  height: auto;
  visibility: visible;
  opacity: 1;
}

.rn-service:hover::before {
  opacity: 1;
}

.rn-service .inner > * {
  -webkit-transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
  transition: 700ms cubic-bezier(0.17, 0.67, 0, 1.01);
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}

.rn-service:hover .inner > * {
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.modal-backdrop {
  background-color: transparent;
}

.modal.fade {
  -webkit-transition: opacity 0.1s linear;
  transition: opacity 0.1s linear;
}

body.modal-open {
  padding-right: 0 !important;
}

.box-wrapper.modal-open {
  padding-right: 25px !important;
}

.modal {
  z-index: 99999999;
  background: #212428;
  padding: 60px 15px !important;
}

.modal.modal-boxed-home {
  width: calc(100% - 50px) !important;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.modal .modal-news {
  max-width: 1000px !important;
}

.modal .modal-dialog {
  width: 100%;
  max-width: 1230px;
}

.modal .modal-dialog .modal-content {
  padding: 45px;
  border-radius: 10px;
  overflow: hidden;
  border: none;
  z-index: 1;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

@media only screen and (max-width: 767px) {
  .modal .modal-dialog .modal-content {
    padding: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .modal .modal-dialog .modal-content {
    padding: 40px 15px 25px 15px;
  }
}

.modal .modal-dialog .modal-content .modal-header {
  padding: 0;
  border: none;
}

.modal .modal-dialog .modal-content .modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
  display: inline-block;
  width: 0;
  right: 44px;
  position: absolute;
  top: 18px;
}

.modal .modal-dialog .modal-content .modal-header .close span {
  color: var(--color-white);
  position: relative;
  z-index: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.modal .modal-dialog .modal-content .modal-header .close span svg {
  width: 22px;
  height: auto;
}

.modal .modal-dialog .modal-content .modal-header .close span::after {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: var(--background-color-1);
  opacity: 1;
  z-index: -1;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

.modal .modal-dialog .modal-content .modal-header .close:hover span {
  color: var(--color-primary);
}

.modal .modal-dialog .modal-content .modal-body .text-content {
  padding-left: 2rem;
}

.modal .modal-dialog .modal-content .modal-body .text-content h3 {
  margin-bottom: 20px;
  font-size: 34px;
  font-weight: 700;
  color: var(--color-lightn);
  line-height: 1.3;
}

.modal .modal-dialog .modal-content .modal-body .text-content h3 span {
  display: block;
  font-size: 16px;
  line-height: 30px;
  color: var(--color-body);
  font-weight: 500;
  margin-bottom: 5px;
}

.modal .modal-dialog .modal-content .modal-body .text-content p {
  font-size: 18px;
  line-height: 30px;
  color: var(--color-body);
  font-weight: 400;
  margin-bottom: 0;
  width: 93%;
}

.modal .modal-dialog .modal-content .modal-body .text-content p:nth-child(1) {
  margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
  .modal .modal-dialog .modal-content .modal-body .text-content {
    margin-top: 30px;
    padding: 0;
  }
}

.modal .modal-dialog .modal-content .modal-body .text-content a svg {
  position: relative;
  margin-right: -13px;
  width: 19px;
}

.modal .featured-project-carousel .carousel-indicators {
  position: absolute;
  top: 83%;
}

.modal .featured-project-carousel .carousel-indicators li {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  opacity: 1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  background-color: var(--color-body);
}

.modal .featured-project-carousel .carousel-indicators li.active {
  background-color: var(--color-primary);
  opacity: 1;
}

.modal .carousel {
  position: relative;
}

.modal .carousel .carousel-inner {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-bottom: 0;
}

.modal .carousel .carousel-inner img {
  max-width: 100%;
  height: auto;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

ol.carousel-indicators {
  position: absolute !important;
  top: 0;
  z-index: 5;
}

div#featured-project-carousel {
  padding: 0;
}

.featured-project-carousel .carousel-control-prev-icon {
  width: 4rem;
  height: 4rem;
  display: inline-block;
}

.featured-project-carousel .carousel-control-next-icon {
  width: 4rem;
  height: 4rem;
  display: inline-block;
}

.modal-body .modal-feat-img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.8rem;
  border-radius: 10px;
  vertical-align: middle;
}

.modal-body .img-fluid {
  width: 100%;
  height: auto;
}

.modal-body .comment-inner .title {
  font-size: 32px;
  font-weight: 600;
  color: var(--color-lightn);
  margin-bottom: 16px;
}

.modal-body .comment-inner .rnform-group {
  margin-bottom: 20px;
}

.modal-body .comment-inner .rnform-group input {
  background-color: #191b1e;
  border-radius: 6px;
  height: 55px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 2px solid #191b1e;
  padding: 0 15px;
  font-size: 14px;
  font-family: var(--font-primary);
  color: var(--color-lightn);
  -webkit-box-shadow: var(--inner-shadow);
  box-shadow: var(--inner-shadow);
  letter-spacing: 1px;
}

.modal-body .comment-inner .rnform-group input:focus {
  color: var(--color-light);
  border-color: var(--color-primary);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.modal-body .comment-inner .rnform-group textarea {
  min-height: 205px;
  background-color: #191b1e;
  border-radius: 6px;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 2px solid #191b1e;
  padding: 15px 15px;
  font-size: 14px;
  line-height: 18px;
  font-family: var(--font-secondary);
  color: var(--color-lightn);
  -webkit-box-shadow: var(--inner-shadow);
  box-shadow: var(--inner-shadow);
  letter-spacing: 1px;
  resize: none;
}

.modal-body .comment-inner .rnform-group textarea:focus {
  color: var(--color-light);
  border-color: var(--color-primary);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.modal-body .news-details .date {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: var(--color-lightn);
  font-weight: 500;
  margin-bottom: 9px;
}

.modal-body .news-details .title {
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--color-lightn);
  margin-top: 20px;
  margin-bottom: 40px;
}

.modal-body .news-details p {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-body);
  font-weight: 400;
}

.modal-body .news-details h4 {
  font-size: 30px;
  color: var(--color-lightn);
}

@media only screen and (max-width: 479px) {
  .section-title.text-center span.subtitle {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 767px) {
  .section-title.text-center span.subtitle {
    margin-bottom: 10px;
  }
}

.section-title span.subtitle {
  color: var(--color-subtitle);
  letter-spacing: 1px;
  display: block;
  line-height: 14px;
}

@media only screen and (max-width: 575px) {
  .section-title span.subtitle {
    text-align: center;
  }
}

@media only screen and (max-width: 479px) {
  .section-title span.subtitle {
    line-height: 24px;
    letter-spacing: 1px;
    text-align: center;
  }
}

.rn-nav-list {
  border: 0 none;
  border-radius: 10px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-nav-list {
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .rn-nav-list {
    margin-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .rn-nav-list {
    margin-top: 0;
  }
}

.rn-nav-list .nav-item {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  text-align: center;
  margin-top: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-nav-list .nav-item {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 767px) {
  .rn-nav-list .nav-item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 575px) {
  .rn-nav-list .nav-item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

@media only screen and (max-width: 479px) {
  .rn-nav-list .nav-item {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

.rn-nav-list .nav-item .nav-link {
  color: #c4cfde;
  font-family: var(--font-primary);
  padding: 30px 10px;
  text-transform: capitalize;
  font-size: var(--font-size-b1);
  font-weight: 500;
  outline: none;
  border: 0 none;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  background: transparent;
  border-radius: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-nav-list .nav-item .nav-link {
    padding: 25px 7px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-nav-list .nav-item .nav-link {
    padding: 30px 6px;
    padding: 18px 6px;
    font-size: 13px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-nav-list .nav-item .nav-link {
    font-size: 15px;
  }
}

.rn-nav-list .nav-item .nav-link:hover,
.rn-nav-list .nav-item .nav-link.active {
  color: #ff014f;
  background-color: transparent;
  border-color: transparent;
  border: 0 none;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

.personal-experience-inner .content span.subtitle {
  color: var(--color-subtitle);
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 500;
}

.personal-experience-inner .content .maintitle {
  font-size: 36px;
  font-weight: 700;
  line-height: 62px;
  color: var(--color-lightn);
}

.content.pd-t {
  padding-top: 105px;
}

@media only screen and (max-width: 479px) {
  .content.pd-t {
    padding-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .content.pd-t {
    padding-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .content.pd-t {
    padding-top: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-experience-inner .content .maintitle {
    font-size: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-experience-inner .content .maintitle {
    font-size: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .personal-experience-inner .content .maintitle {
    font-size: 24px;
  }
}

.personal-experience-inner .experience-list {
  padding-left: 31px;
  border-left: 5px solid #17191c;
}

@media only screen and (max-width: 767px) {
  .personal-experience-inner .experience-list {
    padding-left: 27px;
  }
}

@media only screen and (max-width: 479px) {
  .personal-experience-inner .experience-list {
    padding-left: 14px;
  }
}

.personal-experience-inner .experience-list .resume-single-list {
  position: relative;
  z-index: 2;
  margin-top: 50px;
  padding: 45px 40px;
  border-radius: 6px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-experience-inner .experience-list .resume-single-list {
    padding: 30px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .personal-experience-inner .experience-list .resume-single-list {
    padding: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .personal-experience-inner .experience-list .resume-single-list {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .personal-experience-inner .experience-list .resume-single-list {
    padding: 20px;
  }
}

.personal-experience-inner .experience-list .resume-single-list::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.personal-experience-inner .experience-list .resume-single-list .inner::after {
  position: absolute;
  content: "";
  width: 20px;
  height: 20px;
  border: 5px solid #17191c;
  border-radius: 100%;
  right: 104%;
  top: 55px;
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
  background: #222529;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-experience-inner
    .experience-list
    .resume-single-list
    .inner::after {
    right: 106%;
  }
}

@media only screen and (max-width: 479px) {
  .personal-experience-inner
    .experience-list
    .resume-single-list
    .inner::after {
    right: 102%;
  }
}

.personal-experience-inner .experience-list .resume-single-list .inner::before {
  position: absolute;
  content: "";
  width: 28px;
  height: 5px;
  right: 100%;
  top: 63px;
  -webkit-transform: translateY(-7px);
  transform: translateY(-7px);
  background: #1b1c1d;
  z-index: 0;
}

@media only screen and (max-width: 479px) {
  .personal-experience-inner
    .experience-list
    .resume-single-list
    .inner::before {
    width: 15px;
  }
}

.personal-experience-inner
  .experience-list
  .resume-single-list
  .inner
  .heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #1a1d23;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .personal-experience-inner
    .experience-list
    .resume-single-list
    .inner
    .heading {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .personal-experience-inner
    .experience-list
    .resume-single-list
    .inner
    .heading {
    display: block;
  }
}

.personal-experience-inner
  .experience-list
  .resume-single-list
  .inner
  .heading
  .title {
  margin-bottom: 15px;
}

.personal-experience-inner
  .experience-list
  .resume-single-list
  .inner
  .heading
  .title
  h4 {
  color: var(--color-lightn);
  font-family: var(--font-primary);
  font-size: 24px;
  line-height: 39px;
  margin-bottom: 7px;
  font-weight: 500;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

@media only screen and (max-width: 479px) {
  .personal-experience-inner
    .experience-list
    .resume-single-list
    .inner
    .heading
    .title
    h4 {
    font-size: 21px;
    line-height: 34px;
  }
}

.personal-experience-inner
  .experience-list
  .resume-single-list
  .inner
  .heading
  .title
  span {
  color: #7c838e;
  display: block;
  font-size: 14px;
}

.personal-experience-inner
  .experience-list
  .resume-single-list
  .inner
  .heading
  .date-of-time {
  display: inline-block;
}

.personal-experience-inner
  .experience-list
  .resume-single-list
  .inner
  .heading
  .date-of-time
  span {
  color: var(--color-primary);
  display: block;
  font-size: 14px;
  padding: 0 15px;
  height: 36px;
  line-height: 36px;
  border-radius: 6px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-weight: 500;
}

.personal-experience-inner .experience-list .resume-single-list .inner p {
  font-size: 18px;
  line-height: 28px;
  color: var(--color-body);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.personal-experience-inner
  .experience-list
  .resume-single-list
  .inner.psudo-after-none::after {
  display: none;
}

.personal-experience-inner
  .experience-list
  .resume-single-list
  .inner.psudo-after-none::before {
  display: none;
}

.personal-experience-inner .experience-list .resume-single-list:hover {
  background: #212427;
}

.personal-experience-inner
  .experience-list
  .resume-single-list:hover
  .inner
  .heading
  .title
  h4 {
  color: var(--color-white);
}

.personal-experience-inner
  .experience-list
  .resume-single-list:hover
  .inner
  .heading
  .date-of-time
  span {
  background: #1e2025;
  -webkit-box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #222527;
  box-shadow: 10px 10px 19px #1c1e22, -10px -10px 19px #222527;
}

.personal-experience-inner
  .experience-list
  .resume-single-list:hover
  .inner
  .description {
  color: var(--color-lightn);
}

.personal-experience-inner
  .experience-list
  .resume-single-list:hover
  .inner::after {
  background: var(--color-primary);
}

.personal-experience-inner .experience-list .resume-single-list:hover::before {
  opacity: 1;
}

.experience-style-two {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 10px;
  margin: 40px auto 0;
  width: 90%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .experience-style-two {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .experience-style-two {
    display: block;
    padding: 20px;
  }
}

.experience-style-two .experience-image {
  max-width: 250px;
  height: auto;
  border-radius: 8px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .experience-style-two .experience-image {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .experience-style-two .experience-image {
    max-width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .experience-style-two .experience-image {
    max-width: 100%;
  }
}

.experience-style-two .experience-image img {
  border-radius: 8px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.experience-style-two .experience-image:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.experience-style-two .experience-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-preferred-size: 80%;
  flex-basis: 80%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .experience-style-two .experience-left {
    -ms-flex-preferred-size: 70%;
    flex-basis: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .experience-style-two .experience-left {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .experience-style-two .experience-left {
    display: block;
  }
}

.experience-style-two .experience-center {
  padding-left: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .experience-style-two .experience-center {
    padding-left: 20px;
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .experience-style-two .experience-center {
    padding-left: 0;
    padding-top: 25px;
  }
}

@media only screen and (max-width: 767px) {
  .experience-style-two .experience-center {
    padding-left: 0;
    padding-top: 25px;
  }
}

.experience-style-two .experience-center .date {
  padding: 2px 8px;
  border-radius: 20px;
  background-color: #545961;
  font-size: 14px;
  color: var(--color-lightn);
}

.experience-style-two .experience-center .experience-title {
  margin-top: 10px;
  font-size: 26px;
  color: var(--color-lightn);
  font-weight: 500;
}

@media only screen and (max-width: 575px) {
  .experience-style-two .experience-center .experience-title {
    font-size: 22px;
    margin-bottom: 10px;
  }
}

.experience-style-two .experience-center .subtitle {
  margin-top: 15px;
  font-size: 20px;
  color: var(--color-lightn);
  font-weight: 400;
  margin-bottom: 7px;
  line-height: 1.4;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .experience-style-two .experience-center .subtitle {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .experience-style-two .experience-center .subtitle {
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 6px;
    line-height: 1.5;
  }
}

.experience-style-two .experience-center .disc {
  font-size: 16px;
  color: var(--color-body);
}

.experience-style-two .experience-right {
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
  text-align: right;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .experience-style-two .experience-right {
    -ms-flex-preferred-size: 30%;
    flex-basis: 30%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .experience-style-two .experience-right {
    text-align: left;
  }
}

@media only screen and (max-width: 767px) {
  .experience-style-two .experience-right {
    text-align: left;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .experience-style-two .experience-footer {
    -ms-flex-preferred-size: 22%;
    flex-basis: 22%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .experience-style-two .experience-footer {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .experience-style-two .experience-footer {
    margin-top: 20px;
  }
}

.progress-charts {
  overflow: hidden;
}

.progress-charts + .progress-charts {
  margin-top: 30px;
}

.progress-charts h6.heading {
  margin-bottom: 15px;
  color: #c3cedd;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0.9;
  font-weight: 400;
}

.progress-charts h6.heading span.percent-label {
  color: #c3cedd;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0.9;
  font-weight: 400;
}

.progress-charts .progress {
  height: 13px;
  overflow: visible;
  font-size: 14px;
  border-radius: 10px;
  padding: 3px;
  background: #212428;
  -webkit-box-shadow: inset 8px 8px 16px #00000075,
    inset -8px -8px 16px #383e4575;
  box-shadow: inset 8px 8px 16px #00000075, inset -8px -8px 16px #383e4575;
}

.progress-charts .progress .progress-bar {
  overflow: visible;
  position: relative;
  border-radius: 14px;
  background: #f02981;
  background: linear-gradient(145deg, #f02981 0%, #c81901 100%);
}

.progress-charts .progress .progress-bar span.percent-label {
  position: absolute;
  right: -7px;
  top: -22px;
  font-weight: 400;
  color: #c3cedd;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  opacity: 0.9;
}

.rn-testimonial {
  padding: 30px 15px;
}

.testimonial {
  outline: none;
  padding-bottom: 25px;
}

@media only screen and (max-width: 575px) {
  .testimonial {
    margin-top: 35px !important;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial {
    margin-top: 35px !important;
  }
}

.testimonial .inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial .inner {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial .inner {
    display: block;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial .inner {
    display: block;
    margin: 0;
  }
}

.testimonial .inner .card-info {
  margin-right: 40px;
  max-width: 395px;
  min-width: 395px;
  padding: 30px 30px 40px 30px;
  border-radius: 10px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial .inner .card-info {
    min-width: 250px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial .inner .card-info {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial .inner .card-info {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial .inner .card-info {
    margin-right: 0;
    padding: 15px;
  }
}

.testimonial .inner .card-info .card-thumbnail {
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 27px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 767px) {
  .testimonial .inner .card-info .card-thumbnail {
    max-width: 150px;
    margin-right: 25px;
    margin-bottom: 0;
  }
}

.testimonial .inner .card-info .card-thumbnail img {
  max-width: 100%;
  height: auto;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block !important;
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

.testimonial .inner .card-info .card-thumbnail img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.testimonial .inner .card-info .card-content .subtitle {
  font-size: 12px;
  color: var(--color-primary);
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 22px;
}

.testimonial .inner .card-info .card-content .title {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 0px;
  line-height: 34px;
  color: #c4cfde;
}

@media only screen and (max-width: 575px) {
  .testimonial .inner .card-info .card-content .title {
    font-size: 18px;
    line-height: 27px;
  }
}

.testimonial .inner .card-info .card-content .designation {
  font-size: 14px;
}

.testimonial .inner .card-description {
  position: relative;
  padding: 50px 40px 50px 40px;
  border-radius: 10px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  margin-top: 115px;
}

@media only screen and (max-width: 479px) {
  .testimonial .inner .card-description {
    padding: 15px;
  }
}

.testimonial .inner .card-description .title-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 479px) {
  .testimonial .inner .card-description .title-area {
    display: block;
  }
}

.testimonial .inner .card-description .title-area .title-info {
  text-align: left;
}

@media only screen and (max-width: 479px) {
  .testimonial .inner .card-description .title-area .title-info {
    display: block;
    margin-bottom: 25px;
  }
}

.testimonial .inner .card-description .title-area .title-info .title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #c4cfde;
}

@media only screen and (max-width: 767px) {
  .testimonial .inner .card-description .title-area .title-info .title {
    font-size: 18px;
    margin-bottom: 6px;
  }
}

.testimonial .inner .card-description .title-area .title-info .date {
  font-weight: 500;
  font-size: 16px;
  color: #7c838e;
}

@media only screen and (max-width: 767px) {
  .testimonial .inner .card-description .title-area .title-info .date {
    font-size: 12px;
  }
}

.testimonial .inner .card-description .title-area .rating {
  padding: 5px 17px 9px 17px;
  border-radius: 6px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  display: inline-block;
}

@media only screen and (max-width: 767px) {
  .testimonial .inner .card-description .title-area .rating {
    padding: 15px 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media only screen and (max-width: 479px) {
  .testimonial .inner .card-description .title-area .rating {
    padding: 5px 17px 9px 17px;
    display: inline-block;
  }
}

.testimonial .inner .card-description .title-area .rating img {
  display: inline-block !important;
}

@media only screen and (max-width: 767px) {
  .testimonial .inner .card-description .title-area .rating img {
    margin-right: 5px;
  }
}

.testimonial .inner .card-description .seperator {
  position: relative;
}

.testimonial .inner .card-description .seperator::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #1a1d23;
  top: 20px;
}

.testimonial .inner .card-description .discription {
  margin-top: 45px;
  font-size: 18px;
  line-height: 30px;
}

.testimonial .inner .card-description::before {
  content: "";
  position: absolute;
  width: 51px;
  height: 5px;
  background: #181a1d;
  left: -43px;
  z-index: -1;
  top: 66px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial .inner .card-description::before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial .inner .card-description::before {
    display: none;
  }
}

.testimonial .inner .card-description::after {
  content: "";
  position: absolute;
  width: 130px;
  height: 130px;
  left: 0;
  top: -130px;
  background: transparent;
  background-size: cover;
  z-index: -1;
  opacity: 0.5;
  background-repeat: no-repeat;
  background-position: center center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial .inner .card-description::after {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial .inner .card-description::after {
    display: none;
  }
}

.testimonial-style-2 .carousel-testimonial {
  padding: 0;
  margin-top: 30px;
}

.testimonial-style-2 .carousel-testimonial .carousel-control-next {
  right: -20px;
}

.testimonial-style-2 .carousel-testimonial .carousel-control-prev {
  left: -20px;
}

.testimonial-style-2 .carousel-testimonial .carousel-inner {
  padding: 30px 0;
  margin-bottom: -10px;
}

.testimonial-style-2 .carousel-testimonial a {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  opacity: 1;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
  width: 20px;
  height: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 30px;
  border-radius: 50%;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  background-color: #212428;
}

.testimonial-style-2 .carousel-testimonial a svg {
  width: 25px;
  position: absolute;
  z-index: 100;
  color: var(--color-primary);
}

.testimonial-style-2 .testimonial-inner {
  padding: 45px 44px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 10px;
  position: relative;
  z-index: 1;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-style-2 .testimonial-inner {
    padding: 20px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-style-2 .testimonial-inner {
    padding: 20px 30px;
  }
}

.testimonial-style-2 .testimonial-inner::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.testimonial-style-2 .testimonial-inner .testimonial-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 25px;
}

.testimonial-style-2 .testimonial-inner .testimonial-header .thumbnail img {
  width: 50px;
  border-radius: 50%;
}

.testimonial-style-2 .testimonial-inner .testimonial-header .ts-header {
  font-size: 14px;
  margin: 0;
  margin-left: 10px;
  font-weight: 500;
}

.testimonial-style-2 .testimonial-inner .testimonial-body p.discription {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-lightn);
  margin-bottom: 22px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin: 0;
  font-weight: 400;
  text-align: left;
}

.testimonial-style-2 .testimonial-inner .testimonial-body p.discription span {
  text-decoration: underline;
  font-weight: 500;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  cursor: pointer;
}

.testimonial-style-2
  .testimonial-inner
  .testimonial-body
  p.discription
  span:hover {
  color: var(--color-primary);
}

.testimonial-style-2 .testimonial-inner:hover::before {
  opacity: 1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial .inner .card-info {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
  }

  .testimonial .inner .card-info .card-thumbnail {
    max-width: 150px;
    margin-right: 25px;
    margin-bottom: 0;
  }

  .testimonial .inner .card-description {
    max-width: 100%;
    padding: 35px 30px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial .inner .card-info {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
  }

  .testimonial .inner .card-info .card-thumbnail {
    max-width: 100px;
    margin-right: 25px;
    margin-bottom: 0;
  }

  .testimonial .inner .card-description {
    max-width: 100%;
    padding: 35px 30px;
    margin-top: 0;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial .inner .card-info {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    display: block;
    padding: 20px;
  }

  .testimonial .inner .card-info .card-thumbnail {
    max-width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .testimonial .inner .card-description {
    max-width: 100%;
    padding: 20px;
    margin-top: 0;
  }
}

.tab-navigation-button {
  float: left;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  background-color: var(--background-color-1);
  border-radius: 10px;
  width: 100%;
  height: auto;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-navigation-button {
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-navigation-button {
    width: 100%;
    float: none;
    display: inline-block;
    padding: 30px 0;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-navigation-button {
    width: 100%;
    float: none;
    display: inline-block;
    padding: 20px 0;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .tab-navigation-button {
    margin-bottom: 35px;
  }
}

.tab-navigation-button .nav-item {
  margin: 0;
}

.tab-navigation-button .nav-item .nav-link {
  border: none;
  font-size: 18px;
  color: var(--color-light);
  height: 90px;
  border-radius: 10px;
  padding: 15px 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .tab-navigation-button .nav-item .nav-link {
    height: auto;
    padding: 15px 20px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-navigation-button .nav-item .nav-link {
    height: auto;
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 767px) {
  .tab-navigation-button .nav-item .nav-link {
    height: auto;
    padding: 15px 20px;
  }
}

.tab-navigation-button .nav-item .nav-link:hover,
.tab-navigation-button .nav-item .nav-link:focus,
.tab-navigation-button .nav-item .nav-link.active {
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  color: var(--color-primary);
  background-color: #212428;
}

.tab-navigation-button .nav-item .nav-link.rn-nav:hover,
.tab-navigation-button .nav-item .nav-link.rn-nav:focus,
.tab-navigation-button .nav-item .nav-link.rn-nav.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #ffffff !important;
  background: var(--color-primary) !important;
}

@media only screen and (max-width: 575px) {
  .tab-navigation-button .nav-item .nav-link.rn-nav {
    font-size: 14px;
    padding: 15px 20px;
  }
}

@media only screen and (max-width: 479px) {
  .tab-navigation-button .nav-item .nav-link.rn-nav {
    font-size: 12px;
    padding: 10px 13px;
  }
}

.clients-wrapper {
  width: 100%;
}

.rn-client-area .section-title {
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .rn-client-area .section-title {
    text-align: center !important;
  }
}

.client-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -15px;
  margin-top: -30px;
}

@media only screen and (max-width: 479px) {
  .client-card {
    padding: 0 20px;
  }
}

.client-card .main-content {
  margin-top: 30px;
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .client-card .main-content {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .client-card .main-content {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 479px) {
  .client-card .main-content {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.client-card .main-content .inner {
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

.client-card .main-content .inner::after {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
  border-radius: 8px;
}

.client-card .main-content .inner:hover::after {
  opacity: 1;
}

.client-card .main-content .thumbnail {
  height: 150px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 30px;
}

.client-card .main-content .thumbnail a {
  display: block;
}

.client-card .main-content .thumbnail a img {
  max-height: 90px;
}

.client-card .main-content .seperator {
  position: relative;
  z-index: 2;
}

.client-card .main-content .seperator::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #1a1d23;
  z-index: -1;
}

.client-card .main-content .client-name {
  padding: 16px;
  color: #7c838e;
}

.client-card .main-content .client-name a {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #7c838e;
}

.client-card .main-content .client-name a:hover {
  color: var(--color-primary);
}

.client-card .main-content .client-name a span {
  color: var(--color-light);
  font-size: 16px;
}

.rn-brand {
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
  padding: 0 15px;
}

@media only screen and (max-width: 767px) {
  .rn-brand {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .rn-brand {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 479px) {
  .rn-brand {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}

.rn-brand .inner {
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 10px;
  position: relative;
  z-index: 2;
}

@media only screen and (max-width: 1199px) {
  .rn-brand .inner.smlg-brand {
    width: 250px;
  }
}

.rn-brand .inner::after {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
  border-radius: 8px;
}

.rn-brand .inner:hover::after {
  opacity: 1;
}

.rn-brand .thumbnail img {
  width: 265px;
  height: 175px;
  padding: 30px;
  -o-object-fit: contain;
  object-fit: contain;
  text-align: center;
  display: inline-block;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-brand .thumbnail img {
    height: 150px;
    padding: 30px;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-brand .thumbnail img {
    padding: 30px;
    -o-object-fit: contain;
    object-fit: contain;
    height: 148px !important;
  }
}

@media only screen and (max-width: 767px) {
  .rn-brand .thumbnail img {
    height: 148px !important;
    padding: 30px;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

@media only screen and (max-width: 479px) {
  .rn-brand .thumbnail img {
    height: 148px !important;
    padding: 30px;
    -o-object-fit: contain;
    object-fit: contain;
  }
}

.rn-brand .seperator {
  position: relative;
  z-index: 2;
}

.rn-brand .seperator::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-color: #1a1d23;
  z-index: -1;
}

.rn-brand .client-name {
  padding: 16px;
  color: #7c838e;
}

.rn-brand .client-name a {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: #7c838e;
}

.rn-brand .client-name a:hover {
  color: var(--color-primary);
}

.rn-brand .client-name a span {
  color: var(--color-light);
  font-size: 16px;
}

.brand-activation-item-5 {
  margin: -28px 0;
}

.brand-activation-item-5 .rn-brand .inner {
  margin: 28px 0;
}

.brand-wrapper-three {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.brand-wrapper-three .rn-brand {
  margin-bottom: 30px;
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
}

.rn-client-style-2 .skill-style-1 {
  margin-bottom: 0;
  margin-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.rn-client-style-2 .client-card .main-content .client-name a {
  color: var(--color-lightn);
}

.rn-client-style-2 .client-card .main-content .client-name a:hover {
  color: var(--color-primary);
}

.rn-client-style-2 .client-card {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-client-style-2 .client-card {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-client-style-2 .client-card {
    margin-top: 15px;
  }
}

.rn-client-style-2 .client-card .main-content {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
}

@media only screen and (max-width: 767px) {
  .rn-client-style-2 .client-card .main-content {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .rn-client-style-2 .client-card .main-content {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
}

@media only screen and (max-width: 479px) {
  .rn-client-style-2 .client-card .main-content {
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-client-style-2 .client-card .main-content {
    -ms-flex-preferred-size: 33%;
    flex-basis: 33%;
  }
}

.rn-client-style-2 .client-card .main-content .thumbnail img {
  height: auto;
}

.rn-client-style-2 ul {
  padding: 0;
}

.navigation-wrapper {
  margin-left: 10px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navigation-wrapper {
    margin: 0;
  }
}

@media only screen and (max-width: 767px) {
  .navigation-wrapper {
    margin: 0;
  }
}

.navigation-wrapper ul {
  border-radius: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navigation-wrapper ul.nav {
  background-color: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  border: 0;
}

@media only screen and (max-width: 479px) {
  .navigation-wrapper ul.nav {
    display: block;
  }
}

.navigation-wrapper ul.nav li {
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
  margin: 0;
}

.navigation-wrapper ul.nav li.recommended {
  position: relative;
}

.navigation-wrapper ul.nav li.recommended::after {
  content: "";
  right: 60px;
  top: -23px;
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #545961;
  z-index: 1;
}

@media only screen and (max-width: 479px) {
  .navigation-wrapper ul.nav li.recommended::after {
    display: none;
  }
}

.navigation-wrapper ul.nav li.recommended::before {
  content: "Recommended";
  position: absolute;
  top: -50px;
  right: 33px;
  background: #545961;
  padding: 5px 13px;
  font-size: 12px;
  border-radius: 30px;
  color: #fff;
  z-index: 2;
}

@media only screen and (max-width: 479px) {
  .navigation-wrapper ul.nav li.recommended::before {
    top: 5px;
    right: 5px;
    background: #54596152;
    border-radius: 9px;
  }
}

.navigation-wrapper ul.nav li a {
  text-align: center;
  display: block;
  padding: 30px 10px;
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.navigation-wrapper ul.nav li a:hover,
.navigation-wrapper ul.nav li a:focus,
.navigation-wrapper ul.nav li a.active {
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  color: var(--color-primary);
}

.navigation-wrapper .tab-content {
  padding: 0 50px 40px 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .navigation-wrapper .tab-content {
    padding: 0 40px 40px 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navigation-wrapper .tab-content {
    padding: 0 40px 40px 40px;
  }
}

@media only screen and (max-width: 767px) {
  .navigation-wrapper .tab-content {
    padding: 0 40px 40px 40px;
  }
}

@media only screen and (max-width: 479px) {
  .navigation-wrapper .tab-content {
    padding: 15px 15px;
  }
}

.rn-pricing .pricing-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 55px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-pricing .pricing-header {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .rn-pricing .pricing-header {
    display: block;
    margin-bottom: 25px;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .rn-pricing .pricing-header .header-left {
    margin-bottom: 25px;
  }
}

.rn-pricing .pricing-header .header-left h2.title {
  font-size: 27px;
  color: var(--color-lightn);
  margin-bottom: 9px;
}

@media only screen and (max-width: 575px) {
  .rn-pricing .pricing-header .header-left h2.title {
    font-size: 22px;
  }
}

.rn-pricing .pricing-header .header-left span {
  color: #7c838e;
  font-family: var(--font-secondary);
}

.rn-pricing .pricing-header .header-right span {
  color: var(--color-primary);
  font-size: 30px;
  font-weight: 600;
  padding: 9px 25px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 6px;
  display: inline-block;
}

.rn-pricing .pricing-body p.description {
  font-size: 18px;
  line-height: 30px;
  max-width: 84%;
  font-weight: 400;
  color: #7c838e;
  font-family: var(--font-primary);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-pricing .pricing-body p.description {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-pricing .pricing-body p.description {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .rn-pricing .pricing-body p.description {
    margin-bottom: 30px;
    font-size: 16px;
  }
}

.rn-pricing .pricing-body .check-wrapper {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.rn-pricing .pricing-body .check-wrapper .left-area {
  margin-bottom: 30px;
}

.rn-pricing .pricing-body .check-wrapper .check {
  margin-bottom: 15px;
}

.rn-pricing .pricing-body .check-wrapper .check svg {
  margin-top: 2px;
  color: var(--color-lightn);
}

.rn-pricing .pricing-body .check-wrapper .check p {
  margin-left: 5px;
  color: var(--color-lightn);
  font-weight: 400;
  font-size: 18px;
  font-family: var(--font-secondary);
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-pricing .pricing-body .check-wrapper .check p {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-pricing .pricing-body .check-wrapper .check p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-pricing .pricing-body .check-wrapper .check p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 575px) {
  .rn-pricing .pricing-body .check-wrapper .check p {
    font-size: 13px;
  }
}

@media only screen and (max-width: 479px) {
  .rn-pricing .pricing-body .check-wrapper {
    display: block;
  }
}

.rn-pricing .pricing-footer {
  text-align: center;
  margin-top: 50px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-pricing .pricing-footer {
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-pricing .pricing-footer {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-pricing .pricing-footer {
    margin-top: 15px;
  }
}

.rn-pricing .pricing-footer .rn-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 25px;
  letter-spacing: 1px;
  font-family: var(--font-secondary);
  padding: 20px 0;
}

.rn-pricing .pricing-footer .rn-btn span {
  margin-right: 5px;
  font-size: 16px;
}

.rn-pricing .pricing-footer .rn-btn svg {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.rn-pricing .pricing-footer .rn-btn:hover svg {
  margin-left: 10px;
}

.rn-pricing .pricing-footer .time-line {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 479px) {
  .rn-pricing .pricing-footer .time-line {
    display: block !important;
    padding-left: 5px;
  }
}

.rn-pricing .pricing-footer .time-line .single-cmt {
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media only screen and (max-width: 479px) {
  .rn-pricing .pricing-footer .time-line .single-cmt:nth-child(2) {
    margin-top: 5px;
  }
}

.rn-pricing .pricing-footer .time-line .single-cmt svg {
  width: 14px;
  margin-right: 7px;
  color: #9ca5b1;
  margin-top: 2px;
}

.rn-pricing .pricing-footer .time-line .single-cmt span {
  font-size: 12px;
  font-weight: 500;
  color: #9ca5b1;
  margin-top: 5px;
  letter-spacing: 1px;
  font-family: var(--font-secondary);
}

@media only screen and (max-width: 479px) {
  .rn-pricing .pricing-footer .time-line .single-cmt span {
    font-size: 12px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-pricing-area .section-title {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-pricing-area .section-title {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 479px) {
  .rn-pricing-area .section-title {
    margin-bottom: 10px;
  }
}

.rn-pricing-area .section-title h2.title {
  margin-bottom: 0;
}

.sticky-top {
  z-index: 10 !important;
}

.pricing-style-2 .title-area-wrapper {
  margin-right: -20px;
}

@media only screen and (max-width: 575px) {
  .pricing-style-2 .title-area-wrapper {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .pricing-style-2 .title-area-wrapper {
    margin-right: 0;
  }
}

.pricing-style-2 .title-area-wrapper .section-title {
  margin-bottom: 0;
}

.pricing-style-2 .title-area-wrapper .section-title h2.title {
  margin-bottom: 40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-style-2 .title-area-wrapper .section-title h2.title {
    margin-bottom: 5px;
  }
}

.pricing-style-2 .title-area-wrapper p {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-lightn);
  margin: 0;
  font-family: var(--font-secondary);
  padding-right: 49px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pricing-style-2 .title-area-wrapper p {
    padding-right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-style-2 .title-area-wrapper p {
    padding-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-style-2 .title-area-wrapper p {
    padding-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-style-2 .title-area-wrapper p {
    text-align: center;
    padding-right: 0;
  }
}

@media only screen and (max-width: 479px) {
  .pricing-style-2 .title-area-wrapper p {
    text-align: center;
    padding-right: 0;
  }
}

.pricing-style-2 .title-area-wrapper p.title-disc {
  margin-bottom: 25px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-style-2 .title-area-wrapper p.title-disc {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-style-2 .title-area-wrapper p.title-disc {
    margin-bottom: 8px;
  }
}

@media only screen and (max-width: 575px) {
  .pricing-style-2 .title-area-wrapper p.title-disc {
    text-align: center;
    padding-right: 0;
  }
}

@media only screen and (max-width: 479px) {
  .pricing-style-2 .title-area-wrapper p.title-disc {
    text-align: center;
    padding-right: 0;
  }
}

.pricing-style-2 .pricing-wrapper,
.pricing-style-2 .pricing-wrapper-pro {
  margin-left: 20px;
  position: relative;
  z-index: 1;
  padding: 40px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 10px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-style-2 .pricing-wrapper,
  .pricing-style-2 .pricing-wrapper-pro {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-style-2 .pricing-wrapper,
  .pricing-style-2 .pricing-wrapper-pro {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1199px) {
  .pricing-style-2 .pricing-wrapper,
  .pricing-style-2 .pricing-wrapper-pro {
    margin-left: 0;
  }
}

.pricing-style-2 .pricing-wrapper::before,
.pricing-style-2 .pricing-wrapper-pro::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.pricing-style-2 .pricing-wrapper .ts-header,
.pricing-style-2 .pricing-wrapper-pro .ts-header {
  margin-bottom: 30px;
}

.pricing-style-2 .pricing-wrapper .ts-header h6,
.pricing-style-2 .pricing-wrapper-pro .ts-header h6 {
  font-weight: 600;
  font-size: 16px;
  color: var(--color-white);
  margin-bottom: 10px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.pricing-style-2 .pricing-wrapper .ts-header span,
.pricing-style-2 .pricing-wrapper-pro .ts-header span {
  color: var(--color-lightn);
  font-size: 16px;
}

.pricing-style-2 .pricing-wrapper .price,
.pricing-style-2 .pricing-wrapper-pro .price {
  font-size: 36px;
  font-family: var(--font-primary);
  color: var(--color-lightest);
  margin-bottom: 23px;
}

.pricing-style-2 .pricing-wrapper .pricing-body,
.pricing-style-2 .pricing-wrapper-pro .pricing-body {
  margin-bottom: 40px;
}

.pricing-style-2 .pricing-wrapper .pricing-body .feature,
.pricing-style-2 .pricing-wrapper-pro .pricing-body .feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.pricing-style-2 .pricing-wrapper .pricing-body .feature svg,
.pricing-style-2 .pricing-wrapper-pro .pricing-body .feature svg {
  margin-right: 10px;
  position: relative;
  background-color: var(--color-lightn);
  border-radius: 50%;
  background: none;
  color: var(--color-lightn);
  opacity: 0.8;
  width: 22px;
}

.pricing-style-2 .pricing-wrapper .pricing-body .feature svg.off,
.pricing-style-2 .pricing-wrapper-pro .pricing-body .feature svg.off {
  color: #666666;
  position: relative;
  background-color: var(--color-lightn);
  border-radius: 50%;
  background: none;
}

.pricing-style-2 .pricing-wrapper .pricing-body .feature .name,
.pricing-style-2 .pricing-wrapper-pro .pricing-body .feature .name {
  color: var(--color-lightn);
  font-weight: 400;
  font-size: 16px;
}

.pricing-style-2 .pricing-wrapper .pricing-body .feature .name.off,
.pricing-style-2 .pricing-wrapper-pro .pricing-body .feature .name.off {
  color: #646669;
}

.pricing-style-2 .pricing-wrapper .pricing-footer a.rn-btn,
.pricing-style-2 .pricing-wrapper-pro .pricing-footer a.rn-btn {
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  display: block;
  text-align: center;
  padding: 14px;
}

.pricing-style-2 .pricing-wrapper .pricing-footer a.rn-btn::before,
.pricing-style-2 .pricing-wrapper-pro .pricing-footer a.rn-btn::before {
  background: -webkit-gradient(
    linear,
    right bottom,
    left top,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to left top, #212428, #16181c);
}

.pricing-style-2 .pricing-wrapper-pro {
  margin-left: 5px;
}

.pricing-style-2 .pricing-wrapper-pro::before {
  opacity: 0;
}

.pricing-style-2 .pricing-wrapper-pro .pricing-footer a.rn-btn {
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

.pricing-style-2 .pricing-wrapper {
  margin-right: -15px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .pricing-style-2 .pricing-wrapper {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1199px) {
  .pricing-style-2 .pricing-wrapper {
    margin-right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .pricing-style-2 .pricing-wrapper {
    margin-right: 0;
    margin-left: 0;
    margin-top: 30px;
  }
}

.contact-about-area {
  padding: 30px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 10px;
  background-color: #212428;
  height: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact-about-area {
    height: auto;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-about-area {
    margin-bottom: 50px;
    height: auto;
  }
}

@media only screen and (max-width: 767px) {
  .contact-about-area {
    margin-bottom: 0;
    padding: 50px 20px;
    height: auto;
  }
}

.contact-about-area .thumbnail {
  margin-bottom: 28px;
  overflow: hidden;
  border-radius: 10px;
}

.contact-about-area .thumbnail img {
  width: 100%;
  height: auto;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-about-area .title-area {
  margin-bottom: 15px;
}

.contact-about-area .title-area h4.title {
  font-size: 29px;
  line-height: 44px;
  color: var(--color-light);
  margin-bottom: 9px;
}

@media only screen and (max-width: 479px) {
  .contact-about-area .title-area h4.title {
    font-size: 20px;
  }
}

.contact-about-area .description {
  margin-bottom: 39px;
}

.contact-about-area .description p {
  font-size: 18px;
  font-weight: 400;
  color: var(--color-body);
  font-family: var(--font-primary);
  line-height: 30px;
  display: inline-block;
  margin-bottom: 20px;
}

.contact-about-area .description span {
  display: block;
}

@media only screen and (max-width: 767px) {
  .contact-about-area .description span {
    font-size: 16px;
  }
}

.contact-about-area .description span a {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
  color: var(--color-lightn);
  font-weight: 400;
}

.contact-about-area .description span a::before {
  content: "";
  position: absolute;
  width: 0%;
  background-color: var(--color-primary);
  height: 1px;
  left: 0;
  bottom: 2px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: 10;
}

.contact-about-area .description span a:hover {
  color: var(--color-primary);
}

.contact-about-area .description span a:hover::before {
  width: 100%;
}

.contact-about-area .social-area .name {
  font-size: 14px;
  font-family: var(--font-secondary);
  font-weight: 500;
  color: var(--color-body);
  margin-bottom: 8px;
}

.contact-about-area .social-area .social-icone {
  margin-top: 24px;
}

.contact-about-area .social-area .social-icone a {
  width: 61px;
  height: 61px;
  line-height: 57px;
  margin-right: 15px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  display: inline-block;
  text-align: center;
}

.contact-about-area .social-area .social-icone a::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.contact-about-area .social-area .social-icone a svg {
  stroke-width: 2;
  width: 20px;
  color: var(--color-lightn);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-about-area .social-area .social-icone a:hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}

.contact-about-area .social-area .social-icone a:hover svg {
  color: var(--color-white);
}

.contact-about-area .social-area .social-icone a:hover::before {
  opacity: 1;
}

.contact-about-area:hover .thumbnail img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.contact-form-wrapper {
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  padding: 30px;
  border-radius: 10px;
  margin-left: 20px;
  height: 100%;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact-form-wrapper {
    margin: 0;
    margin-top: 0;
  }
}

@media only screen and (max-width: 767px) {
  .contact-form-wrapper {
    margin: 0;
    margin-top: 40px;
  }
}

@media only screen and (max-width: 479px) {
  .contact-form-wrapper {
    padding: 20px;
  }
}

.contact-form-wrapper label {
  font-size: 12px;
  color: #a0a8b3;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: var(--font-secondary);
  margin-bottom: 14px;
  text-align: left;
  display: block;
}

.contact-form-wrapper input {
  background-color: #191b1e;
  border-radius: 6px;
  height: 55px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 2px solid #191b1e;
  padding: 0 15px;
  font-size: 14px;
  font-family: var(--font-secondary);
  color: var(--color-lightn);
  -webkit-box-shadow: var(--inner-shadow);
  box-shadow: var(--inner-shadow);
  letter-spacing: 1px;
}

@media only screen and (max-width: 479px) {
  .contact-form-wrapper input {
    margin-bottom: 15px;
  }
}

.contact-form-wrapper input:focus {
  background-color: #191b1e;
  color: var(--color-light);
  border-color: var(--color-primary);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.contact-form-wrapper textarea {
  min-height: 235px;
  background-color: #191b1e;
  border-radius: 6px;
  padding: 0;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  border: 2px solid #191b1e;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 18px;
  font-family: var(--font-secondary);
  color: var(--color-lightn);
  -webkit-box-shadow: var(--inner-shadow);
  box-shadow: var(--inner-shadow);
  letter-spacing: 1px;
  resize: none;
}

@media only screen and (max-width: 767px) {
  .contact-form-wrapper textarea {
    min-height: 150px;
  }
}

.contact-form-wrapper textarea:focus {
  border-color: var(--color-primary);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.contact-form-wrapper .introduce {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.contact-form-wrapper .introduce input {
  width: 100%;
}

.contact-form-wrapper a.rn-btn span {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: var(--font-secondary);
  -webkit-transform: var(--transition);
  transform: var(--transition);
  margin-left: 5px;
}

.contact-form-wrapper a.rn-btn svg {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.contact-form-wrapper a:hover svg {
  margin-left: 10px;
}

.contact-form-wrapper .email-area {
  margin-top: 30px;
}

@media only screen and (max-width: 479px) {
  .contact-form-wrapper .email-area {
    margin-top: 0;
  }
}

.contact-form-wrapper .subject {
  margin-top: 30px;
}

@media only screen and (max-width: 479px) {
  .contact-form-wrapper .subject {
    margin-top: 0;
  }
}

.contact-form-wrapper .message {
  margin-top: 30px;
}

@media only screen and (max-width: 479px) {
  .contact-form-wrapper .message {
    margin-top: 0;
  }
}

@media only screen and (max-width: 479px) {
  .contact-form-wrapper .rn-btn {
    display: block;
    text-align: center;
  }
}

.error-msg {
  margin-top: 20px;
  background: #ff00031f;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.error-msg p {
  color: #f10;
  font-size: 16px;
}

.success-msg {
  margin-top: 20px;
  background: #3eb75e2e;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.success-msg p {
  color: green;
  font-size: 16px;
}

.rn-about-area .image-area {
  padding: 30px;
  border-radius: 8px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  margin-right: 20px;
}

@media only screen and (max-width: 479px) {
  .rn-about-area .image-area {
    margin-right: 0;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-about-area .image-area {
    padding: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-about-area .image-area {
    margin-right: 0;
    padding: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-about-area .image-area {
    margin-right: 0;
    padding: 15px;
  }
}

.rn-about-area .image-area .thumbnail {
  overflow: hidden;
  border-radius: 8px;
}

.rn-about-area .image-area .thumbnail img {
  border-radius: 8px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
}

.rn-about-area .image-area .thumbnail img:hover {
  -webkit-transform: scale(1.08);
  transform: scale(1.08);
}

.rn-about-area .contant {
  top: 50%;
  position: relative;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-about-area .contant {
    padding-top: 40px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-about-area .contant {
    padding: 0 10px;
  }
}

@media only screen and (max-width: 575px) {
  .rn-about-area .contant {
    padding: 0 10px;
    padding-top: 16px;
  }
}

.rn-about-area .contant .discription {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-lightn);
  margin-bottom: 30px;
  padding-right: 10%;
}

@media only screen and (max-width: 1199px) {
  .rn-about-area .contant .discription {
    line-height: 26px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-about-area .contant .discription {
    margin-bottom: 26px;
  }
}

@media only screen and (max-width: 767px) {
  .rn-about-area .contant .discription {
    padding-right: 0;
  }
}

@media only screen and (max-width: 575px) {
  .rn-about-area .contant .discription {
    line-height: 26px;
    text-align: center;
  }
}

.rn-about-area .contant .discription:nth-child(2) {
  margin-bottom: 20px;
  margin-top: 23px;
}

@media only screen and (max-width: 1199px) {
  .rn-about-area .contant .discription:nth-child(2) {
    line-height: 26px;
    margin-bottom: 10px;
  }
}

.rn-about-area .rn-btn {
  padding: 18px 35px;
}

@media only screen and (max-width: 575px) {
  .rn-about-area .rn-btn {
    width: 80%;
    margin: auto;
    display: block;
    text-align: center;
  }
}

.sticky-home-wrapper .section-height {
  padding: 35px 40px 40px;
  border-radius: 10px;
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 479px) {
  .sticky-home-wrapper .section-height {
    padding: 35px 15px 40px;
  }
}

@media only screen and (max-width: 479px) {
  .sticky-home-wrapper .section-height {
    padding: 35px 15px 40px;
  }
}

@media only screen and (max-width: 575px) {
  .sticky-home-wrapper .section-height {
    text-align: center;
  }
}

.sticky-home-wrapper .section-height::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  -webkit-filter: blur(1px);
  filter: blur(1px);
  z-index: -1;
}

.sticky-home-wrapper h5.title {
  position: relative;
  margin-bottom: 35px;
  font-size: 30px;
  text-align: left;
}

@media only screen and (max-width: 767px) {
  .sticky-home-wrapper h5.title {
    font-size: 24px;
  }
}

.sticky-home-wrapper h5.title::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background: #121415;
  bottom: -18px;
  left: 0;
}

.sticky-home-wrapper .rn-about-area .inner p {
  margin: 0;
}

.sticky-home-wrapper .rn-about-area .inner p:nth-child(2) {
  margin-bottom: 25px;
}

.sticky-home-wrapper .rn-about-area .inner p.about-disc {
  font-size: 18px;
  color: var(--color-body);
  line-height: 28px;
}

.sticky-home-wrapper .rn-skill-area .inner.slide {
  margin-bottom: 0;
  padding-bottom: 0;
}

.sticky-home-wrapper .rn-skill-area .inner .skill-share-inner {
  padding-top: 0 !important;
}

.sticky-home-wrapper .rn-skill-area .inner .skill-share-inner.margin-top-25 {
  margin-top: 25px !important;
}

.sticky-home-wrapper .rn-skill-area .inner .skill-share-inner span {
  padding: 0;
}

.sticky-home-wrapper .rn-skill-area .inner .skill-share-inner .skill-share {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-right: -25px;
  margin-bottom: -8px;
}

@media only screen and (max-width: 1199px) {
  .sticky-home-wrapper .rn-skill-area .inner .skill-share-inner .skill-share {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sticky-home-wrapper .rn-skill-area .inner .skill-share-inner .skill-share {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 575px) {
  .sticky-home-wrapper .rn-skill-area .inner .skill-share-inner .skill-share {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.sticky-home-wrapper .rn-skill-area .inner .skill-share-inner .skill-share li {
  width: 81px;
  height: 81px;
  line-height: 81px;
  margin-bottom: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media only screen and (max-width: 1199px) {
  .sticky-home-wrapper
    .rn-skill-area
    .inner
    .skill-share-inner
    .skill-share
    li {
    margin-right: 17px;
    margin-bottom: 17px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sticky-home-wrapper
    .rn-skill-area
    .inner
    .skill-share-inner
    .skill-share
    li {
    margin-right: 30px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .sticky-home-wrapper
    .rn-skill-area
    .inner
    .skill-share-inner
    .skill-share
    li {
    margin-right: 30px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .sticky-home-wrapper
    .rn-skill-area
    .inner
    .skill-share-inner
    .skill-share
    li {
    margin-right: 30px;
    margin-bottom: 30px;
    height: 65px;
    width: 65px;
  }
}

.sticky-home-wrapper
  .rn-skill-area
  .inner
  .skill-share-inner
  .skill-share
  li
  img {
  max-height: 30px;
}

@media only screen and (max-width: 479px) {
  .sticky-home-wrapper
    .rn-skill-area
    .inner
    .skill-share-inner
    .skill-share
    li
    img {
    max-height: 27px;
  }
}

.sticky-home-wrapper
  .rn-skill-area
  .inner
  .skill-share-inner.skill-share-inner-width
  .skill-share {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@media only screen and (max-width: 575px) {
  .sticky-home-wrapper
    .rn-skill-area
    .inner
    .skill-share-inner.skill-share-inner-width
    .skill-share {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.sticky-home-wrapper
  .rn-skill-area
  .inner
  .skill-share-inner.skill-share-inner-width
  li {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.sticky-home-wrapper
  .rn-skill-area
  .inner
  .skill-share-inner.skill-share-inner-width
  li
  img {
  max-width: 23px;
}

.sticky-home-wrapper .single-card-sticky .card-list {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 575px) {
  .sticky-home-wrapper .single-card-sticky .card-list {
    text-align: left;
  }
}

@media only screen and (max-width: 479px) {
  .sticky-home-wrapper .single-card-sticky .card-list {
    text-align: left;
  }
}

.sticky-home-wrapper .single-card-sticky .card-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 20px;
  color: var(--color-lightn);
  position: relative;
  line-height: 38px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.sticky-home-wrapper .single-card-sticky .card-list li::before {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background-color: var(--color-body);
  border-radius: 50%;
  top: 50%;
  left: -20px;
  opacity: 0.3;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.sticky-home-wrapper .single-card-sticky .card-list li a {
  font-size: 18px;
  font-weight: 400;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--color-body);
  line-height: 30px;
}

.sticky-home-wrapper .single-card-sticky .card-list li a svg {
  width: 0px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--color-primary);
}

.sticky-home-wrapper .single-card-sticky .card-list li a:hover {
  margin-top: 5px;
  color: var(--color-primary);
}

.sticky-home-wrapper .single-card-sticky .card-list li a:hover svg {
  width: 50px;
}

.sticky-home-wrapper .rn-testimonial-area .testimonial-inner {
  padding: 10px 1px 57px 1px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.sticky-home-wrapper .rn-testimonial-area .testimonial-inner::before {
  display: none;
}

.sticky-home-wrapper .contact-form-wrapper {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

.portfolio-popup-thumbnail img {
  border-radius: 10px;
}

.rn-testimonial-carousel.carousel .carousel-indicators {
  top: 84%;
  left: 0;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-left: 0;
}

.rn-testimonial-carousel.carousel .carousel-indicators li {
  font-size: 0;
  line-height: 0;
  width: 11px;
  height: 11px;
  padding: 0;
  cursor: pointer;
  border: 0;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--inner-shadow);
  box-shadow: var(--inner-shadow);
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin: 0 8px;
}

.rn-testimonial-carousel.carousel .carousel-indicators li.active {
  background: var(--color-primary);
}

.rn-testimonial-carousel.carousel a {
  width: 20px;
  height: 20px;
  top: -39%;
  padding: 23px;
  border-radius: 13%;
  background: #212428;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  opacity: 1;
  z-index: 1;
}

@media only screen and (max-width: 1199px) {
  .rn-testimonial-carousel.carousel a {
    top: -31%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-testimonial-carousel.carousel a {
    top: -33%;
  }
}

@media only screen and (max-width: 479px) {
  .rn-testimonial-carousel.carousel a {
    top: -25%;
  }
}

@media only screen and (max-width: 575px) {
  .rn-testimonial-carousel.carousel a {
    top: -24%;
  }
}

.rn-testimonial-carousel.carousel a svg {
  width: 25px;
  position: absolute;
  z-index: 100;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.rn-testimonial-carousel.carousel a:hover::before {
  opacity: 1;
}

.rn-testimonial-carousel.carousel a:hover svg {
  color: var(--color-primary);
}

.rn-testimonial-carousel.carousel a::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.rn-testimonial-carousel.carousel .carousel-control-prev {
  left: 79%;
}

@media only screen and (max-width: 1199px) {
  .rn-testimonial-carousel.carousel .carousel-control-prev {
    left: 70%;
    left: 70%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-testimonial-carousel.carousel .carousel-control-prev {
    left: 82%;
  }
}

@media only screen and (max-width: 767px) {
  .rn-testimonial-carousel.carousel .carousel-control-prev {
    left: 74%;
  }
}

@media only screen and (max-width: 575px) {
  .rn-testimonial-carousel.carousel .carousel-control-prev {
    left: 71%;
  }
}

@media only screen and (max-width: 479px) {
  .rn-testimonial-carousel.carousel .carousel-control-prev {
    left: 58%;
  }
}

.rn-testimonial-carousel.carousel .carousel-control-next {
  right: 0;
}

.rn-testimonial-carousel.carousel.slide {
  padding-bottom: 0;
}

.title.small-h2 {
  font-size: 32px !important;
}

.color-body {
  color: var(--color-body) !important;
}

.about-skill-style {
  padding: 0;
  margin: 30px 0;
}

.about-skill-style li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
}

.about-skill-style li svg {
  width: 18px;
  height: auto;
  color: var(--color-lightn);
  margin-right: 15px;
}

.about-skill-style li span {
  color: var(--color-body);
}

.service-card-one {
  text-align: center;
  padding: 35px 20px;
  border-radius: 13px;
  height: 100%;
}

.service-card-one .inner svg {
  width: 55px;
  height: auto;
  stroke-width: 1px;
  color: var(--color-primary);
  margin-bottom: 25px;
}

.service-card-one .inner p.describe {
  font-size: 18px;
  line-height: 28px;
}

.skill-style-1 {
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: -40px;
  margin-right: -40px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-style-1 {
    margin-top: 33px;
  }
}

.skill-style-1 li {
  text-align: center;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  margin-right: 40px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  margin-top: 0;
  margin-bottom: 40px;
  padding: 20px 30px;
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
  padding: 35px 30px;
  min-height: 130px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .skill-style-1 li {
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
  }
}

@media only screen and (max-width: 767px) {
  .skill-style-1 li {
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
  }
}

@media only screen and (max-width: 479px) {
  .skill-style-1 li {
    -ms-flex-preferred-size: 80%;
    flex-basis: 80%;
  }
}

.skill-style-1 li::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.skill-style-1 li:hover::before {
  opacity: 1;
}

.skill-style-1 li:hover img {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  opacity: 1;
}

.skill-style-1 li img {
  max-height: 50px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-area h4 span {
  padding: 20px 23px;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  display: inline-block;
  border-radius: 50%;
  font-size: 30px;
  line-height: 30px;
  color: var(--color-primary);
  margin-bottom: 23px;
}

.footer-area .description {
  font-size: 17px;
  color: var(--color-body);
  font-family: var(--font-primary);
}

.footer-area .description a {
  color: var(--color-body);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}

.footer-area .description a::before {
  position: absolute;
  content: "";
  width: 0%;
  height: 1px;
  background-color: var(--color-primary);
  left: 0;
  bottom: 3px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.footer-area .description a:hover {
  color: var(--color-primary);
}

.footer-area .description a:hover::before {
  width: 100%;
}

.footer-area .logo a img {
  max-height: 100px;
}

.footer-style-2 .copyright .description {
  color: var(--color-body);
  font-size: 18px;
}

@media only screen and (max-width: 575px) {
  .footer-style-2 .copyright .description {
    padding: 0 44px;
  }
}

@media only screen and (max-width: 479px) {
  .footer-style-2 .copyright .description {
    padding: 0 30px;
  }
}

.footer-style-2 .copyright .description a {
  color: var(--color-body);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}

.footer-style-2 .copyright .description a::after {
  content: "";
  position: absolute;
  width: 70%;
  background-color: var(--color-primary);
  height: 0px;
  left: 0;
  top: 13px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: -1;
}

.footer-style-2 .copyright .description a:hover {
  color: var(--color-white);
}

.footer-style-2 .copyright .description a:hover::after {
  height: 12px;
}

.footer-style-2 .logo-thumbnail {
  margin-bottom: 35px;
}

@media only screen and (max-width: 575px) {
  .footer-style-2 .logo-thumbnail {
    text-align: left;
  }
}

.footer-style-2 .logo-thumbnail img {
  max-width: 100%;
}

@media only screen and (max-width: 575px) {
  .footer-style-2 .social-icone-wrapper .social-share {
    -webkit-box-pack: left;
    -ms-flex-pack: left;
    justify-content: left;
  }
}

.footer-style-2 .social-icone-wrapper .social-share li {
  margin: 0 25px 25px 0;
}

.footer-style-2 .social-icone-wrapper .social-share li a {
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  border-radius: 6px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  position: relative;
}

.footer-style-2 .social-icone-wrapper .social-share li a::after {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.footer-style-2 .social-icone-wrapper .social-share li a:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.footer-style-2 .social-icone-wrapper .social-share li a:hover::after {
  opacity: 1;
}

.footer-style-2 .social-icone-wrapper .social-share li a svg {
  color: var(--color-lightn);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  stroke-width: 2;
  position: relative;
  z-index: 2;
  width: 20px;
}

.footer-style-2 .menu-wrapper {
  margin-left: 80px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-style-2 .menu-wrapper {
    margin-left: 0;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-style-2 .menu-wrapper {
    margin-left: 0;
  }
}

@media only screen and (max-width: 575px) {
  .footer-style-2 .menu-wrapper {
    text-align: left;
  }
}

.footer-style-2 .menu-wrapper .menu-title h6 {
  font-size: 18px;
  text-transform: uppercase;
  color: var(--color-primary);
  font-weight: 500;
}

@media only screen and (max-width: 575px) {
  .footer-style-2 .menu-wrapper .menu-title h6 {
    margin-bottom: 0;
  }
}

.footer-style-2 .menu-wrapper .menu-footer {
  padding: 0;
  margin-left: 0;
}

@media only screen and (max-width: 575px) {
  .footer-style-2 .menu-wrapper .menu-footer {
    margin-top: 0;
  }
}

.footer-style-2 .menu-wrapper .menu-footer li {
  margin-bottom: 8px;
  list-style: none;
}

.footer-style-2 .menu-wrapper .menu-footer li a {
  font-size: 17px;
  font-weight: 400;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--color-lightn);
  position: relative;
}

@media only screen and (max-width: 767px) {
  .footer-style-2 .menu-wrapper .menu-footer li a {
    font-size: 16px;
  }
}

.footer-style-2 .menu-wrapper .menu-footer li a::after {
  content: "";
  position: absolute;
  width: 70%;
  background-color: var(--color-primary);
  height: 0px;
  left: 0;
  top: 13px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  z-index: -1;
}

.footer-style-2 .menu-wrapper .menu-footer li a:hover {
  color: var(--color-light);
}

.footer-style-2 .menu-wrapper .menu-footer li a:hover::after {
  height: 12px;
}

.rn-portfolio,
.rn-blog {
  text-align: left;
  padding: 30px;
  position: relative;
  border-radius: 20px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  position: relative;
  z-index: 1;
  height: 100%;
  -webkit-transition: 0.5s all ease-in-out;
  transition: 0.5s all ease-in-out;
  display: inline-block;
}

.rn-blog {
  position: relative;
  padding: 60px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-portfolio,
  .rn-blog {
    padding: 30px;
  }
}

@media only screen and (max-width: 479px) {
  .rn-portfolio,
  .rn-blog {
    padding: 20px;
  }
}

.rn-portfolio .inner .thumbnail a,
.rn-blog .inner .thumbnail a {
  display: block;
  overflow: hidden;
  border-radius: 10px;
}

.rn-portfolio .inner .thumbnail a img,
.rn-blog .inner .thumbnail a img {
  width: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-portfolio .inner .thumbnail a img,
  .rn-blog .inner .thumbnail a img {
    height: inherit;
  }
}

.rn-portfolio .inner .thumbnail a video,
.rn-blog .inner .thumbnail a video {
  border-radius: 10px;
  height: 100%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
}

@media only screen and (max-width: 479px) {
  .rn-portfolio .inner .thumbnail a,
  .rn-blog .inner .thumbnail a {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

.rn-portfolio .inner .content,
.rn-blog .inner .content {
  padding-top: 18px;
}

.rn-portfolio .inner .content .category-info,
.rn-blog .inner .content .category-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 10px;
}

.rn-portfolio .inner .content .category-info .category-list,
.rn-blog .inner .content .category-info .category-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.rn-portfolio .inner .content .category-info .category-list a,
.rn-blog .inner .content .category-info .category-list a {
  color: var(--color-primary);
  font-weight: 500;
  font-family: var(--font-primary);
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
}

.rn-portfolio .inner .content .category-info .category-list .pro-cat {
  color: var(--color-primary);
  font-weight: 500;
  font-family: var(--font-primary);
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: block;
}

.rn-portfolio .inner .content .category-info .meta span,
.rn-blog .inner .content .category-info .meta span {
  color: var(--color-lightn);
  font-weight: 500;
  font-family: var(--font-primary);
  font-size: 13px;
}

.rn-portfolio .inner .content .category-info .meta span i,
.rn-blog .inner .content .category-info .meta span i {
  margin-right: 2px;
  font-size: 13px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.rn-portfolio .inner .content .category-info .meta span a i,
.rn-blog .inner .content .category-info .meta span a i {
  margin-right: 2px;
  font-size: 13px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.rn-portfolio .inner .content .title,
.rn-blog .inner .content .title {
  margin: 0;
}

.rn-portfolio .inner .content .title a,
.rn-blog .inner .content .title a {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--color-lightn);
  font-weight: 600;
  font-size: 23px;
  line-height: 34px;
  display: inline-block;
  cursor: pointer;
}

.rn-portfolio-area .catt-title {
  grid-template-columns: auto 1fr;
  display: grid;
  align-items: center;
  grid-gap: 20px;
  line-height: 0;
  margin-top: 60px;
}

.white-version .rn-portfolio-area .catt-title h3 {
  color: var(--color-body-white);
}

.rn-portfolio-area .catt-title h3 {
  font-size: 1.75rem;
  margin: 0;
}

.txt-success {
  color: var(--color-primary);
  font-weight: 400;
}

.rn-portfolio-area .catt-title .text-right {
  text-align: right !important;
}

.rn-portfolio-area .catt-title .text-right {
  z-index: 100;
}

.rn-portfolio-area .catt-title .text-right:before {
  z-index: -1;
  content: "";
  display: block;
  height: 1px;
  width: 85%;
  background-color: var(--color-primary);
  position: relative;
  right: 0;
  top: 15px;
}

.rn-portfolio-area .catt-title .text-right .btn-brd {
  border: 1px solid var(--color-primary);
}

@media only screen and (max-width: 479px) {
  .rn-portfolio .inner .content .title a,
  .rn-blog .inner .content .title a {
    font-size: 20px;
    line-height: 30px;
  }
}

.rn-portfolio .inner .content .title a i,
.rn-blog .inner .content .title a i {
  font-size: 0;
  position: relative;
  top: 10px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
}

.rn-portfolio .inner .content .title:hover a,
.rn-blog .inner .content .title:hover a {
  color: var(--color-primary);
}

.rn-portfolio .inner .content .title:hover a i,
.rn-blog .inner .content .title:hover a i {
  color: var(--color-primary);
  top: 3px;
  opacity: 1;
  font-size: 23px;
}

.rn-portfolio::before,
.rn-blog::before {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 20px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.rn-portfolio:hover::before,
.rn-blog:hover::before {
  opacity: 1;
}

.rn-portfolio:hover .thumbnail a img,
.rn-blog:hover .thumbnail a img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.rn-portfolio:hover video,
.rn-blog:hover video {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@media only screen and (max-width: 767px) {
  .mt_dec-sm--60 {
    margin-top: -30px;
  }
}

@media only screen and (max-width: 575px) {
  .mt_dec-sm--60 {
    margin-top: -40px;
  }
}

.backto-top > div {
  position: fixed;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 46px;
  border-radius: 50%;
  background-color: #212428;
  text-align: center;
  z-index: 999 !important;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

@media only screen and (max-width: 575px) {
  .backto-top > div {
    bottom: 30px;
  }
}

.backto-top > div:hover::after {
  opacity: 1;
}

.backto-top > div::after {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

.backto-top svg {
  font-size: 14px !important;
  color: var(--color-light);
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 1;
}

.portfolio-slick-activation .rn-portfolio-slick {
  padding: 35px 15px;
}

.blog-slick-activation {
  margin: -35px -15px;
}

@media only screen and (max-width: 1199px) {
  .blog-slick-activation {
    margin: -26px -15px -57px -16px;
  }
}

.blog-slick-activation .rn-blog-slick {
  padding: 35px 15px;
}

.portfolio-style-two .carousel.slide {
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 10px !important;
  padding: 55px !important;
  margin-top: 55px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-style-two .carousel.slide {
    padding: 30px !important;
    margin-top: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-style-two .carousel.slide {
    padding: 30px !important;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-style-two .carousel.slide {
    padding: 30px !important;
    margin-top: 20px;
  }
}

.portfolio-style-two .carousel.slide .carousel-control-next,
.portfolio-style-two .carousel.slide .carousel-control-prev {
  position: absolute;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  opacity: 1;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
  width: 20px;
  height: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 30px;
  border-radius: 50%;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  background-color: #212428;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-style-two .carousel.slide .carousel-control-next,
  .portfolio-style-two .carousel.slide .carousel-control-prev {
    padding: 26px;
    border-radius: 22%;
  }
}

@media only screen and (max-width: 575px) {
  .portfolio-style-two .carousel.slide .carousel-control-next,
  .portfolio-style-two .carousel.slide .carousel-control-prev {
    padding: 25px;
    top: 752px;
    border-radius: 19%;
  }
}

@media only screen and (max-width: 479px) {
  .portfolio-style-two .carousel.slide .carousel-control-next,
  .portfolio-style-two .carousel.slide .carousel-control-prev {
    top: 703px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-style-two .carousel.slide .carousel-control-next,
  .portfolio-style-two .carousel.slide .carousel-control-prev {
    top: 106%;
    border-radius: 20%;
    padding: 26px;
  }
}

.portfolio-style-two .carousel.slide .carousel-control-next svg,
.portfolio-style-two .carousel.slide .carousel-control-prev svg {
  width: 25px;
  position: absolute;
  z-index: 100;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  color: var(--color-lightn);
}

.portfolio-style-two .carousel.slide .carousel-control-next:hover svg,
.portfolio-style-two .carousel.slide .carousel-control-prev:hover svg {
  color: var(--color-primary);
}

.portfolio-style-two .carousel.slide .carousel-control-next {
  right: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-style-two .carousel.slide .carousel-control-next {
    right: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-style-two .carousel.slide .carousel-control-next {
    right: 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-style-two .carousel.slide .carousel-control-next {
    right: auto;
    left: 15%;
  }
}

@media only screen and (max-width: 479px) {
  .portfolio-style-two .carousel.slide .carousel-control-next {
    right: auto;
    left: 27%;
  }
}

.portfolio-style-two .carousel.slide .carousel-control-prev {
  left: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-style-two .carousel.slide .carousel-control-prev {
    left: 0;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-style-two .carousel.slide .carousel-control-prev {
    left: 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-style-two .carousel.slide .carousel-control-prev {
    left: 0;
  }
}

.portfolio-style-two .carousel-inner {
  border-radius: 8px;
}

.portfolio-style-two .carousel-inner .carousel-item .portfolio-single {
  margin: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-style-two
    .carousel-inner
    .carousel-item
    .portfolio-single
    .direction {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-style-two
    .carousel-inner
    .carousel-item
    .portfolio-single
    .direction {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}

.portfolio-style-two
  .carousel-inner
  .carousel-item
  .portfolio-single
  .thumbnail {
  z-index: 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-style-two
    .carousel-inner
    .carousel-item
    .portfolio-single
    .thumbnail {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-style-two
    .carousel-inner
    .carousel-item
    .portfolio-single
    .thumbnail {
    margin-bottom: 40px;
  }
}

.portfolio-style-two
  .carousel-inner
  .carousel-item
  .portfolio-single
  .thumbnail
  img {
  border-radius: 8px;
  width: auto;
  -o-object-fit: fill;
  object-fit: fill;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-style-two
    .carousel-inner
    .carousel-item
    .portfolio-single
    .thumbnail
    img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: auto;
  }
}

.portfolio-style-two .carousel-inner .carousel-item .portfolio-single .inner {
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-style-two .carousel-inner .carousel-item .portfolio-single .inner {
    bottom: 0;
    top: 0;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-style-two .carousel-inner .carousel-item .portfolio-single .inner {
    bottom: 0;
    top: 0;
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

.portfolio-style-two
  .carousel-inner
  .carousel-item
  .portfolio-single
  .inner
  .title {
  color: var(--color-lightn);
  margin-bottom: 34px;
}

.portfolio-style-two
  .carousel-inner
  .carousel-item
  .portfolio-single
  .inner
  p.discription {
  font-size: 16px;
  line-height: 28px;
  color: var(--color-lightn);
  margin-bottom: 30px;
}

.portfolio-single .inner p.discriptions {
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .portfolio-style-two
    .carousel-inner
    .carousel-item
    .portfolio-single
    .inner
    p.discription {
    margin-bottom: 15px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .portfolio-style-two
    .carousel-inner
    .carousel-item
    .portfolio-single
    .inner
    p.discription {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-style-two
    .carousel-inner
    .carousel-item
    .portfolio-single
    .inner
    p.discription {
    margin-bottom: 15px;
  }
}

.portfolio-style-two
  .carousel-inner
  .carousel-item
  .portfolio-single
  .inner
  .ft-area
  .feature-wrapper
  .single-feature {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.portfolio-style-two
  .carousel-inner
  .carousel-item
  .portfolio-single
  .inner
  .ft-area
  .feature-wrapper
  .single-feature
  svg {
  margin-right: 10px;
  position: relative;
  background-color: var(--color-lightn);
  border-radius: 50%;
  background: none;
  color: var(--color-lightn);
  opacity: 0.8;
  width: 22px;
  top: 1px;
}

.portfolio-style-two
  .carousel-inner
  .carousel-item
  .portfolio-single
  .inner
  .ft-area
  .feature-wrapper
  .single-feature
  p {
  color: var(--color-lightn);
  font-size: 16px;
}

.ft-area .feature-wrapper .single-feature {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ft-area .feature-wrapper .single-feature svg {
  margin-right: 10px;
  position: relative;
  background-color: var(--color-primary);
  border-radius: 50%;
  background: none;
  color: var(--color-primary);
  opacity: 0.8;
  width: 22px;
  top: 1px;
}

.ft-area .feature-wrapper .single-feature p {
  color: var(--color-body);
  font-size: 16px;
}

@media only screen and (max-width: 479px) {
  .blog-style-three.rn-blog-area h2.title {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 479px) {
  .blog-style-three.rn-blog-area .slick-dots {
    bottom: -20px;
  }
}

.portfolio-style-three .portfolio-wrapper-style-one,
.portfolio-style-three .blog-wrapper-style-one,
.blog-style-three .portfolio-wrapper-style-one,
.blog-style-three .blog-wrapper-style-one {
  padding: 20px 0 20px 0;
}

.portfolio-style-three .portfolio-wrapper-style-one .inner .thumbnail,
.portfolio-style-three .blog-wrapper-style-one .inner .thumbnail,
.blog-style-three .portfolio-wrapper-style-one .inner .thumbnail,
.blog-style-three .blog-wrapper-style-one .inner .thumbnail {
  height: 100%;
}

.portfolio-style-three .portfolio-wrapper-style-one .inner .thumbnail a,
.portfolio-style-three .blog-wrapper-style-one .inner .thumbnail a,
.blog-style-three .portfolio-wrapper-style-one .inner .thumbnail a,
.blog-style-three .blog-wrapper-style-one .inner .thumbnail a {
  height: 100%;
}

.portfolio-style-three .portfolio-wrapper-style-one .inner .content,
.portfolio-style-three .blog-wrapper-style-one .inner .content,
.blog-style-three .portfolio-wrapper-style-one .inner .content,
.blog-style-three .blog-wrapper-style-one .inner .content {
  margin-top: 0;
}

.portfolio-style-three .carousel-control-prev,
.blog-style-three .carousel-control-prev {
  left: -25px;
}

.portfolio-style-three .carousel-control-next,
.blog-style-three .carousel-control-next {
  right: -25px;
}

.portfolio-style-three .carousel-control-prev,
.portfolio-style-three .carousel-control-next,
.blog-style-three .carousel-control-prev,
.blog-style-three .carousel-control-next {
  width: 20px;
  height: 20px;
  top: 50%;
  padding: 30px;
  border-radius: 50%;
  background: #212428;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  opacity: 1;
}

.portfolio-style-three .carousel-control-prev svg,
.portfolio-style-three .carousel-control-next svg,
.blog-style-three .carousel-control-prev svg,
.blog-style-three .carousel-control-next svg {
  width: 25px;
  position: absolute;
  z-index: 100;
  color: var(--color-primary);
}

.portfolio-style-three .carousel.slide,
.blog-style-three .carousel.slide {
  padding-bottom: 0;
}

.portfolio-style-three .carousel .carousel-inner,
.blog-style-three .carousel .carousel-inner {
  padding: 0px 10px;
}

.portfolio-style-three
  .carousel
  .carousel-inner
  .carousel-item
  .portfolio-wrapper-style-one,
.blog-style-three
  .carousel
  .carousel-inner
  .carousel-item
  .portfolio-wrapper-style-one {
  padding: 20px 0 20px 0;
}

.blog-style-three .carousel-control-prev,
.blog-style-three .carousel-control-next {
  top: 45%;
}

.home-classic .portfolio-style-two {
  padding: 60px 0 120px 0;
}

.item-portfolio-static .portfolio-static {
  padding: 30px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border-radius: 15px;
}

.item-portfolio-static .portfolio-static .thumbnail-inner .thumbnail {
  overflow: hidden;
  border-radius: 8px;
}

.item-portfolio-static .portfolio-static .thumbnail-inner .thumbnail img {
  border-radius: 8px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.item-portfolio-static .portfolio-static .thumbnail-inner .thumbnail img:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.sr-thmb .thumbnail {
  overflow: hidden;
  border-radius: 8px;
}

.sr-thmb .thumbnail img {
  border-radius: 8px;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.sr-thmb .thumbnail img:hover {
  -webkit-transform: scale(1.03);
  transform: scale(1.03);
}

.item-portfolio-static .content .inner {
  padding-top: 20px;
}

.item-portfolio-static .content .inner h4 {
  font-size: 25px;
  color: var(--color-lightn);
  -webkit-transition: var(--transition);
  transition: var(--transition);
  margin-bottom: 0;
}

.item-portfolio-static .content .inner h4:hover {
  color: var(--color-primary);
}

.item-portfolio-static .content .inner p {
  margin-bottom: 5px;
  font-size: 16px;
}

.mt_dec--50 {
  margin-top: -50px;
}

.mt_dec--25 {
  margin-top: -25px;
}

.slick-initialized .slick-slide {
  margin-bottom: 0 !important;
}

.slick-dots li button:before {
  display: none;
}

.rn-slick-dot-style .slick-dots {
  width: auto;
  top: auto;
  z-index: 3;
  position: absolute;
  left: 50%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .rn-slick-dot-style .slick-dots {
    bottom: -4px;
  }
}

.rn-slick-dot-style .slick-dots li {
  width: auto;
  height: auto;
  padding: 10px;
  margin: 0;
}

.rn-slick-dot-style .slick-dots li button {
  font-size: 0;
  line-height: 0;
  width: 11px;
  height: 11px;
  padding: 0;
  cursor: pointer;
  border: 0;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--inner-shadow);
  box-shadow: var(--inner-shadow);
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.rn-slick-dot-style .slick-dots li button::before {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .rn-slick-dot-style .slick-dots li button {
    bottom: 3px;
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .rn-slick-dot-style .slick-dots li button {
    bottom: 3px;
    position: relative;
  }
}

.rn-slick-dot-style .slick-dots li.slick-active button {
  background: var(--color-primary);
}

.rn-slick-dot-style.dot-position-left .slick-dots {
  left: 0;
  bottom: 2%;
  -webkit-transform: none;
  transform: none;
}

.slick-arrow-style-one .slick-arrow {
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  opacity: 1;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
  width: 60px;
  height: 60px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 50%;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  background-color: #212428;
  left: -18px;
  border: 0;
  z-index: 2;
}

@media only screen and (max-width: 575px) {
  .slick-arrow-style-one .slick-arrow {
    width: 43px;
    height: 43px;
    top: 30%;
    left: 74%;
    border-radius: 23%;
  }
}

.slick-arrow-style-one .slick-arrow svg {
  width: 25px;
  position: absolute;
  z-index: 100;
  color: var(--color-primary);
}

.slick-arrow-style-one .next-arrow.slick-arrow {
  left: auto;
  right: -18px;
}

@media only screen and (max-width: 575px) {
  .slick-arrow-style-one .next-arrow.slick-arrow {
    left: 84%;
    top: 30%;
  }
}

.slick-arrow-style-one.arrow-top-align {
  position: relative;
}

.slick-arrow-style-one.arrow-top-align .slide-arrow {
  width: 20px;
  height: 20px;
  top: -28%;
  padding: 23px;
  border-radius: 13%;
  background: #212428;
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  opacity: 1;
  z-index: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.slick-arrow-style-one.arrow-top-align .slide-arrow:hover {
  color: var(--color-primary);
}

.slick-arrow-style-one.arrow-top-align .next-arrow {
  right: 0;
}

.slick-arrow-style-one.arrow-top-align .prev-arrow {
  left: 79%;
}

.testimonial-activation {
  position: relative;
  margin: 0 80px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-activation {
    margin: 0px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-activation {
    margin: 0px;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-activation {
    margin: 0px;
  }
}

.testimonial-activation button.slide-arrow {
  font-size: 25px;
  position: absolute;
  right: 25px;
  width: 60px;
  height: 60px;
  line-height: 60px;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
  border: 0 none;
  top: 50px;
  z-index: 3;
  border-radius: 6px;
}

.testimonial-activation button.slide-arrow:focus {
  color: var(--color-primary);
}

.testimonial-activation button.slide-arrow.prev-arrow {
  right: 105px;
}

@media only screen and (max-width: 575px) {
  .testimonial-activation button.slide-arrow.prev-arrow {
    right: 80px;
  }
}

.testimonial-activation button.slide-arrow::after {
  content: "";
  position: absolute;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  width: 100%;
  height: 100%;
  border-radius: 6px;
  top: 0;
  left: 0;
  background: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#212428),
    to(#16181c)
  );
  background: linear-gradient(to right bottom, #212428, #16181c);
  opacity: 0;
  z-index: -1;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-activation button.slide-arrow {
    top: auto;
    bottom: -70px;
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-activation button.slide-arrow {
    top: auto;
    bottom: -70px;
    display: none !important;
  }
}

@media only screen and (max-width: 575px) {
  .testimonial-activation button.slide-arrow {
    top: auto;
    bottom: -70px;
    right: 0;
  }
}

.testimonial-activation button.slide-arrow:hover {
  color: var(--color-primary);
}

.testimonial-activation button.slide-arrow:hover::after {
  opacity: 1;
}

.testimonial-activation .slick-dots {
  width: auto;
  top: auto;
  z-index: 3;
  position: absolute;
  left: 50%;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-activation .slick-dots {
    bottom: -4px;
  }
}

.testimonial-activation .slick-dots li {
  width: auto;
  height: auto;
  padding: 10px;
  margin: 0;
}

.testimonial-activation .slick-dots li button {
  font-size: 0;
  line-height: 0;
  width: 11px;
  height: 11px;
  padding: 0;
  cursor: pointer;
  border: 0;
  background: var(--background-color-1);
  -webkit-box-shadow: var(--inner-shadow);
  box-shadow: var(--inner-shadow);
  border-radius: 50%;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.testimonial-activation .slick-dots li button::before {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-activation .slick-dots li button {
    bottom: 3px;
    position: relative;
  }
}

@media only screen and (max-width: 767px) {
  .testimonial-activation .slick-dots li button {
    bottom: 3px;
    position: relative;
  }
}

.testimonial-activation .slick-dots li.slick-active button {
  background: var(--color-primary);
}

.text-color-primary {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 18px;
}

.box-wrapper {
  margin: 25px;
  background: #ee0979;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#6a67ce),
    to(#fc636b)
  ) !important;
  background: linear-gradient(to right, #6a67ce, #fc636b) !important;
  position: relative;
}

@media only screen and (max-width: 767px) {
  .box-wrapper {
    margin: 10px;
  }
}

.box-wrapper .main-page-wrapper,
.box-wrapper .rn-footer-area {
  background: #212428;
}

.home-classic .rn-header.sticky .header-right a.rn-btn {
  -webkit-box-shadow: var(--shadow-1);
  box-shadow: var(--shadow-1);
}

@media only screen and (max-width: 767px) {
  .box-wrapper .main-page-wrapper {
    padding: 15px;
  }

  .box-wrapper .rn-header.header--fixed.sticky {
    left: 10px;
    right: 10px;
  }
}

.box-wrapper .rn-right-demo {
  right: 25px;
}

@media only screen and (max-width: 767px) {
  .box-wrapper .rn-right-demo {
    right: 10px;
  }
}

.box-wrapper .rn-right-demo .demo-button {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.box-wrapper .rn-header.header--fixed.sticky {
  top: 0;
  left: 25px;
  right: 25px;
  width: auto;
}

.box-wrapper .rn-header.header--fixed.sticky {
  -webkit-box-shadow: 0px 0px 19px 0 #1c1e22;
  box-shadow: 0px 0px 19px 0 #1c1e22;
}

body.white-version {
  background-color: #ecf0f3;
}

.white-version .rn-content-wrapper .padding-tb {
  background: #fff;
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-content-wrapper .padding-tb .header-left::before {
  background: #dce1e4;
}

.white-version
  .rn-content-wrapper
  .padding-tb
  .header-left
  .header-thumbnail
  img {
  border: 5px solid #ecf0f3;
}

.white-version
  .rn-content-wrapper
  .padding-tb
  .header-left
  .header-info-content
  h4.title {
  color: var(--color-body-white);
}

.white-version
  .rn-content-wrapper
  .padding-tb
  .header-left
  .header-info-content
  .status-info {
  color: #696f79;
}

.white-version
  .rn-content-wrapper
  .padding-tb
  .header-right
  .rn-header-content
  li {
  color: var(--color-body-white);
}

.white-version
  .rn-content-wrapper
  .padding-tb
  .header-right
  .rn-header-content
  li
  span {
  color: var(--color-body);
}

.white-version .rn-content-wrapper .tab-content-wrapper .tab-navigation-button {
  background: #fff;
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-navigation-button
  .nav-item
  .nav-link.rn-nav {
  color: var(--color-body-white);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-navigation-button
  .nav-item
  .nav-link.rn-nav.active {
  background: var(--color-primary) !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-navigation-button
  .nav-item
  .nav-link.rn-nav:hover {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.white-version .rn-content-wrapper .tab-content-wrapper .tab-content {
  background: #fff;
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .rn-brand-area
  .brand-wrapper-three
  .inner {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3) !important;
  box-shadow: var(--shadow-white-3) !important;
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .rn-brand-area
  .brand-wrapper-three
  .inner::after {
  background: var(--gradient-box-w);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .rn-brand-area
  .brand-wrapper-three
  .inner.border-style {
  border: none !important;
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .rn-brand-area
  .brand-wrapper-three
  .inner
  .seperator::after {
  background: #dce1e4;
  opacity: 0.9;
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .resume-single-list {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .resume-single-list::before {
  background: var(--gradient-red-hover);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .resume-single-list
  .heading {
  border-bottom: 1px solid #e3e9ed;
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .resume-single-list
  .heading
  .title
  h4 {
  color: var(--color-body-white);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .resume-single-list
  .heading
  .date-of-time
  span {
  background: var(--color-white);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  color: var(--color-primary);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .resume-single-list:hover
  .heading {
  border-bottom: 1px solid #ce6e6e;
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .resume-single-list:hover
  .heading
  .title
  h4 {
  color: var(--color-white);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .resume-single-list:hover
  .heading
  .title
  span {
  color: var(--color-white);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .resume-single-list:hover
  .heading
  .date-of-time
  span {
  background: var(--color-primary);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--color-white);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .resume-single-list:hover
  p.description {
  color: var(--color-white);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .tab-content
  .personal-experience-inner
  .content
  .maintitle {
  color: var(--color-heading-wv);
}

.white-version
  .rn-content-wrapper
  .tab-content-wrapper
  .rn-about-area
  h6.title {
  color: var(--color-body-white) !important;
}

.white-version .left-header-style {
  background: var(--gradient-box-w);
  border-right: 1px solid #dce1e4;
}

.white-version .left-header-style .logo-area a img {
  border: 5px solid #fff;
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .left-header-style #sideNavs .primary-menu li a {
  color: var(--color-body-white);
  font-weight: 500;
}

.white-version .left-header-style #sideNavs .primary-menu li a.active {
  color: var(--color-primary);
}

.white-version .banner-inner h1.color-black {
  color: var(--color-heading-wv);
}

.white-version .banner-inner .cd-headline span:nth-child(1) {
  color: var(--color-body-white);
}

.white-version .banner-inner .cd-headline.color-light span:nth-child(1) {
  color: var(--color-lightn);
}

.white-version .client-style-two .rn-brand .inner,
.white-version .client-style-three .rn-brand .inner {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .client-style-two .rn-brand .inner::after,
.white-version .client-style-three .rn-brand .inner::after {
  display: none;
}

.white-version .client-style-two .rn-brand .inner .seperator::after,
.white-version .client-style-three .rn-brand .inner .seperator::after {
  background: #dce1e4;
  opacity: 0.9;
}

.white-version .rn-video-popup-area .thumbnail {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-video-popup-area .thumbnail a::after {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version .rn-video-popup-area .thumbnail a span {
  border-left: 27px solid #f1f1f1ce;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
}

.white-version .gallery-wrapper .portfolio-static {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .gallery-wrapper .portfolio-static .content .inner p {
  color: var(--color-primary);
}

.white-version .gallery-wrapper .portfolio-static .content .inner h4 {
  color: var(--color-heading-wv);
}

.white-version .social-share-style-1 .title {
  color: var(--color-heading-wv);
}

.white-version .social-share-style-1 .social-share li a {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .social-share-style-1 .social-share li a svg {
  color: rgba(0, 0, 0, 0.384);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.white-version .social-share-style-1 .social-share li a::before {
  background: var(--gradient-red-hover);
}

.white-version .social-share-style-1 .social-share li a:hover svg {
  color: var(--color-white);
}

.white-version .testimonial-style-2 .testimonial-inner {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version
  .testimonial-style-2
  .testimonial-inner
  .testimonial-header
  .ts-header {
  color: var(--color-body-white);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.white-version
  .testimonial-style-2
  .testimonial-inner
  .testimonial-header
  .ts-header
  span {
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.white-version .testimonial-style-2 .testimonial-inner::before {
  background: var(--gradient-box-w);
}

.white-version
  .testimonial-style-2
  .testimonial-inner
  .testimonial-body
  .discription {
  color: var(--color-body-white);
}

.white-version .portfolio-style-two .portfolio-single .inner .title {
  color: var(--color-heading-wv) !important;
}

.portfolio-single .inner .title {
  color: var(--color-heading-wv) !important;
}

.white-version .portfolio-style-two .portfolio-single .inner p.discription {
  color: var(--color-body-white) !important;
}

.white-version
  .portfolio-style-two
  .portfolio-single
  .inner
  .ft-area
  .feature-wrapper
  .single-feature
  p {
  color: var(--color-body-white) !important;
}

.white-version
  .portfolio-style-two
  .portfolio-single
  .inner
  .ft-area
  .feature-wrapper
  .single-feature
  svg {
  color: var(--color-body-white) !important;
}

.white-version .portfolio-style-two a {
  background: var(--gradient-box-w) !important;
  -webkit-box-shadow: var(--shadow-white-3) !important;
  box-shadow: var(--shadow-white-3) !important;
}

.white-version ul.slick-dots li button {
  background: #ced2d5;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version ul.slick-dots li.slick-active button {
  background: var(--color-primary);
}

.white-version.home-classic .slide .content .inner .title {
  color: var(--color-white);
}

.white-version.home-classic .slide .content .inner .title .span {
  color: var(--color-white);
}

.white-version.home-classic .slide .content .inner p.description {
  color: var(--color-lightn);
}

.white-version.home-classic .skill-style-1 li {
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  background: var(--gradient-box-w);
}

.white-version.home-classic
  .rn-client-style-2
  .main-content
  .client-name
  span
  a {
  color: var(--color-body-white);
}

.white-version.home-classic
  .rn-client-style-2
  .main-content
  .client-name
  span
  a:hover {
  color: var(--color-primary);
}

.white-version.home-classic .pricing-style-2 .title-area-wrapper p {
  color: var(--color-body-white);
}

.white-version.home-classic .pricing-style-2 .pricing-wrapper {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version.home-classic .pricing-style-2 .pricing-wrapper .ts-header span {
  color: #5c6065;
}

.white-version.home-classic .pricing-style-2 .pricing-wrapper .ts-header h6 {
  color: var(--color-heading-wv);
}

.white-version.home-classic .pricing-style-2 .pricing-wrapper h3.price {
  color: var(--color-primary);
}

.white-version.home-classic
  .pricing-style-2
  .pricing-wrapper
  .pricing-body
  .feature
  svg {
  color: var(--color-heading-wv);
}

.white-version.home-classic
  .pricing-style-2
  .pricing-wrapper
  .pricing-body
  .feature
  svg.off {
  color: rgba(61, 60, 60, 0.281);
}

.white-version.home-classic
  .pricing-style-2
  .pricing-wrapper
  .pricing-body
  .feature
  span.name {
  color: var(--color-body-white);
}

.white-version.home-classic
  .pricing-style-2
  .pricing-wrapper
  .pricing-body
  .feature
  span.name.off {
  color: rgba(0, 0, 0, 0.6);
}

.white-version.home-classic
  .pricing-style-2
  .pricing-wrapper
  .pricing-footer
  a.rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version.home-classic
  .pricing-style-2
  .pricing-wrapper
  .pricing-footer
  a.rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version.home-classic
  .pricing-style-2
  .pricing-wrapper
  .pricing-footer
  a.rn-btn:hover {
  color: var(--color-white);
}

.white-version.home-classic .rn-header .header-right a.rn-btn {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version .rn-about-area .image-area {
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  background: var(--gradient-box-w);
}

.white-version .rn-about-area .contant .discription {
  color: var(--color-body-white);
}

.white-version .rn-about-area a.rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-about-area a.rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version .rn-about-area a.rn-btn:hover {
  color: var(--color-white);
}

.white-version.box-wrapper header.rn-header,
.white-version.box-wrapper .main-page-wrapper,
.white-version.box-wrapper .rn-footer-area {
  background: #ecf0f3;
}

.white-version .slide-style-2 .slide .slider-wrapper .background-image-area {
  -webkit-box-shadow: var(--gradient-red-hover);
  box-shadow: var(--gradient-red-hover);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .slide-style-2 .user-info-top {
  background: var(--gradient-box-w) !important;
  -webkit-box-shadow: var(--shadow-white-3) !important;
  box-shadow: var(--shadow-white-3) !important;
}

.white-version .slide-style-2 .user-info-top .user-info-header h2.title {
  color: var(--color-heading-wv) !important;
}

.white-version .slide-style-2 .user-info-top .user-info-header p.disc {
  color: var(--color-body-white) !important;
}

.white-version .slide-style-2 .user-info-top .user-info-footer .info span {
  color: var(--color-heading-wv) !important;
}

.white-version .slide-style-2 .user-info-bottom {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3) !important;
  box-shadow: var(--shadow-white-3) !important;
}

.white-version .slide-style-2 .user-info-bottom .button-wrapper a.rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .slide-style-2 .user-info-bottom .button-wrapper a.rn-btn span {
  color: var(--color-primary) !important;
  -webkit-transition: var(--transition) !important;
  transition: var(--transition) !important;
}

.white-version
  .slide-style-2
  .user-info-bottom
  .button-wrapper
  a.rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version
  .slide-style-2
  .user-info-bottom
  .button-wrapper
  a.rn-btn:hover
  span {
  color: var(--color-white) !important;
}

.white-version .slide-style-2 .user-info-bottom span {
  color: var(--color-body-white) !important;
}

.white-version .experience-style-two .experience-center .date {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.white-version .experience-style-two .experience-center .experience-title {
  color: var(--color-heading-wv);
}

.white-version .experience-style-two .experience-center .subtitle {
  color: var(--color-body-white);
}

.white-version .experience-style-two .experience-center p.disc {
  color: var(--color-body-white);
}

.white-version .footer-style-2 .social-icone-wrapper .social-share li a {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .footer-style-2 .social-icone-wrapper .social-share li a svg {
  color: var(--color-body-white);
}

.white-version .footer-style-2 .social-icone-wrapper .social-share li a::after {
  background: var(--gradient-red-hover);
}

.white-version
  .footer-style-2
  .social-icone-wrapper
  .social-share
  li
  a:hover
  svg {
  color: var(--color-white);
}

.white-version .footer-style-2 .menu-wrapper .menu-footer li a {
  color: var(--color-body-white);
}

.white-version .footer-style-2 .menu-wrapper .menu-footer li a::after {
  background-color: var(--color-white);
}

.white-version .footer-style-2 .copyright .description a {
  color: var(--color-body-white);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.white-version .footer-style-2 .copyright .description a::after {
  background: var(--color-white);
}

.white-version .footer-style-2 .copyright .description a:hover {
  color: var(--color-primary);
}

.white-version .slick-arrow-style-one .slick-arrow {
  color: var(--color-body-white);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  background: var(--gradient-box-w);
}

.white-version .slick-arrow-style-one .slick-arrow::before {
  background: var(--gradient-red-hover);
}

.white-version .experience-style-two {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .experience-style-two a.rn-btn {
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  background: var(--gradient-box-w);
}

.white-version .experience-style-two a.rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version .experience-style-two a.rn-btn:hover {
  color: var(--color-white);
}

.white-version .cd-headline.clip .cd-words-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  width: 2px;
  height: 80%;
  background-color: var(--color-primary);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.white-version #sideNav .primary-menu li a {
  color: var(--color-body-white);
  font-weight: 600;
}

.white-version .section-separator::after {
  background: #dce1e4;
}

.white-version .rn-header.header--fixed.sticky {
  background-color: #ecf0f3;
  -webkit-box-shadow: 10px 10px 19px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 19px rgba(0, 0, 0, 0.1);
}

.white-version .rn-header .header-center .primary-menu li a {
  color: var(--color-tertiary);
}

.white-version .rn-header .header-right a::before {
  background: var(--gradient-red-hover);
}

.white-version .rn-header .header-right a.rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-header .header-right a.rn-btn:hover {
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.white-version .rn-header .header-right a:hover {
  color: var(--color-white);
}

.white-version .slide .content .inner .title {
  color: var(--color-heading-wv);
}

.white-version .slide .content .inner .title span {
  color: var(--color-heading-wv);
}

.white-version .slide .content .inner .title span:nth-child(1) {
  color: var(--color-primary);
}

.white-version .slide .content .inner .subtitle {
  color: var(--color-body-white);
  font-weight: 500;
}

.white-version .slide .content .inner .description {
  color: var(--color-body-white);
}

.white-version .slide .thumbnail::before {
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  background: var(--gradient-box-w);
}

.white-version .slide .social-share-inner-left span.title {
  color: var(--color-heading-wv);
  font-weight: 500;
}

.white-version .slide .social-share-inner-left span.title.white-clr {
  color: var(--color-lightn);
}

.white-version .slide .social-share-inner-left .social-share li a {
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  background: var(--gradient-box-w);
}

.white-version .slide .social-share-inner-left .social-share li a::before {
  background: var(--gradient-red-hover);
}

.white-version .slide .social-share-inner-left .social-share li a svg {
  color: var(--color-body-white);
}

.white-version .slide .social-share-inner-left .social-share li a:hover svg {
  color: var(--color-white);
}

.white-version .slide .skill-share-inner span.title {
  color: var(--color-heading-wv);
  font-weight: 500;
}

.white-version .slide .skill-share-inner .skill-share li {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-service {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-service::before {
  background: var(--gradient-red-hover);
}

.white-version .rn-service .inner .content h4.title {
  color: var(--color-heading-wv);
}

.white-version .rn-service .inner .content p.description {
  color: var(--color-body-white);
}

.white-version .rn-service:hover .inner .icon svg {
  color: var(--color-white);
}

.white-version .rn-service:hover .inner .content h4.title {
  color: var(--color-white);
}

.white-version .rn-service:hover .inner .content .description {
  color: var(--color-white);
}

.white-version .rn-service:hover .inner .content .read-more-button i {
  color: var(--color-white);
}

.white-version .rn-portfolio,
.white-version .rn-blog {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  transition: var(--transition);
}

.white-version .rn-portfolio::before,
.white-version .rn-blog::before {
  display: none;
}

.white-version .rn-portfolio .inner .content .title a,
.white-version .rn-blog .inner .content .title a {
  color: var(--color-heading-wv);
}

.white-version .rn-portfolio .inner .content .category-info .category-list a,
.white-version .rn-blog .inner .content .category-info .category-list a {
  font-weight: 500;
}

.white-version .rn-portfolio .inner .content .category-info .meta span,
.white-version .rn-blog .inner .content .category-info .meta span {
  color: var(--color-body-white);
}

.white-version .rn-portfolio .inner .content .category-info .meta span a i,
.white-version .rn-blog .inner .content .category-info .meta span a i {
  color: var(--color-body-white);
}

.white-version .modal-portfolio {
  background: #ecf0f3;
}

.white-version .modal-portfolio .modal-dialog {
  margin: auto;
}

.white-version .modal-portfolio .modal-dialog .modal-content {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .modal-portfolio
  .modal-dialog
  .modal-content
  .modal-header
  .close
  span {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--color-primary);
}

.white-version
  .modal-portfolio
  .modal-dialog
  .modal-content
  .modal-header
  .close
  span::after {
  opacity: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .modal-portfolio
  .modal-dialog
  .modal-content
  .modal-header
  .close
  span:hover {
  color: var(--color-white);
}

.white-version
  .modal-portfolio
  .modal-dialog
  .modal-content
  .modal-header
  .close
  span:hover::after {
  background: var(--gradient-red-hover);
}

.white-version
  .modal-portfolio
  .modal-dialog
  .modal-content
  .modal-body
  .text-content
  p {
  color: var(--color-body-white);
}

.white-version
  .modal-portfolio
  .modal-dialog
  .modal-content
  .modal-body
  .text-content
  h3 {
  color: var(--color-heading-wv);
}

.white-version
  .modal-portfolio
  .modal-dialog
  .modal-content
  .modal-body
  .text-content
  a.rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .modal-portfolio
  .modal-dialog
  .modal-content
  .modal-body
  .text-content
  a.rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version
  .modal-portfolio
  .modal-dialog
  .modal-content
  .modal-body
  .text-content
  a.rn-btn:hover {
  color: var(--color-white);
}

.white-version .modal {
  background: #ecf0f3;
}

.white-version .modal .modal-dialog {
  margin: auto;
}

.white-version .modal .modal-dialog .modal-content {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .modal .modal-dialog .modal-content .modal-header .close span {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--color-primary);
}

.white-version
  .modal
  .modal-dialog
  .modal-content
  .modal-header
  .close
  span::after {
  opacity: 1;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .modal
  .modal-dialog
  .modal-content
  .modal-header
  .close
  span:hover {
  color: var(--color-white);
}

.white-version
  .modal
  .modal-dialog
  .modal-content
  .modal-header
  .close
  span:hover::after {
  background: var(--gradient-red-hover);
}

.white-version .modal .modal-body .news-details p {
  color: var(--color-body-white);
}

.white-version .modal .modal-body .news-details h2 {
  color: var(--color-heading-wv);
}

.white-version .modal .modal-body .news-details h4 {
  color: var(--color-heading-wv);
}

.white-version .modal .modal-body .news-details h2 span {
  color: var(--color-body-white);
}

.white-version .modal .modal-body .comment-inner .title {
  color: var(--color-heading-wv);
}

.white-version .modal .modal-body .comment-inner a.rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .modal .modal-body .comment-inner a.rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version .modal .modal-body .comment-inner a.rn-btn:hover {
  color: var(--color-white);
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.white-version .modal .modal-body .comment-inner .rnform-group input {
  background: #fdfefe;
  border: 2px solid #dadada;
  color: var(--color-body-white);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version .modal .modal-body .comment-inner .rnform-group input:focus {
  border: 2px solid var(--color-primary);
}

.white-version .modal .modal-body .comment-inner .rnform-group textarea {
  background: #fdfefe;
  border: 2px solid #dadada;
  font-family: var(--font-secondary);
  color: var(--color-body-white);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version .modal .modal-body .comment-inner .rnform-group textarea:focus {
  border: 2px solid var(--color-primary);
}

.white-version .backto-top > div {
  background-color: var(--color-white);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .backto-top > div svg {
  color: var(--color-primary);
}

.white-version .backto-top > div::after {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  background: var(--gradient-red-hover);
}

.white-version .backto-top > div:hover svg {
  color: var(--color-white);
}

.white-version .backto-top > div:hover .progress svg.progress-circle path {
  stroke: rgb(255, 255, 255);
  stroke-width: 8;
  color: var(--color-white);
}

.white-version .backto-top > div:hover .progress::after {
  color: var(--color-white);
}

.white-version .rn-resume-area .rn-nav-list {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-resume-area .rn-nav-list .nav-item .nav-link {
  color: var(--color-heading-wv);
}

.white-version .rn-resume-area .rn-nav-list .nav-item .nav-link.active {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  color: var(--color-primary);
}

.white-version .rn-resume-area .rn-nav-list .nav-item .nav-link:hover {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  color: var(--color-primary);
}

.white-version .rn-resume-area .personal-experience-inner .content .maintitle {
  color: var(--color-heading-wv);
}

.white-version .rn-resume-area .personal-experience-inner .experience-list {
  border-left: 5px solid #d9dee2;
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list
  .inner::before {
  background: #d9dee2;
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list
  .inner::after {
  border: 5px solid #d9dee2;
  background: #ecf0f3;
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list
  .inner
  p {
  color: var(--color-body-white);
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list::before {
  background: var(--gradient-red-hover);
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list
  .heading {
  border-bottom: 1px solid #e3e9ed;
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list
  .heading
  .title
  h4 {
  color: var(--color-heading-wv);
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list
  .heading
  .title
  span {
  color: var(--color-body-white);
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list
  .heading
  .date-of-time
  span {
  background: var(--color-white);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list:hover
  .heading {
  border-bottom: 1px solid #ce6e6e;
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list:hover
  .heading
  .title
  h4 {
  color: var(--color-white);
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list:hover
  .heading
  .title
  span {
  color: var(--color-white);
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list:hover
  .heading
  .date-of-time
  span {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #fe024e;
  color: var(--color-white);
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list:hover
  .inner::after {
  background: var(--color-primary);
}

.white-version
  .rn-resume-area
  .personal-experience-inner
  .experience-list
  .resume-single-list:hover
  .inner
  p {
  color: var(--color-white);
}

.white-version .rn-resume-area .progress-charts .progress {
  background: #dce1e4;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version .rn-resume-area .progress-charts .progress .progress-bar {
  background: linear-gradient(145deg, #fff 0%, var(--color-primary) 100%);
}

.white-version
  .rn-resume-area
  .progress-charts
  .progress
  .progress-bar
  span.percent-label {
  color: var(--color-body-white);
}

.white-version .rn-resume-area .progress-charts h6.heading {
  color: var(--color-heading-wv);
  font-weight: 500;
}

.white-version .progress-charts .progress {
  background: #dce1e4;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version .progress-charts .progress .progress-bar {
  background: linear-gradient(145deg, #fff 0%, var(--color-primary) 100%);
}

.white-version .progress-charts .progress .progress-bar span.percent-label {
  color: var(--color-body-white);
}

.white-version .progress-charts h6.heading {
  color: var(--color-heading-wv);
  font-weight: 500;
}

.white-version .progress-wrapper .content .subtitle {
  color: var(--color-subtitle);
  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 500;
}

.white-version .progress-wrapper .content h4.maintitle {
  color: var(--color-heading-wv);
}

.white-version .testimonial .inner .card-info {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .testimonial .inner .card-info .card-content .title {
  color: var(--color-heading-wv);
}

.white-version .testimonial .inner .card-info .card-content .designation {
  color: var(--color-body-white);
}

.white-version .testimonial .inner .card-description {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .testimonial .inner .card-description::after {
  opacity: 0.2;
}

.white-version .testimonial .inner .card-description .discription {
  color: var(--color-body-white);
}

.white-version .testimonial .inner .card-description .title-area .rating {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .testimonial
  .inner
  .card-description
  .title-area
  .title-info
  .title {
  color: var(--color-heading-wv);
}

.white-version
  .testimonial
  .inner
  .card-description
  .title-area
  .title-info
  .date {
  color: var(--color-body-white);
}

.white-version .testimonial .inner .card-description::before {
  background: #d9dee2;
}

.white-version .testimonial .inner .card-description .seperator::after {
  background: #d9dee2;
  height: 1px;
}

.white-version .testimonial-activation button.slide-arrow {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  color: var(--color-primary);
}

.white-version .testimonial-activation button.slide-arrow::after {
  background: var(--gradient-red-hover);
}

.white-version .testimonial-activation button.slide-arrow:hover {
  color: var(--color-white);
}

.white-version .testimonial-activation .slick-dots li button {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #ced2d5;
}

.white-version .testimonial-activation .slick-dots li.slick-active button {
  background: var(--color-primary);
}

.white-version .breadcrumb-style-one {
  background: var(--gradient-box-w);
}

.white-version .breadcrumb-style-one .breadcrumb-inner .page-list li a {
  color: var(--color-body-white);
}

.white-version
  .breadcrumb-style-one
  .breadcrumb-inner
  .page-list
  li.current-page {
  color: var(--color-primary);
}

.white-version .breadcrumb-style-one .breadcrumb-inner .title {
  color: var(--color-heading-wv);
}

.white-version .rn-client-area .section-title span {
  color: var(--color-primary);
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
}

.white-version .rn-client-area .tab-navigation-button {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-client-area .tab-navigation-button .nav-item .nav-link {
  color: var(--color-body-white);
}

.white-version
  .rn-client-area
  .tab-navigation-button
  .nav-item
  .nav-link:hover {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .rn-client-area
  .tab-navigation-button
  .nav-item
  .nav-link.active {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  color: var(--color-primary);
}

.white-version .rn-client-area .client-card .seperator::after {
  background: #dce1e4;
  opacity: 0.9;
}

.white-version .rn-client-area .client-card .inner {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-client-area .client-card .inner::after {
  background: none;
}

.white-version .rn-client-area .client-card .inner:hover {
  background: var(--gradient-box-w);
}

.white-version .rn-pricing-area .navigation-wrapper {
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  border-radius: 6px;
  background: var(--gradient-box-w);
}

.white-version .rn-pricing-area .navigation-wrapper .nav {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-pricing-area .navigation-wrapper .nav li a {
  color: var(--color-body-white);
}

.white-version .rn-pricing-area .navigation-wrapper .nav li a:hover {
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  color: var(--color-primary);
  border-radius: 6px;
}

.white-version .rn-pricing-area .navigation-wrapper .nav li a.active {
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  color: var(--color-primary);
  background: var(--gradient-box-w);
}

.white-version .rn-pricing-area .navigation-wrapper .nav li::before {
  background: #e3e9ec;
  color: #000;
}

.white-version .rn-pricing-area .navigation-wrapper .nav li::after {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 15px solid #e3e9ec;
}

.white-version
  .rn-pricing-area
  .rn-pricing
  .pricing-header
  .header-left
  h2.title {
  color: var(--color-heading-wv);
}

.white-version .rn-pricing-area .rn-pricing .pricing-header .header-right span {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-pricing-area .rn-pricing .pricing-body p.description {
  color: var(--color-body-white);
}

.white-version
  .rn-pricing-area
  .rn-pricing
  .pricing-body
  .check-wrapper
  .check
  p {
  color: var(--color-body-white);
}

.white-version
  .rn-pricing-area
  .rn-pricing
  .pricing-body
  .check-wrapper
  .check
  svg {
  color: var(--color-primary);
}

.white-version .rn-pricing-area .rn-pricing .pricing-footer .rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-pricing-area .rn-pricing .pricing-footer .rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version .rn-pricing-area .rn-pricing .pricing-footer .rn-btn:hover {
  color: var(--color-white);
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.white-version
  .rn-pricing-area
  .rn-pricing
  .pricing-footer
  .time-line
  .single-cmt
  svg {
  color: var(--color-body-white);
}

.white-version
  .rn-pricing-area
  .rn-pricing
  .pricing-footer
  .time-line
  .single-cmt
  span {
  color: var(--color-body-white);
}

.white-version .rn-contact-area .contact-about-area {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-contact-area .contact-about-area .title-area h4.title {
  color: var(--color-heading-wv);
}

.white-version .rn-contact-area .contact-about-area .title-area span {
  color: var(--color-body-white);
  opacity: 0.9;
}

.white-version .rn-contact-area .contact-about-area .description span {
  color: var(--color-body-white);
}

.white-version .rn-contact-area .contact-about-area .description span a {
  color: var(--color-body-white);
}

.white-version .rn-contact-area .contact-about-area .description span a:hover {
  color: var(--color-primary);
}

.white-version .rn-contact-area .contact-about-area .description p {
  color: var(--color-body-white);
  font-weight: 400;
}

.white-version .rn-contact-area .contact-about-area .social-area .name {
  color: var(--color-heading-wv);
}

.white-version
  .rn-contact-area
  .contact-about-area
  .social-area
  .social-icone
  a {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .rn-contact-area
  .contact-about-area
  .social-area
  .social-icone
  a
  svg {
  color: var(--color-body-white);
}

.white-version
  .rn-contact-area
  .contact-about-area
  .social-area
  .social-icone
  a::before {
  background: var(--gradient-red-hover);
}

.white-version
  .rn-contact-area
  .contact-about-area
  .social-area
  .social-icone
  a:hover
  svg {
  color: var(--color-white);
}

.white-version .rn-contact-area .contact-form-wrapper {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-contact-area .contact-form-wrapper input {
  background-color: #fdfefe;
  border: 2px solid #dadada;
  font-family: var(--font-secondary);
  color: var(--color-body-white);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version .rn-contact-area .contact-form-wrapper input:focus {
  border: 2px solid var(--color-primary);
}

.white-version .rn-contact-area .contact-form-wrapper textarea {
  background: #fdfefe;
  border: 2px solid #dadada;
  font-family: var(--font-secondary);
  color: var(--color-body-white);
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version .rn-contact-area .contact-form-wrapper textarea:focus {
  border: 2px solid var(--color-primary);
}

.white-version .rn-contact-area .contact-form-wrapper label {
  color: var(--color-body-white);
}

.white-version .rn-contact-area a.rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-contact-area a.rn-btn:hover {
  color: var(--color-white);
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.white-version .rn-contact-area a.rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version .footer-area .description {
  color: var(--color-body-white);
}

.white-version .popup-mobile-menu .inner {
  background: var(--gradient-box-w);
}

.white-version
  .popup-mobile-menu
  .inner
  .menu-top
  .menu-header
  .close-button
  .close:after {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .popup-mobile-menu .inner .menu-top .discription {
  color: var(--color-body-white);
}

.white-version .popup-mobile-menu .inner .content .primary-menu .nav-item a {
  color: var(--color-heading-wv);
}

.white-version
  .popup-mobile-menu
  .inner
  .content
  .social-share-style-1
  span.title {
  color: var(--color-body-white);
}

.white-version
  .popup-mobile-menu
  .inner
  .content
  .social-share-style-1
  .social-share
  li
  a {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .popup-mobile-menu
  .inner
  .content
  .social-share-style-1
  .social-share
  li
  a
  svg {
  color: var(--color-primary);
}

.white-version a.video-play-button span {
  border-left: 27px solid #2c2a2bce;
  border-top: 17px solid transparent;
  border-bottom: 17px solid transparent;
}

.white-version a.video-play-button::after {
  background: #ffffff96;
}

.white-version a.video-play-button::before {
  background: #ecf0f33b;
}

.white-version .rn-right-demo .demo-button,
.home-sticky-white-version .rn-right-demo .demo-button {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version .rn-right-demo .demo-button span.text,
.home-sticky-white-version .rn-right-demo .demo-button span.text {
  color: var(--color-heading);
}

.white-version .section-title span.subtitle {
  color: var(--color-subtitle);
  letter-spacing: 2px;
  display: block;
  line-height: 14px;
  font-weight: 500;
}

.white-version .section-title h2.title {
  color: var(--color-body-white);
}

.white-version {
  background-color: #ecf0f3;
}

.white-version .sticky-home-wrapper h5.title {
  color: var(--color-body-white);
}

.white-version .sticky-home-wrapper h5.title::after {
  background: var(--color-tertiary);
  opacity: 0.3;
}

.white-version .sticky-home-wrapper .section-height::after {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  -webkit-filter: blur(0);
  filter: blur(0);
}

.white-version
  .sticky-home-wrapper
  .rn-skill-area
  .inner
  .skill-share-inner
  span {
  color: var(--color-heading-wv);
  font-weight: 400;
}

.white-version
  .sticky-home-wrapper
  .rn-skill-area
  .inner
  .skill-share-inner
  .skill-share
  li {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .banner-details-wrapper-sticky .banner-title-area h1.title {
  color: var(--color-heading-wv);
}

.white-version .banner-details-wrapper-sticky .banner-title-area h1.title span {
  color: var(--color-primary);
}

.white-version
  .banner-details-wrapper-sticky
  .banner-title-area
  h1.title
  .span {
  color: var(--color-body-white);
}

.white-version .banner-details-wrapper-sticky .banner-title-area p {
  color: var(--color-body-white);
}

.white-version .banner-details-wrapper-sticky .button-area .rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .banner-details-wrapper-sticky .button-area .rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version .banner-details-wrapper-sticky .button-area .rn-btn:hover {
  color: var(--color-white);
}

.white-version .banner-details-wrapper-sticky .thumbnail img {
  border: 5px solid #fefefe;
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .rn-about-area .inner p.about-disc {
  color: var(--color-body-white);
}

.white-version .single-card-sticky .card-list li a {
  color: #5c5f62;
}

.white-version .single-card-sticky .card-list li::before {
  background: #b9b9b9;
}

.white-version .testimonial-style-2 .carousel .carousel-indicators li {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #ced2d5;
}

.white-version .testimonial-style-2 .carousel a {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .testimonial-style-2 .carousel a::before {
  background: var(--gradient-red-hover);
  -webkit-transition: var(--transition);
  transition: var(--transition);
}

.white-version .testimonial-style-2 .carousel a svg {
  -webkit-transition: var(--transition);
  transition: var(--transition);
  color: var(--color-body-white);
}

.white-version .testimonial-style-2 .carousel a:hover svg {
  color: var(--color-white);
}

.white-version
  .testimonial-style-2
  .testimonial-inner
  .testimonial-header
  .ts-header {
  color: var(--color-body-white);
}

.white-version
  .testimonial-style-2
  .testimonial-inner
  .testimonial-body
  p.discription {
  color: var(--color-body-white);
}

.white-version .contact-form-wrapper label {
  color: var(--color-body-white);
}

.white-version .contact-form-wrapper input {
  background: #fdfefe;
  border: 2px solid #dadada;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--color-body-white);
}

.white-version .contact-form-wrapper textarea {
  background: #fdfefe;
  border: 2px solid #dadada;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: var(--color-body-white);
}

.white-version .contact-form-wrapper .rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  color: var(--color-primary);
}

.white-version .contact-form-wrapper .rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version .contact-form-wrapper .rn-btn:hover span {
  color: var(--color-white);
}

.white-version .contact-form-wrapper .rn-btn:hover svg {
  color: var(--color-white);
}

.white-version .backto-top > div {
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  z-index: 999;
  width: 50px;
  height: 50px;
  line-height: 46px;
  border-radius: 50%;
  background-color: var(--gradient-box-w);
  text-align: center;
  z-index: 999 !important;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.white-version .backto-top > div::after {
  background: var(--gradient-red-hover);
}

.white-version .backto-top > div:hover svg {
  color: var(--color-white);
}

.white-version .backto-top svg {
  color: #282a2f;
}

.progress {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background-color: var(--gradient-box-w);
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  position: relative;
}

.progress svg path {
  fill: none;
}

.progress svg.progress-circle path {
  stroke: rgba(255, 0, 0, 0.55);
  stroke-width: 8;
}

.progress::after {
  position: absolute;
  content: "\e914";
  font-family: "feather" !important;
  text-align: center;
  line-height: 50px;
  font-size: 24px;
  color: rgba(255, 0, 0, 0.55);
  left: 0;
  top: 0;
  height: 50px;
  width: 50px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.white-version
  .slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-bottom {
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version a.rn-btn {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
  color: var(--color-primary);
}

.white-version .slide.slider-style-3 .slider-wrapper .background-image-area,
.white-version .portfolio-style-two .carousel.slide,
.white-version .pricing-style-2 .pricing-wrapper,
.white-version .pricing-style-2 .pricing-wrapper-pro,
.white-version .pricing-style-2 .pricing-wrapper-pro .pricing-footer a.rn-btn {
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.front-end-developer.white-version div#carouselExampleControls {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version .modal .modal-dialog .modal-content .modal-body .text-content h3,
.white-version .pricing-style-2 .pricing-wrapper .ts-header h6,
.white-version .pricing-style-2 .pricing-wrapper-pro .ts-header h6 {
  color: var(--color-heading-wv);
}

.white-version
  .slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-header
  .title,
.white-version .modal-body .news-details .date,
.white-version
  .modal
  .modal-dialog
  .modal-content
  .modal-body
  .text-content
  h3
  span,
.white-version .modal .modal-dialog .modal-content .modal-body .text-content p,
.white-version .portfolio-style-two .carousel.slide .carousel-control-next svg,
.white-version .portfolio-style-two .carousel.slide .carousel-control-prev svg,
.white-version .pricing-style-2 .pricing-wrapper .ts-header span,
.white-version .pricing-style-2 .pricing-wrapper-pro .ts-header span,
.white-version .pricing-style-2 .pricing-wrapper .pricing-body .feature .name,
.white-version
  .pricing-style-2
  .pricing-wrapper-pro
  .pricing-body
  .feature
  .name,
.white-version .pricing-style-2 .pricing-wrapper .pricing-body .feature svg,
.white-version
  .pricing-style-2
  .pricing-wrapper-pro
  .pricing-body
  .feature
  svg {
  color: var(--color-body-white);
}

.white-version
  .pricing-style-2
  .pricing-wrapper
  .pricing-footer
  a.rn-btn::before,
.white-version
  .pricing-style-2
  .pricing-wrapper-pro
  .pricing-footer
  a.rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version
  .portfolio-style-two
  .carousel.slide
  .carousel-control-next
  svg:hover,
.white-version
  .portfolio-style-two
  .carousel.slide
  .carousel-control-prev
  svg:hover,
.white-version .pricing-style-2 .pricing-wrapper .price,
.white-version .pricing-style-2 .pricing-wrapper-pro .price {
  color: var(--color-primary);
}

.white-version
  .slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-header
  p.disc,
.white-version
  .slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-top
  .user-info-footer
  .info
  span,
.white-version
  .slide.slider-style-3
  .slider-wrapper
  .slider-info
  .user-info-bottom
  > span,
.white-version .footer-area .description a {
  color: var(--color-body-white);
}

.white-version a.rn-btn::before,
.white-version button.rn-btn::before {
  background: var(--gradient-red-hover);
}

.white-version a.rn-btn:hover,
.white-version button.rn-btn {
  color: var(--color-white);
}

.white-version .rn-portfolio .inner .content .title a:hover,
.white-version .rn-blog .inner .content .title a:hover {
  color: var(--color-primary);
}

.white-version .slick-arrow-style-one .slick-arrow:hover,
.white-version .footer-area .description a:hover {
  color: var(--color-primary);
}

.white-version .slide.slider-style-4 .social-share-inner-left span.title {
  color: var(--color-white);
}

.white-version.home-classic .header-style-2.sticky #sideNav .primary-menu li a {
  color: var(--color-body-white);
}

.white-version
  .testimonial-style-2.testimonial-with-carousel
  .testimonial-inner {
  background: var(--gradient-box-w);
  -webkit-box-shadow: var(--shadow-white-3);
  box-shadow: var(--shadow-white-3);
}

.white-version
  .sticky-home-wrapper
  .rn-contact-area.section-height
  .contact-form-wrapper {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.white-version.home-classic #sideNav .primary-menu li a {
  color: var(--color-lightn);
}

.white-version.home-classic .header--sticky.sticky #sideNav .primary-menu li a {
  color: var(--color-body-white);
}

.white-version .popup-mobile-menu .inner .menu-top {
  border-bottom-color: #dce1e4;
}

.white-version .header--sticky.sticky .mainmenu-nav .primary-menu li a {
  opacity: 0.8;
}

.white-version .client-card .main-content .client-name a,
.white-version .rn-pricing .pricing-header .header-left span {
  color: var(--color-body-white);
}

.white-version #sideNav .primary-menu li a.active {
  color: var(--color-primary);
}

.white-version .footer-style-2 .copyright .description,
.white-version .slider-style-5 h1 {
  color: var(--color-body-white);
}

.white-version .slider-style-6 .cd-headline span {
  color: var(--color-white) !important;
}
