.skill-share-inner .content li a {
  color: var(--color-primary) !important;
  margin-left: 10px;
  text-transform: uppercase;
}

.rn-portfolio .inner .thumbnail {
  position: relative;
}

.rn-portfolio .inner .owner {
  position: absolute;
  left: 10px;
  bottom: -10px;
}

.rn-portfolio .inner .content p span {
  color: var(--color-primary);
}

.navs ul li {
  list-style: none;
  padding: 5px 15px;
}

.navs ul a[aria-current] {
  border-top: 1px solid #888;
  border-left: 1px solid #888;
  border-right: 1px solid #888;
  border-radius: 10px 10px 0px 0px;
}

.white-version .owner img{
border: 5px solid #ecf0f3;
}.owner img{
border: 5px solid #1d1e24;
}

.rn-portfolio::before:hover, .rn-blog::before:hover{
opacity: 1;}